import React from 'react';

function UpcomingMatches( {...matchProps} ) {
    // Match Military Time to Normal Time
    const timeMap = {
        13: 1,
        14: 2,
        15: 3,
        16: 4,
        17: 5,
        18: 6,
        19: 7,
        20: 8,
        21: 9,
        22: 10,
        23: 11,
        24: 12,
    };

    const upcomingMatches = matchProps.matches.filter((match) => {
        return  match.status.type === 'notstarted';
    })

    return (
        <>
        {upcomingMatches.reverse().map((match) => {
                function handleMatchClick() {
                    matchProps.setCurrentMatch(match.id);
                    matchProps.setHomeTeam(match.homeTeam.name);
                    matchProps.setAwayTeam(match.awayTeam.name);
                    matchProps.setScoreHome(match.homeScore.current);
                    matchProps.setScoreAway(match.awayScore.current);
                    matchProps.setHomeTeamID(match.homeTeam.id);
                    matchProps.setAwayTeamID(match.awayTeam.id);
                };

                // Get Match Start Time
                let matchTimeStamp = match.startTimestamp;
                let matchDateMili = new Date(matchTimeStamp * 1000);
                let matchHours = matchDateMili.getHours();
                let matchMinutes = "0" + matchDateMili.getMinutes();
                let formatTime = matchHours + ':' + matchMinutes.substr(-2);
                if (matchHours === 0) {
                    matchHours = 12;
                };
                if (matchHours >= 13) {
                    matchHours = timeMap[matchHours];
                    formatTime = matchHours + ':' + matchMinutes.substr(-2) + ' PM';
                } else {
                    formatTime = matchHours + ':' + matchMinutes.substr(-2) + ' AM';
                };

                return (
                    <div key={match.id}>
                    <div className='matchWrapper'>
                        <div className='teamNamesWrapper'>
                            <div className='nameScoreWrapper upcoming'>
                                <h2 className='teamName'>{match.homeTeam.nameCode}</h2>
                                <h2 className='score'>{match.homeScore.current}</h2>
                                <h2 className='upcomingTime'>{formatTime}</h2>
                            </div>
                            <div className='nameScoreWrapper'>
                                <h2 className='teamName'>{match.awayTeam.nameCode}</h2>
                                <h2 className='score'>{match.awayScore.current}</h2>
                            </div>
                        </div>
                    </div>
                    </div>
                )
            })}
        </>
    )
}

export default UpcomingMatches;