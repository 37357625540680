import React from "react";
import {
  Avatar,
  ProfileContainer,
  Close,
  ArchSubChatContainer,
  ArchSubChatContent,
} from "./styles";
import Api from "../../config/axios";
import { getAvatarPath } from "../../utils/helpers";
import { languages } from "../../utils/languages";
import { Icon } from "../index";
import "./styles.css";
import SubChatConversationModal from "../Chat/components/SubChatConversationModal";
import AddUserModal from "../CreateChat/AddUserModal";
import { Row } from "react-bootstrap";
import {toast} from "react-toastify";

function Profile({
  id,
  subChats,
  setSubChats,
  updateSubChat,
  deleteSubChat,
  user,
  ...props
}) {
  const [data, setData] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  //rotate css style indicator for icon (different with chatroom page component state)
  const [rotate, setRotate] = React.useState("rotate(0deg)");

  const [subChatOpen, setSubChatOpen] = React.useState(false);
  const [selectedSubChatConversation, setSelectedSubChatConversation] =
    React.useState(null);
  const [chatId, setChatId] = React.useState(null);
  const [showAddUserModal, setShowAddUserModal] = React.useState(false);
  const [pinnedSubChats, setPinnedSubChats] = React.useState([]);


  React.useEffect(() => {
    if (typeof id === "string") {
      fetchData();
    } else {
      setIsLoading(false);
    }
    openSubChats();
  }, [id]);

  const fetchData = React.useCallback(async () => {
    if (typeof id === "string") {
      const res = await Api.get("/user/" + id);
      setData(res.data);
      setIsLoading(false);
    }
  }, [id]);


  // // open Archived Subconverstation lists
  // const openSubChats = React.useCallback(() => {
  //   const archivedSubChats = subChats.filter((subchat) => subchat.isArch);
  //   if (!archivedSubChats.length) return;
  //   setOpen(true);
  //   setRotate("rotate(180deg)");
  // }, [subChats]);

    // Open all Subconversation chats
    const openSubChats = React.useCallback(() => {
      if (!subChats.length) return; // Check if there are any subchats
      setOpen(true);
      setRotate("rotate(180deg)");
    }, [subChats]);

  // close Archived Subconverstation lists
  const closeSubChats = React.useCallback(() => {
    setOpen(false);
    setRotate("rotate(0deg)");
  }, []);

  const openUpSubChatModal = React.useCallback((subChat) => {
    setSubChatOpen(true);
    setSelectedSubChatConversation(subChat);
  }, []);

  const closeSubChatModal = React.useCallback(() => {
    setSubChatOpen(false);
    setSelectedSubChatConversation(null);
  }, []);

  const openAddUserModal = () => {
    setShowAddUserModal(true);
  };
  const closeAddUserModal = () => {
    setShowAddUserModal(false);
  };
  React.useEffect(() => {
    setChatId(id._id);
  }, [id]);

  const countUsersInGroupChat = () => {
    return id.users ? id.users.length : 0;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleDeleteConversation = async () => {
    const isConfirmed = window.confirm("Are you sure you want to delete this conversation?");
    if (isConfirmed) {
      try {
        // Call the API to delete the conversation
        await Api.delete(`/chat/conversation/${chatId}`); 
        console.log("Conversation deleted successfully.");
        toast.success("Conversation deleted successfully.");
        
        // Refresh the screen
        window.location.reload();
        
      } catch (error) {
        console.error("Error deleting conversation:", error);
      }
    }
  };  


  // Function to toggle pin status of a subchat
  const togglePin = async (subChat) => {
    try {
      // If the subchat is already pinned, unpin it; otherwise, pin it
      const updatedSubChats = [...subChats];
      const index = updatedSubChats.findIndex((chat) => chat._id === subChat._id);
      if (index !== -1) {
        // Check if the maximum number of pinned subchats has been reached
        const pinnedCount = updatedSubChats.filter((chat) => chat.isPinned).length;
        if (subChat.isPinned || pinnedCount < 3) {
          updatedSubChats[index] = {
            ...updatedSubChats[index],
            isPinned: !updatedSubChats[index].isPinned,
          };
          setSubChats(updatedSubChats);

          // Update the backend
          await Api.put(`/subChat/subconversation/pin/${subChat._id}`, { isPinned: !subChat.isPinned });
        } else {
          toast.warning("Maximum number of pinned subchats reached.");
        }
      }
    } catch (error) {
      console.error("Error toggling pin status:", error);
    }
  };


  return (
    <ProfileContainer>
      <Close onClick={() => props.setProfile(0)}>
        <Icon name="close" size={30} />
      </Close>
      {data && typeof id === "string" ? (
        <>
          <SubChatConversationModal
            isOpen={subChatOpen}
            onClose={closeSubChatModal}
            selectedSubChatConversation={selectedSubChatConversation}
            user={user}
            data={
              selectedSubChatConversation
                ? selectedSubChatConversation.users
                : null
            }
            // isGroup will need to be changed once the side bar for groups is working again
            isGroup={false}
            recipients={
              selectedSubChatConversation
                ? selectedSubChatConversation.users
                : null
            }
          />
          <Avatar src={getAvatarPath(data.avatar)} />
          <div className="title">{data.name}</div>
          <div>{data.phone}</div>
          <div>{data.email}</div>
          <div>{languages.find((i) => i.language === data.language).name}</div>
          <ArchSubChatContainer isVisible={open}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>In-Chat Threads</div>
              <div>
                {!open ? (
                  <i
                    className="bx bx-chevron-down"
                    style={{
                      transform: rotate,
                      fontSize: "28px",
                      cursor: "pointer",
                    }}
                    onClick={() => openSubChats()}
                  ></i>
                ) : (
                  <i
                    className="bx bx-chevron-down"
                    style={{
                      transform: rotate,
                      fontSize: "28px",
                      cursor: "pointer",
                    }}
                    onClick={() => closeSubChats()}
                  ></i>
                )}
              </div>
            </div>
            {subChats.slice().reverse().map((subChat) => (
              <ArchSubChatContent
                key={subChat._id}
                isVisible={open}
                onClick={() => openUpSubChatModal(subChat)}
              >
                # {subChat.name}
                <div style={{ fontSize: "18px" }}>
                  {/* Pin button */}
                  <i
                    className={subChat.isPinned ? "bx bxs-pin" : "bx bx-pin"}
                    style={{ marginRight: "8px", fontSize: "18px", cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePin(subChat); // Pass the whole subChat object
                    }}
                  ></i>
                  {/* Trash button */}
                  <i
                    id="remove"
                    className="bx bx-trash"
                    onClick={(e) => {
                      e.stopPropagation();
                      const isConfirmed = window.confirm(`Are you sure you want to delete subchat ${subChat.name}?`);
                      if (isConfirmed) {
                        deleteSubChat(subChat._id, data._id);
                        // const remainingSubChats = subChats.filter((chat) => chat._id !== subChat._id);
                        // if (!remainingSubChats.some((chat) => chat.isArch)) {
                        //   closeSubChats();
                        // }
                      }
                    }}
                  ></i>
                </div>
              </ArchSubChatContent>
            ))}
          </ArchSubChatContainer>
        </>
      ) : (
        <>
        <SubChatConversationModal
            isOpen={subChatOpen}
            onClose={closeSubChatModal}
            selectedSubChatConversation={selectedSubChatConversation}
            user={user}
            data={
              selectedSubChatConversation
                ? selectedSubChatConversation.users
                : null
            }
            isGroup={true}
            recipients={
              selectedSubChatConversation
                ? selectedSubChatConversation.users
                : null
            }
          />

          <Avatar src={getAvatarPath("", true)} />
          <div className="title">{id.name}</div>
          <div className="AddUserContainer">
            <p>Participants ({countUsersInGroupChat()})</p>
            <button onClick={openAddUserModal} className="AddUserButton">
              ⊕
            </button>
          </div>
          <hr className="GroupUsersLine" />
          <AddUserModal
            user={user}
            show={showAddUserModal}
            handleClose={closeAddUserModal}
            chatId={chatId}
          />
          {id.users?.map((user, index) => (
            <div className="GroupUsers" key={index}>
              <div>{user.name}</div>
            </div>
          ))}

          <ArchSubChatContainer isVisible={open}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>In-Chat Threads</div>
                        <div>
                          {!open ? (
                            <i
                              className="bx bx-chevron-down"
                              style={{
                                transform: rotate,
                                fontSize: "28px",
                                cursor: "pointer",
                              }}
                              onClick={() => openSubChats()}
                            ></i>
                          ) : (
                            <i
                              className="bx bx-chevron-down"
                              style={{
                                transform: rotate,
                                fontSize: "28px",
                                cursor: "pointer",
                              }}
                              onClick={() => closeSubChats()}
                            ></i>
                          )}
                        </div>
                      </div>
                      {subChats.slice().reverse().map((subChat) => (
                        <ArchSubChatContent
                          key={subChat._id}
                          isVisible={open}
                          onClick={() => openUpSubChatModal(subChat)}
                        >
                          # {subChat.name}
                          <div style={{ fontSize: "18px" }}>
                            {/* Pin button */}
                            <i
                              className={subChat.isPinned ? "bx bxs-pin" : "bx bx-pin"}
                              style={{ marginRight: "8px", fontSize: "18px", cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePin(subChat); // Pass the whole subChat object
                              }}
                            ></i>
                            {/* Trash button */}
                            <i
                              id="remove"
                              className="bx bx-trash"
                              onClick={(e) => {
                                e.stopPropagation();
                                const isConfirmed = window.confirm(`Are you sure you want to delete subchat ${subChat.name}?`);
                                if (isConfirmed) {
                                  deleteSubChat(subChat._id, id._id);
                                  // const remainingSubChats = subChats.filter((chat) => chat._id !== id._id);
                                  // if (!remainingSubChats.some((chat) => chat.isArch)) {
                                  //   closeSubChats();
                                  // }
                                }
                              }}
                            ></i>
                          </div>
                        </ArchSubChatContent>
                      ))}
                    </ArchSubChatContainer>


        <button onClick={handleDeleteConversation} className="DeleteConversationButton">
          Delete Conversation
        </button>
        
        </>
      )}
    </ProfileContainer>
  );
}

export default Profile;
