import React, { useEffect } from 'react';
import { europeLocations, americasLocations, asiaLocations } from './Parks/parkLocations';
import CoasterTimeFeed from './CoasterTimeFeed';

function ParksList( {...props} ) {
    // Scroll to top on render
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const europeParks = props.parks.filter((park) => {
        return europeLocations.includes(park.name);
    });

    const americasParks = props.parks.filter((park) => {
        return americasLocations.includes(park.name);
    });

    const asiaParks = props.parks.filter((park) => {
        return asiaLocations.includes(park.name);
    });

    return (
        <div id='themeParksListContainer'>
            <div id='parksLists'>
                <div className='parksContainer'>
                    <h2 className='parksContainerTitle'>North American Parks</h2>
                    <div className='parksColumns'>
                        <div className='parksColumn'>
                            {americasParks.slice(0, 11).map((park) => {
                                function handleParkClick() {
                                    props.setCurrentParkId(park.id);
                                    props.setShowingPark(true);
                                };

                                return (
                                    <h2 className='parkName' onClick={handleParkClick} key={park.id}>{park.name}</h2>
                                )
                            })}
                        </div>
                        <div className='parksColumn'>
                            {americasParks.slice(11, 21).map((park) => {
                                function handleParkClick() {
                                    props.setCurrentParkId(park.id);
                                    props.setShowingPark(true);
                                };

                                return (
                                    <h2 className='parkName' onClick={handleParkClick} key={park.id}>{park.name}</h2>
                                )
                            })}
                        </div>     
                        <div className='parksColumn'>
                            {americasParks.slice(21, 31).map((park) => {
                                function handleParkClick() {
                                    props.setCurrentParkId(park.id);
                                    props.setShowingPark(true);
                                };

                                return (
                                    <h2 className='parkName' onClick={handleParkClick} key={park.id}>{park.name}</h2>
                                )
                            })}
                        </div>   
                        <div className='parksColumn'>
                            {americasParks.slice(31, 40).map((park) => {
                                function handleParkClick() {
                                    props.setCurrentParkId(park.id);
                                    props.setShowingPark(true);
                                };

                                return (
                                    <h2 className='parkName' onClick={handleParkClick} key={park.id}>{park.name}</h2>
                                )
                            })}
                        </div>        
                    </div>
                </div>
                <div className='parksContainer'>
                    <h2 className='parksContainerTitle'>European Parks</h2>
                    <div className='parksColumns'>
                        <div className='parksColumn'>
                            {europeParks.slice(0, 11).map((park) => {
                                function handleParkClick() {
                                    props.setCurrentParkId(park.id);
                                    props.setShowingPark(true);
                                };

                                return (
                                    <h2 className='parkName' onClick={handleParkClick} key={park.id}>{park.name}</h2>
                                )
                            })}
                        </div>
                        <div className='parksColumn'>
                            {europeParks.slice(11, 19).map((park) => {
                                function handleParkClick() {
                                    props.setCurrentParkId(park.id);
                                    props.setShowingPark(true);
                                };

                                return (
                                    <h2 className='parkName' onClick={handleParkClick} key={park.id}>{park.name}</h2>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='parksContainer'>
                    <h2 className='parksContainerTitle'>Asian Parks</h2>
                    <div className='parksColumns'>
                        <div className='parksColumn'>
                            {asiaParks.map((park) => {
                                function handleParkClick() {
                                    props.setCurrentParkId(park.id);
                                    props.setShowingPark(true);
                                };

                                return (
                                    <h2 className='parkName' onClick={handleParkClick} key={park.id}>{park.name}</h2>
                                )
                            })}
                        </div>
                    </div>
                </div>          
            </div>
            <div id='feedContainer'>
                <CoasterTimeFeed {...props} />
            </div>
        </div>
    )
};

export default ParksList;