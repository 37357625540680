import React from 'react';
import { theme } from '../../config/theme';
import { ThemeProvider } from 'styled-components';
import { Chat, Profile, Sidebar} from '../../components';
import { Container, Header}  from './styles';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Api from '../../config/axios';
import { LoadScript } from '@react-google-maps/api';
import constants from '../../config/constants';
import {getImagePath} from "../../utils/helpers.js"
import {
	createChat,
	disconnectSocket,
	initiateSocket,
	newChat,
	refreshMessages,
	setOffline,
	setOnline,
	emitLastSeen,
	subscribeToLastSeen,
	unsubscribeFromLastSeen
} from './socket';
import Spinner from '../../components/Spinner';
import useDimensions from '../../utils/useDimensions';
import { CenteredContent } from '../../utils/sharedStyles';
import { useBeforeunload } from 'react-beforeunload';
import { toast } from 'react-toastify';
import InactivityDetector from '../../utils/InactivityDetector';
import styled from 'styled-components';

function ChatRoom() {
	const { width } = useDimensions();
	const [loading, setLoading] = React.useState(true);
	const [mode, setMode] = useOutletContext();
	const [user, setUser] = React.useState({});
	const [conversations, setConversations] = React.useState([]);
	const [chatId, setChatId] = React.useState('');
	const [chatData, setChatData] = React.useState({});
	const [subChats, setSubChats] = React.useState([]);
	//Toggle state for SubConversation list in chat componenet
	const [subChatOpen, setSubChatOpen] = React.useState(false);
	//Rotate css style indicator for icon in chat componenet
	const [rotate, setRotate] = React.useState('rotate(0deg)');
	const [profile, setProfile] = React.useState();
	const [sidebarStatus, setSidebarStatus] = React.useState('open');
	//const useBackground = Boolean(user.useBackground);
	//const background = String(getImagePath(user.useBackground));
	// console.log(user.phone);
	// console.log(user.useBackground);
	// console.log(user.background);
	const navigate = useNavigate();

	const handleInactivity = async () => {
		await Api.put('/user/updateUserStatus/' + user._id, { status: 'yellow' });
		emitLastSeen({ userId: user._id, lastSeen: Date.now() });
	};

	const handleInactivity10 = async () => {
		await Api.put('/user/updateUserStatus/' + user._id, { status: 'red' });
		emitLastSeen({ userId: user._id, lastSeen: Date.now() });
	};

	async function updateStatus(id) {
		await Api.put('/user/updateUserStatus/' + id, { status: 'green' });
	}

	React.useEffect(() => {
		fetchData();
		return () => {
			disconnectSocket();
		};
	}, []);

	const fetchData = React.useCallback(async () => {
		const token = localStorage.getItem('token');
		if (token) {
			Api.setToken(token);
			const user = (await Api.get('/user')).data;
			if (!user) {
				localStorage.removeItem('token');
				navigate('/app/login');
				return;
			}
			const conversations = (await Api.get('/chat/conversation')).data.data;
			setUser(user);
			setConversations(conversations);
			setOnline(user._id);
			setLoading(false);
			updateStatus(user._id);
		} else navigate('/app/login');
	}, []);

	useBeforeunload(() => {
		setOffline(user._id);
	});

	const fetchChatData = React.useCallback(async () => {
		if (chatId) {
			const res = await Api.get('/chat/conversation/' + chatId);
			setChatData(res.data);
			setSubChats(res.data.subConversations);
			setSidebarStatus('close');
		}
	}, [chatId]);

	React.useEffect(() => {
		fetchChatData();
		setProfile();
	}, [chatId, fetchChatData]);

	React.useEffect(() => {
		if (user._id) {
			initiateSocket(user._id);
			subscribeToLastSeen((data) => {
				console.log('Last seen data: ', data);
			});
			refreshMessages((data) => {
				setConversations((state) =>
					state.map((c) =>
						c._id === data.conversationId
							? {
									...c,
									message: data,
									unseenMessageLength: (c.unseenMessageLength || 0) + 1
							  }
							: c
					)
				);
			});
			newChat((data) => {
				setConversations((state) => [data, ...state]);
			});
		}
		return () => {
			unsubscribeFromLastSeen();
		};
	}, [user._id]);

	React.useEffect(() => {}, [sidebarStatus]);

	//Function for toggle subconversation in chat component to interact with profile side bar subconversation lists
	const openSubChats = React.useCallback(
		(method = '') => {
			const unArchivedSubChats = subChats.filter((subChat) => !subChat.isArch);
			if (!unArchivedSubChats.length && !method.length) return;
			setSubChatOpen(true);
			setRotate('rotate(180deg)');
		},
		[subChats]
	);

	//Function for toggle subconversation in chat component to interact with profile side bar subconversation lists
	const closeSubChats = React.useCallback(() => {
		setSubChatOpen(false);
		setRotate('rotate(0)');
	}, []);

	//Function for update archieve status of subconversation in chat component and profile side bar subconversation lists (use later for futer development)
	// const updateSubChat = React.useCallback(
	// 	async (subchatId, method) => {
	// 		try {
	// 			const unArchivedSubChats = subChats.filter(
	// 				(subChat) => !subChat.isArch
	// 			);
	// 			if (method === 'archiveOut') {
	// 				if (unArchivedSubChats.length >= 3) {
	// 					toast.warn(
	// 						'Maximum number of 3 subconversations reached. Please archive and try again'
	// 					);
	// 					return;
	// 				} else {
	// 					openSubChats('archive');
	// 				}
	// 			} else if (method === 'archiveIn') {
	// 				if (unArchivedSubChats.length - 1 === 0) closeSubChats();
	// 			}
	// 			await Api.put(`/subchat/subconversation/${subchatId}`);
	// 			setSubChats(
	// 				subChats.map((subChat) => {
	// 					if (subChat._id === subchatId) {
	// 						return { ...subChat, isArch: !subChat.isArch };
	// 					} else {
	// 						return subChat;
	// 					}
	// 				})
	// 			);
	// 		} catch (error) {
	// 			toast.warn('An erorr has occcurred. Please try again');
	// 			console.error('An erorr has occcurred', error);
	// 		}
	// 	},
	// 	[subChats, setSubChats, closeSubChats, openSubChats]
	// );

	//Function for deleting subconversation in profile side bar subconversation lists
	const deleteSubChat = React.useCallback(
		async (subChatId, chatId) => {
			try {
				await Api.delete(
					`/subchat/deleteSubConversation/${subChatId}/${chatId}`
				);
				setSubChats(subChats.filter((subChat) => subChat._id !== subChatId));
			} catch (error) {
				toast.warn('An erorr has occcurred. Please try again');
				console.error('An erorr has occcurred', error);
			}
		},
		[subChats, setSubChats]
	);

	const updateLastMessage = React.useCallback((id, message) => {
		setConversations((state) =>
			state.map((c) => (c._id === id ? { ...c, message } : c))
		);
	}, []);

	const setSeenMessages = React.useCallback((data) => {
		setConversations((state) =>
			state.map((c) =>
				c._id === data.conversationId
					? {
							...c,
							message: {
								...c.message,
								seenBy: [...(c.message?.seenBy || []), data.userId]
							},
							unseenMessageLength: 0
					  }
					: c
			)
		);
	}, []);

	const createConversation = React.useCallback((newChat) => {
		setConversations((state) => [newChat, ...state]);
		createChat(newChat);
	}, []);

	const setThemeMode = React.useCallback((val) => {
		setMode(val);
		localStorage.setItem('mode', val);
	}, []);

	const handleLogout = () => {
		localStorage.removeItem('token');
		navigate('/');
	};

	const handleProfileClick = async (event) => {
		event.preventDefault();
		await Api.put('/user/updateUserStatus/' + user._id, { status: 'yellow' });
		navigate(`/app/myprofile`, { state: user });
	};

	const sideBarToggle = (val) => {
		if (width < 700 && val === 'close') {
			setSidebarStatus(val);
		} else {
			if (sidebarStatus === 'close') setSidebarStatus('open');
			else setSidebarStatus('close');
		}
	};
	return (	
		<ThemeProvider theme={mode === 'dark' ? theme.dark : theme.light}>
			<InactivityDetector timeout={120000} onInactivity={handleInactivity} />
			<InactivityDetector timeout={600000} onInactivity={handleInactivity10} />
			<Header/>
			<Container background = {getImagePath(user.background)} useBackground = {user.useBackground}>
				
				{loading ? (
					<CenteredContent className='loading'>
						<Spinner color='#358bd0' />
					</CenteredContent>
				) : (
					<>
						{width >= 700 || sidebarStatus === 'open' ? (
							
							<Sidebar
								width={width}
								user={user}
								conversations={conversations}
								setConversations={setConversations}
								setChatId={setChatId}
								createChat={createConversation}
								setSidebarStatus={setSidebarStatus}
							/>
						) : (
							<></>
						)}
						{chatData._id && (
							<LoadScript googleMapsApiKey={constants.maps_api}>
								{width > 950 || !profile ? (
									<Chat

										updateLastMessage={updateLastMessage}
										setSeenMessages={setSeenMessages}
										setProfile={setProfile}
										data={chatData}
										user={user}
										mode={mode}
										setMode={setThemeMode}
										subChats={subChats}
										setSubChats={setSubChats}
										// updateSubChat={updateSubChat}
										openSubChats={openSubChats}
										closeSubChats={closeSubChats}
										rotate={rotate}
										setRotate={setRotate}
										subChatOpen={subChatOpen}
										setSubChatOpen={setSubChatOpen}
										sideBarToggle={sideBarToggle}
										sidebarStatus={sidebarStatus}
										
									/>
								) : (
									<></>
								)}
							</LoadScript>
							
						)}
						{profile ? (
							<Profile
								id={profile}
								setProfile={setProfile}
								setConversations={setConversations}
								subChats={subChats}
								setSubChats={setSubChats}
								// updateSubChat={updateSubChat}
								deleteSubChat={deleteSubChat}
								user={user}
							/>
							) : null}
					</>
				)}
			</Container>
		</ThemeProvider>
	);
}

export default ChatRoom;
