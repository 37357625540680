import styled from 'styled-components';

export const ImgMessage = styled.img`
	margin-top: 15px;
	max-width: 300px;
	max-height: 300px;
	transition: all 0.3s ease-in;
	cursor: pointer;

	@media screen and (max-width: 350px) {
		max-width: 200px;
		max-height: 200px;
	}
`;

export const ChatContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	transition: all 0.3s ease-in;
	background-image: ${({background,useBackground}) => useBackground ? 'url('+ background +')' : 'none'};
	background-color: ${({ theme }) => theme.bg};
`;

export const ChatContent = styled.div`
	height: 100%;
	transition: all 0.3s ease-in;

	padding-bottom: 100px;
	& > div {
		height: 100%;
	}
`;

export const SubChatContainer = styled.div`
	z-index: 1;
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 5px;
	padding: 10px 20px;
	transition: all 0.3s ease-in;
	background-color: transparent;
	height: auto;
	${(props) =>
		props.isVisible ? 'opacity: 1;' : 'opacity: 0; pointer-events: none;'};

	@media screen and (max-width: 1400px) {
		flex-direction: column;
		align-items: center;
	}
`;

export const SubChatContent = styled.div`
	&:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	}
	transition: all 0.3s ease-in;
	border-radius: 0.25rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 0 10px;
	cursor: pointer;
	width: 100%;
	max-width: 450px;
	height: 85px;
	border: 1px solid ${({ theme }) => theme.border};
	background-color: ${({ theme }) => theme.bg};
	color: ${({ theme }) => theme.txt};

	@media screen and (max-width: 1400px) {
		max-width: none;
		height: 60px;
	}
`;

export const IconBtns = styled.div`
	font-size: 30px;
	color: ${({ theme }) => theme.txt};
	transition: all 0.3s ease-in;
`;

export const MapContainer = styled.div`
	background-color: gray;
	transition: all 0.3s ease-in;

	width: 607px;
	height: 250px;
	margin-bottom: 10px;
	border-radius: 25px;
	overflow: hidden;
	@media screen and (max-width: 1100px) {
		width: 80%;
	}
`;

export const Header = styled.div`
	width: 100%;
	height: 90px;
	border-bottom: 1px solid ${({ theme }) => theme.border};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 25px;
	transition: all 0.3s ease-in;

	color: ${({ theme }) => theme.txt};
	font-size: 22px;
	& > div {
		cursor: pointer;
	}
`;
export const HeaderAvatar = styled.img`
	width: 40px;
	height: 40px;
	margin-right: 15px;
	border-radius: 50px;
`;
export const StatusTxt = styled.div`
	color: ${({ theme }) => theme.title};
	opacity: 0.8;
	font-size: 12px;
	margin-top: 2px;
	transition: all 0.3s ease-in;
`;

export const InputWrapper = styled.div`
	position: relative;
`;
export const InputContainer = styled.div`
	background-color: ${({ theme }) => theme.inputBg};
	transition: all 0.3s ease-in;
	margin: 0 20px;
	border-radius: 30px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	height: 50px;
	padding: 0 10px;
	margin-top: 9px;
`;
export const Input = styled.input.attrs(({ theme }) => ({
	placeholderTextColor: theme.gray,
	color: theme.txt,
	type: 'text'
}))`
	margin: 0 10px;
	border-radius: 30px;
	width: 90%;
	color: ${({ theme }) => theme.txt};
	border: none;
	background: transparent;
	outline: none;
`;
export const IconBtn = styled.div`
	position: absolute;
	right: 95px;
	top: 22px;
	cursor: pointer;
	transform: rotate(280deg);
`;
export const IconContainer = styled.div`
	cursor: pointer;
	width: 38px;
	height: 38px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme }) =>
		theme.mode === 'dark' ? '#465265' : '#fff'};
	transition: all 0.3s ease-in;
`;
export const ActionsContainer = styled.div`
	height: 120px;
	background-color: ${({ theme }) => theme.bg2};
	transition: all 0.3s ease-in;
	padding-top: 25px;
	position: absolute;
	bottom: 50px;
	width: 60%;
	right: 60px;
	border-radius: 10px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	display: flex;
	flex-direction: column;
	z-index: 2;
	color: ${({ theme }) => theme.txt};
`;
export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
`;
export const RowItem = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 350px) {
		font-size: 13px;
	}
`;
export const Btn = styled.div`
	width: 45px;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 25px;
	margin-bottom: 10px;

	@media screen and (max-width: 350px) {
		width: 30px;
		height: 30px;
	}
`;
export const MessageText = styled.div`
	color: ${({ theme, right }) => (right ? '#fff' : theme.txt)};
	transition: all 0.3s ease-in;
	margin: 13px;
	font-size: 14px;
	margin-left: 17px;

	div {
		color: ${({ theme, right }) => (right ? '#fff' : theme.txt)};
	}
`;
export const SubChatMessageText = styled.div`
	transition: all 0.3s ease-in;
	margin: 4px;
	font-size: 15px;
	margin-left: 17px;

	div {
		color: ${({ theme, right }) => (right ? '#fff' : '#000')};
	}
`;
export const LoadBtn = styled.div`
	cursor: pointer;
	padding: 8px;
	background-color: ${({ theme }) => theme.primary};
	width: 130px;
	margin-bottom: 15px;
	border-radius: 3px;
	height: 35px;
	text-align: center;
	margin-top: 15px;
	color: #fff;
	font-size: 14px;
	align-self: center;
`;

// subConversation Modal Styles
export const Chat = styled.div`
	flex: 0.6;
	display: flex;
	flex-direction: column;
	padding: 20px;
	background-color: ${({ theme }) => theme.bg};
	border-radius: 20px;
`;

export const ChatHeader = styled.div`
	display: flex;
	padding: 12px;
	align-items: center;
	margin-bottom: 10px;
	color: ${({ theme }) => theme.txt};
	background-color: ${({ theme }) => theme.bg};
	border-bottom: 1px solid ${({ theme }) => theme.border};
`;

export const ChatBody = styled.div`
	overflow: auto;
	scroll-behavior: smooth;
	height: 75vh;
	max-height: 75vh;
	background-color: ${({ theme }) => theme.bg};
`;

export const ChatMessage = styled.div`
	position: relative;
	font-size: 15px;
	border-radius: 20px 0px 0px 20px;
	padding: 20px 20px 10px 20px;
	background-color: rgb(53, 139, 208);
	width: fit-content;
	margin: 10px;
	margin-left: auto;
	color: white;
`;

export const ChatMessageReceiver = styled.div`
	position: relative;
	font-size: 15px;
	border-radius: 20px 0px 0px 20px;
	padding: 20px 20px 10px 20px;
	width: fit-content;
	margin-left: auto;
	margin: 10px 10px 10px 50px;
	background-color: rgb(244, 247, 254);
	border-radius: 20px 20px 20px 0px;
	background-color: ${({ theme }) =>
		theme.mode === 'light' ? 'rgb(244, 247, 254)' : 'rgb(36, 42, 52);'};
	color: ${({ theme }) => theme.txt};
`;

export const ChatPhoto = styled.img`
	position: absolute;
	left: -45px;
	top: 45px;
	height: 36px;
	width: 36px;
	border-radius: 50%;
`;

export const Timestamp = styled.p`
	font-size: xx-small;
	text-align: right;
	margin: 0;
`;

export const Footer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	background-color: ${({ theme }) => theme.bg};
`;

export const Form = styled.form`
	flex: 1;
	display: flex;
	background-color: ${({ theme }) => theme.bg};
`;

export const SubInput = styled.input`
	flex: 1;
	border-radius: 30px;
	padding: 15px;
	border: none;
	margin: 0 10px 0px 10px;
	background-color: ${({ theme }) => theme.inputBg};
	color: ${({ theme }) => theme.txt};
	outline: none;
`;
