import React, { useEffect, useState } from 'react';
import basketballIcon from './SportsAssets/basketball.png';
import soccerIcon from './SportsAssets/soccer.png';
import baseballIcon from './SportsAssets/baseball.png';
import footballIcon from './SportsAssets/football.png';
import cricketIcon from './SportsAssets/cricket.png';

import DropDown from './DropDown';

function SportsNavList( {...bannerProps} ) {
    const [showingNavDropdown, setShowingNavDropdown] = useState(false);
    const [thisSport, setThisSport] = useState('Basketball');

    // DropDown Menu Leagues
    let dropBasketballLeagues = ['NBA', 'Euroleague', 'Olympic Games', 'FIBA EuroBasket'];
    let dropSoccerLeagues = ['LaLiga', 'Bundesliga', 'Serie A', 'Ligue 1', 'CONMEBOL Libertadores', 'Copa América'];
    let dropBaseballLeagues = ['MLB', 'World Baseball Classic', 'Cuba Serie Nacional', 'Serie del Caribe', 'Pro Yakyu - NPB'];
    let dropFootballLeagues = ['NFL', 'NFL, Playoffs', 'NFL Preseason'];
    let dropCricketLeagues = ['Big Bash League', 'ICC Cricket World Cup', 'Indian Premier League', 'T20 International', 'Asia Cup'];

    // Define Banners
    const banners = [
        {event: 'Basketball', id: 2},
        {event: 'Soccer', id: 1},
        {event: 'Baseball', id: 64},
        {event: 'Football', id: 1},
        {event: 'Cricket', id: 62}
    ];

    return (
    <div id='bannerWrapperNavbar'>
            {banners.map((banner) => {
                let icon = null;
                let navDropDownItems = dropBasketballLeagues;

                if (banner.event === 'Basketball') {
                    icon = basketballIcon;
                    navDropDownItems = dropBasketballLeagues;
                };
                if (banner.event === 'Soccer') {
                    icon = soccerIcon;
                    navDropDownItems = dropSoccerLeagues;
                };
                if (banner.event === 'Baseball') {
                    icon = baseballIcon;
                    navDropDownItems = dropBaseballLeagues;
                };
                if (banner.event === 'Football') {
                    icon = footballIcon;
                    navDropDownItems = dropFootballLeagues;
                };
                if (banner.event === 'Cricket') {
                    icon = cricketIcon;
                    navDropDownItems = dropCricketLeagues;
                };
                
                // When we Change Sports we want to reset everything
                function handleBannerClick() {
                    bannerProps.setBannerClick(bannerProps.bannerClick + 1);
                    bannerProps.setSelectedSport(banner.event);
                    bannerProps.setCounter(bannerProps.counter + 1);
                    bannerProps.setAllStats([]);
                    bannerProps.setBestPlayerStats([]);
                    bannerProps.setStartDate(new Date());
                    bannerProps.setShowingDropDown(false);
                    bannerProps.setShowingStats(false);
                    bannerProps.setScoreHome(0);
                    bannerProps.setScoreAway(0);
                    bannerProps.setStandings([]);
                    bannerProps.startLoading(15);
                    bannerProps.setShowSearch(false);
                };

                function handleHover() {
                    setThisSport(banner.event);
                    setShowingNavDropdown(true);
                }

                function handleLeave() {
                    setShowingNavDropdown(false);
                    setThisSport(null);
                }

                return (
                    <div id='none' onMouseLeave={handleLeave}>
                    <div id='bannerNavWrapper' key={banner.event} onClick={() => handleBannerClick()} onMouseEnter={handleHover}>
                        <h2 className='sportsNavItem'>{banner.event}</h2>
                        <svg className='bannerSVG' viewBox="0 0 24 24">
                            <path d="M19.653 9.369l-0.916-0.892-6.705 6.681-6.681-6.681-0.917 0.892 7.597 7.622z"/>
                        </svg>
                    </div> 
                    {showingNavDropdown && banner.event === thisSport ? 
                        <div className='navDropDown'>
                            {navDropDownItems.map((league) => {
                                function handleClick() {
                                    bannerProps.setLeague(league);
                                    bannerProps.setStartDate(bannerProps.currentDate);
                                    bannerProps.startLoading(15);
                                    bannerProps.setShowSearch(false);
                                    bannerProps.setBannerClick(0);
                                    bannerProps.setSelectedSport(banner.event);
                                    bannerProps.setCounter(bannerProps.counter + 1);
                                    bannerProps.setAllStats([]);
                                    bannerProps.setBestPlayerStats([]);
                                    bannerProps.setStartDate(new Date());
                                    bannerProps.setShowingDropDown(false);
                                    bannerProps.setShowingStats(false);
                                    bannerProps.setScoreHome(0);
                                    bannerProps.setScoreAway(0);
                                    bannerProps.setStandings([]);
                                    bannerProps.startLoading(15);
                                    bannerProps.setShowSearch(false);
                                };

                                let leagueTitle = league;
                                league === 'NFL, Playoffs' ? leagueTitle = 'Super Bowl LVIII' : leagueTitle = league;

                                return (
                                    <h2 onClick={handleClick} className='navDropItem'>{leagueTitle}</h2>
                                )
                            })}
                        </div>
                    : <></>}
                    </div>
                )
            })}
        </div>
    )
}

export default SportsNavList;