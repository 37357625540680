import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import Api from '../../config/axios';
import { toast } from 'react-toastify';
import { getAvatarPath } from '../../utils/helpers';
import { useOutsideAlerter } from '../../utils/useOutsideAlerter';
import { Icon } from '../../components';
import DropdownInput from '../../components/DropdownInput';
import { languages } from '../../utils/languages';
import { useNavigate } from 'react-router-dom';
import EditProfileTab from './components/editProfileTab';
import SettingsTab from './components/settingsTab';
import PasswordTab from './components/passwordTab';
import { Tabs } from 'antd';

function MyProfile() {
	const location = useLocation();
	const user = location.state;
	const userData = location.state;
	//This state variable will store the current language and change the user's language
	const [userLanguage, setUserLanguage] = React.useState(userData.language);
	const navigate = useNavigate();

	// This state variable will keep track of whether the component is in edit mode or not,
	// if true user is allowed to edit the profile
	const [isEditMode, setIsEditMode] = useState(false);

	const [toggle, setToggle] = useState(userData.translateUser);
	const [avatar, setAvatar] = useState(getAvatarPath(userData.avatar));
	const [username, setUsername] = useState(userData.name);
	const [password, setPassword] = useState({
		currentPassword: '',
		newPassword: '',
		confirmPassword: ''
	});
	const [mode, setMode] = useOutletContext();
	const [actionsVisible, setActionsVisible] = React.useState(false);
	const actionsRef = React.useRef(null);
	const [newText, setNewText] = React.useState('');

	useOutsideAlerter(actionsRef, () => setActionsVisible(false));

	const handleDelete = async () => {
		const confirmed = window.confirm(
			'Are you sure you want to delete your account? This action cannot be undone.'
		);
		if (!confirmed) return;
		try {
			await Api.delete(`/user/${userData._id}`);
			console.log('Account deleted successfully.');
			navigate('/app/login');
			toast.success('Account Successfully Deleted!');
		} catch (error) {
			console.error(error);
		}
	};

	const handleProfileUpdateSubmit = async () => {
		if (!username || username.trim() === '') {
			toast.warn('Invalid name');
			return;
		}
		if (username !== userData.name) {
			await Api.put(`/user`, { name: username });
		}
		if (newText) {
			await Api.put(`/user/changeText/${userData._id}`, { textSize: newText });
		}
		if (
			!password.newPassword &&
			!password.confirmPassword &&
			!password.currentPassword
		) {
			toast.success('Profile Updated');
			return;
		}
		if (password.newPassword === password.confirmPassword) {
			try {
				await Api.put(`/user/password/${userData._id}`, {
					userNewPassword: password.newPassword,
					userOldPassword: password.currentPassword
				});
			} catch (error) {
				toast.warn('Invalid password or mismatched');
				return;
			}
		} else if (password.newPassword !== password.confirmPassword) {
			toast.warn('Invalid password or mismatched');
			return;
		}
		toast.success('Profile Updated');
		setPassword({
			currentPassword: '',
			newPassword: '',
			confirmPassword: ''
		});
	};

	const toggleEditMode = () => {
		setIsEditMode(!isEditMode);
		if (isEditMode) {
			handleProfileUpdateSubmit();
		}
	};

	const handleChangeUserName = (event) => {
		setUsername(event.target.value);
	};

	const handleCheckboxChange = async (event) => {
		setToggle(event);
		await Api.put(`/user/translateUser`, { translateUser: event });
	};

	const handlePasswordChange = (event) => {
		const { name, value } = event.target;
		setPassword({ ...password, [name]: value });
	};

	const handleAvatarChange = (source) => {
		const uri = URL.createObjectURL(source);
		let data = { ['image']: uri };
		const formData = new FormData();
		formData.append('file', source);
		Api.put(`/user/avatar`, formData)
			.then((res) => {
				toast.success('Avatar Updated');
				setAvatar(getAvatarPath(res.data.path));
				return res.data.path;
			})
			.catch((error) => {
				toast.error('Error');
			});
	};

	const handleTextChange = (event) => {
		if (event.target.value === 'small') setNewText('0.80');
		if (event.target.value === 'medium') setNewText('0.90');
		if (event.target.value === 'large') setNewText('1.15');
		return;
	};

	const handleLanguageChange = React.useCallback(async (e) => {
		const selectedLanguage = e.target.value;
		setUserLanguage(selectedLanguage);

		// Map the language code to its corresponding name
		const selectedLanguageName = languages.find(
			(language) => language.language === selectedLanguage
		)?.name;

		// Map the user language code to its corresponding name
		const userLanguageName = languages.find(
			(language) => language.language === userLanguage
		)?.name;

		if (selectedLanguage === userLanguage) {
			toast.success('Your language is already set to ' + userLanguageName);
		} else {
			try {
				await Api.put(`/user/language/${userData._id}`, {
					language: selectedLanguage
				});
				toast.success(
					'Your language has been changed to ' + selectedLanguageName
				);
				setUserLanguage(selectedLanguage);
			} catch (error) {
				toast.error('Failed to update language');
			}
		}
	});

	const languageDropdown = (
		<DropdownInput
			label='Language'
			value={userLanguage}
			onChange={handleLanguageChange}
			style={{ marginTop: 0 }}
		/>
	);

	useEffect(async () => {
		let imag = await getAvatarPath(userData.avatar);
		setAvatar(imag);
	}, [userData.avatar]);

	return (
		<div
			style={{
				display: 'block',
				width: '100%',
				height: '103%',
				padding: 30,
				backgroundColor: '#DEDEDE',
				overflowY: 'scroll',
				borderRadius: '25px'
			}}
		>
			<NavLink
				to='/app/chat'
				className='back-to-chat'
				style={{ alignSelf: 'flex-start' }}
			>
				<Icon
					style={{ position: 'fixed' }}
					name='arrow-back-outline'
					size={30}
					color={'gray'}
				/>
			</NavLink>
			<Tabs>
				<div tab='Profile' key='1'>
					<EditProfileTab user={user} />
				</div>
				<div tab='Password' key='2'>
					<PasswordTab user={user} />
				</div>
				<div tab='Settings' key='3'>
					<SettingsTab user={user} />
				</div>
			</Tabs>
		</div>
	);
}
export default MyProfile;
