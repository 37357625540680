import React, { useEffect, useRef } from 'react';

function Matches( {...matchProps} ) {
    // Match Military Time to Normal Time
    const timeMap = {
        13: 1,
        14: 2,
        15: 3,
        16: 4,
        17: 5,
        18: 6,
        19: 7,
        20: 8,
        21: 9,
        22: 10,
        23: 11,
        24: 12,
    };

    const ref = useRef(null);

    // Refresh Play by Play every 35 Seconds
    useEffect(() => {
        ref.current = setInterval(matchProps.fetchData, 1 * 35 * 1000); // 1 Minute 1 * 60 * 1000

        return () => {
            if(ref.current){
              clearInterval(ref.current)
            };
          };
    }, []);

    const currentMatches = matchProps.matches.filter((match) => {
        return match.status.type !== 'finished' && match.status.type !== 'notstarted';
    })

    return (
        <>
        {currentMatches.length > 0 ? <h2 className='matchWrapperTitle'>Live Now</h2> : <></>}
        {currentMatches.map((match) => {
                function handleMatchClick() {
                    matchProps.setCurrentMatch(match.id);
                    matchProps.setHomeTeam(match.homeTeam.name);
                    matchProps.setAwayTeam(match.awayTeam.name);
                    matchProps.setScoreHome(match.homeScore.current);
                    matchProps.setScoreAway(match.awayScore.current);
                    matchProps.setHomeTeamID(match.homeTeam.id);
                    matchProps.setAwayTeamID(match.awayTeam.id);
                    matchProps.setStartTime(match.startTimestamp);
                    matchProps.setHomeNameCode(match.homeTeam.nameCode);
                    matchProps.setAwayNameCode(match.awayTeam.nameCode);
                };

                // Get Match Start Time
                let matchTimeStamp = match.startTimestamp;
                let matchDateMili = new Date(matchTimeStamp * 1000);
                let matchHours = matchDateMili.getHours();
                let matchMinutes = "0" + matchDateMili.getMinutes();
                let formatTime = matchHours + ':' + matchMinutes.substr(-2);
                if (matchHours >= 13) {
                    matchHours = timeMap[matchHours];
                    formatTime = matchHours + ':' + matchMinutes.substr(-2) + ' PM';
                } else {
                    formatTime = matchHours + ':' + matchMinutes.substr(-2) + ' AM';
                };

                return (
                    <div key={match.id}>
                    {matchTimeStamp < Math.floor(Date.now() / 1000) ? 
                    <div className='matchWrapper'>
                        <div className='teamNamesWrapper'>
                            <div className='nameScoreWrapper'>
                                <h2 className='teamName'>{match.homeTeam.nameCode}</h2>
                                <h2 className='score'>{match.homeScore.current}</h2>
                            </div>
                            <div className='nameScoreWrapper'>
                                <h2 className='teamName'>{match.awayTeam.nameCode}</h2>
                                <h2 className='score'>{match.awayScore.current}</h2>
                            </div>
                        </div>
                        <button className='matchButton' onClick={() => handleMatchClick()}><div className='triangle'></div></button>
                    </div>
                    : <></>}
                    </div>
                )
            })}
        </>
    )
}

export default Matches;