import React, { useEffect, useState, useRef } from 'react';
import { getEventsByDate, getStatsByEvent, getBestPlayers, getLineup, getStandings, getCricketStatsByEvent, 
searchLeagues, searchGamesByCategory } from './sofaScoreUtils';
import { Link,} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './index.css';

// Assets Imports
import LiveSportsLogo from '../../components/LiveSports/SportsAssets/LiveSportsLogo.jpg';
import searchIcon from '../../components/LiveSports/SportsAssets/search.png';
import SportsNavList from '../../components/LiveSports/SportsNavList';
import chatIcon from '../../components/LiveSports/SportsAssets/chatIcon.png';
import chatIconBlue from '../../components/LiveSports/SportsAssets/chatIconBlue.png';

// Component Imports
import DropDown from '../../components/LiveSports/DropDown';
import Matches from '../../components/LiveSports/Matches';
import UpcomingMatches from '../../components/LiveSports/UpcomingMatches';
import PastMatches from '../../components/LiveSports/PastMatches';
import SearchedLeagues from '../../components/LiveSports/SearchedLeagues';

import MatchStats from '../../components/LiveSports/MatchStats';

function LiveSports() {
    const [selectedSport, setSelectedSport] = useState('Basketball');
    const [league, setLeague] = useState('NBA');
    const [matches, setMatches] = useState([]);
    const [counter, setCounter] = useState(0);
    const [loading, setLoading] = useState(false);

    // Search Bar
    const [searchQuery, setSearchQuery] = useState('');
    const [searchedLeagues, setSearchedLeagues] = useState([]);
    const [showSearch, setShowSearch] = useState(false);
    const [category, setCategory] = useState('');
    const [showingSearchedGames, setShowingSearchedGames] = useState(false);
    const [bannerClick, setBannerClick] = useState(0);

    // Standings State
    const [standings, setStandings] = useState([]);

    // League DropDown Menu
    const [showingDropDown, setShowingDropDown] = useState(false);
    const [menuDisplay, setMenuDisplay] = useState('none');

    //Stats State Handling
    const [showingStats, setShowingStats] = useState(false);
    const [currentMatch, setCurrentMatch] = useState('none');
    const [allStats, setAllStats] = useState([]);
    const [bestPlayerStats, setBestPlayerStats] = useState([]);
    const [lineUp, setLineUp] = useState([]);

    const [homeTeam, setHomeTeam] = useState('');
    const [awayTeam, setAwayTeam] = useState('');

    const [scoreHome, setScoreHome] = useState(0);
    const [scoreAway, setScoreAway] = useState(0);

    const [homeNameCode, setHomeNameCode] = useState('');
    const [awayNameCode, setAwayNameCode] = useState('');

    const [homeTeamID, setHomeTeamID] = useState('');
    const [awayTeamID, setAwayTeamID] = useState('');
    const [homeTeamLogo, setHomeTeamLogo] = useState('');
    const [awayTeamLogo, setAwayTeamLogo] = useState(''); 

    const [startTime, setStartTime] = useState(0);
    const [currentDatesList, setCurrentDatesList] = useState([]);

    // Calendar
    const [startDate, setStartDate] = useState(new Date());
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    function setDates() {
        let currentDate = startDate || new Date();
        let yesterdayDate = new Date(currentDate);
        yesterdayDate.setDate(currentDate.getDate() - 1);
    
        let beforeYesterdayDate = new Date(currentDate);
        beforeYesterdayDate.setDate(currentDate.getDate() - 2);
    
        let tomorrowDate = new Date(currentDate);
        tomorrowDate.setDate(currentDate.getDate() + 1);
    
        let afterTomorrowDate = new Date(currentDate);
        afterTomorrowDate.setDate(currentDate.getDate() + 2);
    
        let datesList = [
            { dateTime: beforeYesterdayDate, day: beforeYesterdayDate.getDate(), month: beforeYesterdayDate.getMonth() + 1 }, 
            { dateTime: yesterdayDate, day: yesterdayDate.getDate(), month: yesterdayDate.getMonth() + 1 }, 
            { dateTime: currentDate, day: currentDate.getDate(), month: currentDate.getMonth() + 1 }, 
            { dateTime: tomorrowDate, day: tomorrowDate.getDate(), month: tomorrowDate.getMonth() + 1 }, 
            { dateTime: afterTomorrowDate, day: afterTomorrowDate.getDate(), month: afterTomorrowDate.getMonth() + 1 }
        ];
    
        setCurrentDatesList(datesList);
    }

    // Set Dates When Picked by Calendar
    useEffect(() => {
        setDates();
        startLoading(15);
        setShowSearch(false);
    }, [startDate]);

    const monthsMap = {
        1: 'JANUARY',
        2: 'FEBRUARY',
        3: 'MARCH',
        4: 'APRIL',
        5: 'MAY',
        6: 'JUNE',
        7: 'JULY',
        8: 'AUGUST',
        9: 'SEPTEMBER',
        10: 'OCTOBER',
        11: 'NOVEMBER',
        12: 'DECEMBER',
    }

    function setPrevDate() {
        setStartDate(currentDatesList[1].dateTime);
    };

    function setNextDate() {
        setStartDate(currentDatesList[3].dateTime);
    };

    // Calendar Icon
    function CalIcon () {
        return (
            <>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" id='calIcon' className="bi bi-calendar-week" viewBox="0 0 16 16">
                 <path id='calIconPath' d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/> <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/> 
            </svg>
            </>
        )
    };

    // Loading Bar
    const progressBar = document.getElementById("bar");
    let barWidth = 0;
    
    const animate = () => {
        if (progressBar) {
            barWidth++;
            progressBar.style.width = `${barWidth}%`;
        };
    };

    function startLoading(speed) {
        // Animate loading bar
        let intervalID = setInterval(() => {
            if (barWidth === 100) {
              clearInterval(intervalID);
              progressBar.style.width = 0;
            } else {
              animate();
            }
          }, speed); // Bar Speed
    };

    // Fetch League + Games When Banner is Clicked or League or Date Change
    const fetchData = async () => {
        const formattedDate = `${startDate.getFullYear()}-${('0' + (startDate.getMonth() + 1)).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`;
        if (selectedSport === 'Basketball') {
            const initialLeague = 'NBA';
            let league_basketball = initialLeague;

            if (league.length < 1 || bannerClick > 0) {
                league_basketball = await getEventsByDate(initialLeague, formattedDate, 'basketball');
                setLeague(initialLeague);
                setShowingSearchedGames(false);
            } else {
                league_basketball = await getEventsByDate(league, formattedDate, 'basketball');
                setLeague(league);
            };

            setMatches(league_basketball);
        };
        if (selectedSport === 'Soccer') {
            const initialLeague = 'LaLiga';
            let league_soccer = initialLeague;

            if (league.length < 1 || bannerClick > 0) {
                league_soccer = await getEventsByDate(initialLeague, formattedDate, 'football');
                setLeague(initialLeague);
                setShowingSearchedGames(false);
            } else {
                league_soccer = await getEventsByDate(league, formattedDate, 'football');
                setLeague(league);
            };

            setMatches(league_soccer);
        };
        if (selectedSport === 'Baseball') {
            const initialLeague = 'MLB';
            let league_baseball = initialLeague;

            if (league.length < 1 || bannerClick > 0) {
                league_baseball = await getEventsByDate(initialLeague, formattedDate, 'baseball');
                setLeague(initialLeague);
                setShowingSearchedGames(false);
            } else {
                league_baseball = await getEventsByDate(league, formattedDate, 'baseball');
                setLeague(league);
            };

            setMatches(league_baseball);
        };
        if (selectedSport === 'Football') {
            const initialLeague = 'NFL';
            let league_football = initialLeague;

            if (league.length < 1 || bannerClick > 0) {
                league_football = await getEventsByDate(initialLeague, formattedDate, 'american-football');
                setLeague(initialLeague);
                setShowingSearchedGames(false);
            } else {
                league_football = await getEventsByDate(league, formattedDate, 'american-football');
                setLeague(league);
            };

            setMatches(league_football);
        };
        if (selectedSport === 'Cricket') {
            const initialLeague = 'Big Bash League';
            let league_cricket = initialLeague;

            if (league.length < 1 || bannerClick > 0) {
                league_cricket = await getEventsByDate(initialLeague, formattedDate, 'cricket');
                setLeague(initialLeague);
                setShowingSearchedGames(false);
            } else {
                league_cricket = await getEventsByDate(league, formattedDate, 'cricket');
                setLeague(league);
            };

            setMatches(league_cricket);
        };
    };

    // Fetch League + Games When Banner is Clicked or League or Date Change
    useEffect(() => {
        fetchData();
        setShowingStats(false);
    }, [selectedSport, startDate, counter]);

    // Get Stats when a game is selected
    const fetchStats = async () => {
        // Set Standings
        let teams = [];
        let seasonId = matches[0].season.id;
        let tournamentID = matches[0].tournament.id;

        teams = await getStandings(seasonId, tournamentID);
        setStandings(teams);

        // Set AllStats & BestPlayer Stats
        if (selectedSport !== 'Cricket') {
            const statsRes = await getStatsByEvent(currentMatch);

            if (statsRes && statsRes[0]) {
                setAllStats(statsRes[0].groups); // Set to 1 for 1st quarter stats only 2 for...
            };

            if (statsRes === undefined) {
                setAllStats([]);
            };

        } else {
            const statsRes = await getCricketStatsByEvent(currentMatch);

            if (statsRes && statsRes[0]) {
                setAllStats([statsRes[0], statsRes[1]]); 
            };

            if (statsRes === undefined) {
                setAllStats([]);
            };
        }

        let playerStatsRes = undefined;

        if (selectedSport !== 'Cricket') {
            playerStatsRes = await getBestPlayers(currentMatch);
        }

        if (playerStatsRes) {
            setBestPlayerStats(playerStatsRes);
        };

        if (playerStatsRes === undefined) {
            setBestPlayerStats([]);
        };

        // Get Lineup
        const lineUpRes = await getLineup(currentMatch);

        if (playerStatsRes) {
            setLineUp(lineUpRes);
        };

        if (playerStatsRes === undefined) {
            setLineUp([]);
        };

        // Get Team Logos

    };

    // Get Stats when a game is selected
    useEffect(() => {
        if (currentMatch !== 'none') {
        setLoading(true);
        fetchStats();

        startLoading(20);

        // We need to wait for all the API data to be set
        setTimeout(function() {
            setShowingStats(true);
            setLoading(false);
        }, 3000);

        }
    }, [currentMatch]);

    // Get Games When Search League is Clicked
    useEffect(() => {
        const fetchCatGames = async () => {
            const formattedDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
            const catGames = await searchGamesByCategory(category, formattedDate);
            setMatches(catGames);
        };

        if (category !== '') {
            fetchCatGames();
            setShowingStats(false);
        };
    }, [category]);

    const handleLeagueChange = async () => {
        !showingDropDown ? setShowingDropDown(true) : setShowingDropDown(false);
    };

    // DropDown Menu Leagues
    let dropBasketballLeagues = ['NBA', 'Euroleague', 'Olympic Games', 'FIBA EuroBasket'];
    let dropSoccerLeagues = ['LaLiga', 'Bundesliga', 'Serie A', 'Ligue 1', 'CONMEBOL Libertadores', 'Copa América'];
    let dropBaseballLeagues = ['MLB', 'World Baseball Classic', 'Cuba Serie Nacional', 'Serie del Caribe', 'Pro Yakyu - NPB'];
    let dropFootballLeagues = ['NFL', 'NFL, Playoffs', 'NFL Preseason'];
    let dropCricketLeagues = ['Big Bash League', 'ICC Cricket World Cup', 'Indian Premier League', 'T20 International', 'Asia Cup'];

    const dropDownProps = {
        selectedSport,
        setLeague,
        startLoading,
        dropBasketballLeagues,
        dropSoccerLeagues,
        dropBaseballLeagues,
        dropFootballLeagues,
        dropCricketLeagues,
        setShowSearch,
        setBannerClick,
        setShowingDropDown
    };

    // Search Bar API Call
    const handleSearch = async (e) => {
        e.preventDefault();
        if (bannerClick !== 0) {
            setBannerClick(0)
        }; 
        startLoading(15);
        const searchResults = await searchLeagues(searchQuery);
        setShowingStats(false);
        setSearchedLeagues(searchResults);
        setShowSearch(true);
    };

    // Match sport input to API sport ID
    const sportsMap = {
        1: 'Soccer',
        2: 'Basketball',
        4: 'Hockey',
        5: 'Tennis',
        12: 'Rugby',
        62: 'Cricket',
        63: 'Football',
        64: 'Baseball',
    };

    const matchProps = {
        matches, 
        setCurrentMatch,
        setHomeTeam,
        setAwayTeam,
        setScoreHome,
        setScoreAway,
        fetchData,
        setHomeTeamID,
        setAwayTeamID,
        setStartTime,
        setHomeNameCode,
        setAwayNameCode
    };

    const searchedLeaguesProps = {
        searchedLeagues,
        sportsMap,
        setLeague,
        setCategory,
        setSelectedSport,
        setBannerClick,
        setShowingSearchedGames
    };

    const statsProps = {
        allStats, 
        bestPlayerStats, 
        homeTeam, 
        awayTeam, 
        scoreHome, 
        scoreAway, 
        lineUp, 
        standings, 
        currentMatch, 
        fetchStats, 
        homeTeamID,
        awayTeamID,
        startTime,
        selectedSport,
        homeNameCode,
        awayNameCode
    };

    //Banner Props
    const bannerProps = {
        setSelectedSport,
        setStartDate,
        setCounter,
        setAllStats,
        setBestPlayerStats,
        setShowingDropDown,
        setShowingStats,
        setScoreHome,
        setScoreAway,
        setStandings,
        startLoading,
        setShowSearch,
        setLeague,
        setBannerClick,
        bannerClick,
        selectedSport,
        counter,
    };

        const dropDownItems = [
            {event: 'Basketball', id: 2},
            {event: 'Soccer', id: 1},
            {event: 'Baseball', id: 64},
            {event: 'Football', id: 1},
            {event: 'Cricket', id: 62}
        ];

        function handleMobileMenu() {
            const menu = document.getElementById('dropDownMenuMobile');
            if (menu && menu.style.display === 'none') {
                setMenuDisplay('block');
            } else {
                setMenuDisplay('none');
            };
        };

    const ref = useRef(null);

    // Refresh Scores + Matches Every 30 seconds
    useEffect(() => {
        ref.current = setInterval(fetchData, 1 * 30 * 1000); // 30 sec: 1 Minute 1 * 60 * 1000

        return () => {
            if(ref.current){
              clearInterval(ref.current);
            };
          };
    }, []);

    return (
        <>
        <div id="progress">
            <div id="bar"></div>
        </div>
        <div id='sportsNavbar'>
            <div id='titleLogoWrapperNavbar'>
                <img id='liveSportsLogoNavbar' src={LiveSportsLogo} alt='live-sports-logo'/>
                <div id='sportsListWrapperNavbar'>
                    <SportsNavList {...bannerProps} />
                </div>
                <label id='hamburgerMenu' aria-label="hamburgerMenu">
                    <input onClick={handleMobileMenu} id='menuCheck' type='checkbox' aria-label="menuCheck"/>
                </label>
            </div>
            <form id='searchBarFormNavbar' onSubmit={handleSearch}>
            <Link to='/app/chat' id='chatIconsNavbar'>
                <img id='chatIconNavbar' className='chatIconNavbar' src={chatIcon} alt='chat'/>
                <img id='chatIconBlueNavbar' className='chatIconNavbar' src={chatIconBlue} alt='chat'/>
            </Link>
            <img id='searchIconNavbar' src={searchIcon} alt='searchIcon'/>
            <input id='searchBarNavbar' placeholder='Search' onChange={(e) => {setSearchQuery(e.target.value)}} value={searchQuery}/>
            </form>
        </div>
        {window.innerWidth <= 700 ? 
        <div style={{display: menuDisplay}} id='dropDownMenuMobile'>
            {dropDownItems.map((sport) => {
                        function handleDropDownClick() {
                            setBannerClick(bannerClick + 1);
                            setSelectedSport(sport.event);
                            setCounter(bannerProps.counter + 1);
                            setAllStats([]);
                            setBestPlayerStats([]);
                            setShowingDropDown(false);
                            setShowingStats(false);
                            setScoreHome(0);
                            setScoreAway(0);
                            setStandings([]);
                            startLoading(15);
                            setShowSearch(false);
                        };

                return (
                    <h2 onClick={handleDropDownClick} className='dropDownMobileItem'>{sport.event}</h2>
                )
            })}
        </div>
        : <></>}
        <div id='sportsWrapper'>
            <div id='datesCalWrapper'>
                <svg onClick={setPrevDate} className='dateSVGLeft' viewBox="0 0 24 24">
                    <path d="M19.653 9.369l-0.916-0.892-6.705 6.681-6.681-6.681-0.917 0.892 7.597 7.622z"/>
                </svg>
                <div id='currentDatesWrapper'>
                {currentDatesList.map((dateObject) => {
                    function handleDateClick() {
                        setStartDate(dateObject.dateTime);
                    }

                    return (
                    <div id='currentDateWrapper' key={dateObject.dateTime} onClick={handleDateClick}>
                        {dateObject.dateTime.getDay() !== new Date().getDay() ?
                        <div id='currentDateContainer'>
                            <div id='dateWeekContainer'>
                                <h2 id='dateWeek'>{weekdays[dateObject.dateTime.getDay()]}</h2>
                                <h2 id='selectedDate'>{monthsMap[dateObject.month].slice(0, 3)} {dateObject.day}</h2>
                            </div>
                        </div>
                        : 
                        <div id='currentDateContainer'>
                            <div id='dateWeekContainer'>
                                <h2 id='dateWeek'>Today</h2>
                                <h2 id='selectedDate'>{monthsMap[dateObject.month].slice(0, 3)} {dateObject.day}</h2>
                            </div>
                        </div>}
                    </div>
                    )
                })}
                </div>
                <svg onClick={setNextDate} className='dateSVGRight' viewBox="0 0 24 24">
                    <path d="M19.653 9.369l-0.916-0.892-6.705 6.681-6.681-6.681-0.917 0.892 7.597 7.622z"/>
                </svg>
                <div id='calIconContainer'>
                    <DatePicker id='calendar' selected={startDate} onChange={(date) => setStartDate(date)} customInput={<div id='calContainer'><CalIcon/></div>}/>
                </div>
            </div>
            {league && !showSearch ? <h2 onClick={handleLeagueChange} id='leagueTitle'>{league !== 'NFL, Playoffs' ? league : 'Super Bowl LVIII' } {startDate.getFullYear()} Season</h2> : <><h2 id='leagueTitle'>Search Results: {searchQuery}</h2></>}
            <div id='dropDownContainer'>
            {showingDropDown ? 
                <DropDown {...dropDownProps}/>
            : <></>}
            </div>
            {showSearch && !showingStats && !showingSearchedGames ? 
            <>
            <div id='searchedLeaguesContainer'>
                <SearchedLeagues {...searchedLeaguesProps} />
            </div>
            </>
            : <></>}
            {matches === null ? <h2 id='noMatchesMessage'>No Matches Found!</h2> : <></>}
            {league && matches && matches !== null && !showingStats && !showSearch ? 
            <>
            <div id='allMatchesWrapper'>
                <div className='matchListWrapper'>
                    <Matches {...matchProps} />
                    <PastMatches {...matchProps} />
                </div>
                <div className='matchListWrapper'>
                <h2 className='matchWrapperTitle'>Up Next</h2>
                    <UpcomingMatches {...matchProps} />
                </div>
            </div>
            </>
            : <></>}
            {category && matches && matches !== null && !showingStats && showSearch && !showingSearchedGames ? 
            <>
            <div id='allMatchesWrapper'>
                <div className='matchListWrapper'>
                    <Matches {...matchProps} />
                    <PastMatches {...matchProps} />
                </div>
                <div className='matchListWrapper'>
                <h2 className='matchWrapperTitle'>Up Next</h2>
                    <UpcomingMatches {...matchProps} />
                </div>
            </div>
            </>
            : <></>}
            {showingStats ? 
                <MatchStats {...statsProps}/>
            : <></>}
        </div>
         </>
        )
}

export default LiveSports;