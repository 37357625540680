import React from 'react';

function Lineups( {...props} ) {

    return (
        <div id='lineUpsContainer'>
            <div id='lineUp'>
                <h2 className='statsContainerTitleLineup'>{props.homeTeam} Lineup</h2>
                <div className='statsRowLineup'>
                    <h2 className='statsText'>NO.</h2>
                    <h2 className='statsText'>Player</h2>
                    <h2 className='statsText'>Position</h2>
                </div>
                {props.lineUp.home.players.map((player) => {
                    return (
                        <div className='statsRowLineup'>
                            <h2 className='statsText'>{player.shirtNumber}</h2>
                            <h2 className='statsText'>{player.player.name}</h2>
                            <h2 className='statsText'>{player.position}</h2>
                        </div>
                    )
                })}
            </div>
            <div id='lineUp'>
                <h2 className='statsContainerTitleLineup'>{props.awayTeam} Lineup</h2>
                <div className='statsRowLineup'>
                    <h2 className='statsText'>NO.</h2>
                    <h2 className='statsText'>Player</h2>
                    <h2 className='statsText'>Position</h2>
                </div>
                {props.lineUp.away.players.map((player) => {
                    return (
                        <div className='statsRowLineup'>
                            <h2 className='statsText'>{player.shirtNumber}</h2>
                            <h2 className='statsText'>{player.player.name}</h2>
                            <h2 className='statsText'>{player.position}</h2>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default Lineups;