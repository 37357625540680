import React from "react";
import { Container, Search } from "./styles";
import Icon from "../Icon";
import debounce from "lodash.debounce";
import { Row } from "../../utils/sharedStyles";
import { Avatar, ChatList, Item, UserName } from "../Sidebar/styles";
import { getAvatarPath } from "../../utils/helpers";
import Api from "../../config/axios";
import GroupChatModal from "./GroupChatModal";
import "./index.css";
import SingleChatModal from "./SingleChatModal"; // Import the SingleChatModal component

function CreateChat({ close, user, ...props }) {
  const [search, setSearch] = React.useState("");
  const [searchDb, setSearchDb] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const [showGroupModal, setShowGroupModal] = React.useState(false);
  const [showSingleModal, setShowSingleModal] = React.useState(false); // State for controlling the SingleChatModal
  const [selectedOption, setSelectedOption] = React.useState("search-name");

  const debouncedSave = React.useCallback(
    debounce((nextValue) => setSearchDb(nextValue), 0),
    []
  );

  const handleChange = (val) => {
    setSearch(val);
    debouncedSave(val);
  };

  React.useEffect(() => {
    searchUser();
  }, [searchDb, selectedOption]);

  const searchUser = React.useCallback(async () => {
    if (searchDb.length > 0) {
      const filteredContacts = user.contacts.filter((contact) => {
        const searchValue = searchDb.toLowerCase();
        if (selectedOption === "search-name") {
          return contact.name.toLowerCase().includes(searchValue);
        } else if (selectedOption === "search-email") {
          return contact.email.toLowerCase().includes(searchValue);
        } else if (selectedOption === "search-phone") {
          return contact.phone.toLowerCase().includes(searchValue);
        }
        return false;
      });
      setUsers(filteredContacts);
    } else {
      setUsers([]);
    }
  }, [searchDb, selectedOption, user.contacts]);

  const onClick = React.useCallback(async (target) => {
    const res = (await Api.get("/chat/conversation-exist/" + target._id)).data;
    if (res.isExist) props.setChatId(res.conversationId);
  }, []);

  const handleOpenGroupModal = () => {
    setShowGroupModal(true);
  };

  const handleCloseGroupModal = () => {
    setShowGroupModal(false);
  };

  const handleOpenSingleModal = () => {
    setShowSingleModal(true);
  };

  const handleCloseSingleModal = () => {
    setShowSingleModal(false);
  };

  const handleSearch = (event) => {
    setSelectedOption(event.target.value);
    searchUser();
  };

  const renderItem = React.useCallback(
    (item) => {
      return (
        <Item onClick={() => onClick(item)} key={item._id}>
          <Row>
            <Avatar src={getAvatarPath(item.avatar)} />
            <div>
              <UserName>{item.name}</UserName>
              <div className="subTxt">
                {selectedOption === "search-phone" ? item.phone : item.email}
              </div>
            </div>
          </Row>
        </Item>
      );
    },
    [selectedOption, onClick]
  );

  return (
    <Container>
      <Row align="center" justify="space-between" className="head">
        <h3>Contacts</h3>
        <div onClick={close}>
          <Icon name="close" />
        </div>
      </Row>
      <div className="searchContainer">
        <div className="icon">
          <Icon name="search" size={21} />
        </div>
        <Search
          placeholder="Search"
          value={search}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />
      </div>
      <div className="form-check">
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            value="search-name"
            checked={selectedOption === "search-name"}
            onChange={handleSearch}
          />
          <label className="form-check-label">Name</label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            value="search-email"
            checked={selectedOption === "search-email"}
            onChange={handleSearch}
          />
          <label className="form-check-label">Email</label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            value="search-phone"
            checked={selectedOption === "search-phone"}
            onChange={handleSearch}
          />
          <label className="form-check-label">Phone</label>
        </div>
      </div>
      <ChatList style={{ paddingBottom: 140 }}>
        <button
          onClick={handleOpenGroupModal}
          style={{
            marginLeft: "85px",
            marginBottom: "15px",
            backgroundColor: "#338bcf",
            color: "#fff",
            padding: "8px 16px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          Create Group Chat
        </button>

        <button
          onClick={handleOpenSingleModal}
          style={{
            margin: "0 auto", 
            backgroundColor: "#338bcf",
            color: "#fff",
            padding: "8px 16px",
            marginBottom: "10px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            display: "block" 
          }}
        >
          Send Message
        </button>

        {!search &&
          user.contacts
            .filter((i) => i.email !== "darpan.patel@gibber.chat")
            .map(renderItem)}
        {!!users.length &&
          users
            .filter((i) => i.email !== "darpan.patel@gibber.chat")
            .map(renderItem)}
      </ChatList>
      <GroupChatModal user={user} show={showGroupModal} handleClose={handleCloseGroupModal} />
      <SingleChatModal user={user} show={showSingleModal} handleClose={handleCloseSingleModal} /> {/* Render the SingleChatModal */}
    </Container>
  );
}

export default CreateChat;
