import React from 'react';

function DropDown( {...dropDownProps} ) {
    return (
        <div id='dropDownMenuLeagues'>
                    {dropDownProps.selectedSport === 'Basketball' ? 
                    <>
                    {dropDownProps.dropBasketballLeagues.map((basketballLeague) => {
                        function handleClick() {
                            dropDownProps.setLeague(basketballLeague);
                            dropDownProps.startLoading(15);
                            dropDownProps.setShowSearch(false);
                            dropDownProps.setBannerClick(0);
                            dropDownProps.setShowingDropDown(false);
                        };

                        return (
                            <h2 onClick={handleClick} className='dropItem'>{basketballLeague}</h2>
                        )
                    })}
                    </> 
                    : <></> }
                    {dropDownProps.selectedSport === 'Soccer' ? 
                    <>
                    {dropDownProps.dropSoccerLeagues.map((soccerLeague) => {
                        function handleClick() {
                            dropDownProps.setLeague(soccerLeague);
                            dropDownProps.startLoading(15);
                            dropDownProps.setShowSearch(false);
                            dropDownProps.setBannerClick(0);
                            dropDownProps.setShowingDropDown(false);
                        };

                        return (
                            <h2 onClick={handleClick} className='dropItem'>{soccerLeague}</h2>
                        )
                    })}
                    </> 
                    : <></> }
                    {dropDownProps.selectedSport === 'Baseball' ? 
                    <>
                    {dropDownProps.dropBaseballLeagues.map((baseballLeague) => {
                        function handleClick() {
                            dropDownProps.setLeague(baseballLeague);
                            dropDownProps.startLoading(15);
                            dropDownProps.setShowSearch(false);
                            dropDownProps.setBannerClick(0);
                            dropDownProps.setShowingDropDown(false);
                        };

                        return (
                            <h2 onClick={handleClick} className='dropItem'>{baseballLeague}</h2>
                        )
                    })}
                    </> 
                    : <></> }
                    {dropDownProps.selectedSport === 'Football' ? 
                    <>
                    {dropDownProps.dropFootballLeagues.map((footballLeague) => {
                        function handleClick() {
                            dropDownProps.setLeague(footballLeague);
                            dropDownProps.startLoading(15);
                            dropDownProps.setShowSearch(false);
                            dropDownProps.setBannerClick(0);
                            dropDownProps.setShowingDropDown(false);
                        };

                        let leagueTitle = footballLeague;
                        footballLeague === 'NFL, Playoffs' ? leagueTitle = 'Super Bowl LVIII' : leagueTitle = footballLeague;

                        return (
                            <h2 onClick={handleClick} className='dropItem'>{leagueTitle}</h2>
                        )
                    })}
                    </> 
                    : <></> }
                    {dropDownProps.selectedSport === 'Cricket' ? 
                    <>
                    {dropDownProps.dropCricketLeagues.map((cricketLeague) => {
                        function handleClick() {
                            dropDownProps.setLeague(cricketLeague);
                            dropDownProps.startLoading(15);
                            dropDownProps.setShowSearch(false);
                            dropDownProps.setBannerClick(0);
                            dropDownProps.setShowingDropDown(false);
                        };

                        return (
                            <h2 onClick={handleClick} className='dropItem'>{cricketLeague}</h2>
                        )
                    })}
                    </> 
                    : <></> }
                </div>
    )
}

export default DropDown;