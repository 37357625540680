import React from 'react';

function CoasterTimeFeed( {...parksListProps} ) {
    return (
        <div id='coasterTimesFeedContainer'>
        {parksListProps.blogPosts.map((post) => {
        // The img url isn't in json, so we grab it manually with regex
        // It is always the 2nd url to be found - [1]
        const postContent = post.content;
        let postUrl = postContent.match(/https?:\/\/[^\s]+/g)[1]; 
        
        // Remove 'Introduction:'
        const postDescription = post.description.slice(14);
                
            return (
                <div className='coasterBlogPost' key={post.guid}>
                <a className='postLink' href={post.link} target='_blank '>
                    <img src={postUrl} className='coasterPostImg' alt='post'/>
                    <h2 className='postTitle'>{post.title}</h2>
                    <p className='postDescription'>{postDescription}</p>
                </a>
                </div>
            )
        })}
        </div>
    )
}

export default CoasterTimeFeed;