import React, { useRef, useEffect,useState } from 'react';

const InactivityDetector = ({ timeout, onInactivity }) => {
  const activityTimer = useRef(null);
  // const [isTyping, setIsTyping] = useState(false);

  const resetTimer = () => {
    if (activityTimer.current) {
      clearTimeout(activityTimer.current);
    }

    activityTimer.current = setTimeout(onInactivity, timeout);
  };

  const handleUserActivity = () => {
    resetTimer();
  };

  /*
  const handleTypingStart = () => {
    // Send typing... to server
    setIsTyping(true);
    console.log('User is typing');
  };

  const handleTypingEnd = () => {
    
    setTimeout(() => {
      setIsTyping(false);
      console.log('User stopped typing');
      // Send green indicator to server

    }, 10000); // Wait for 10 seconds
  };
  */

  useEffect(() => {
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    document.addEventListener('scroll', handleUserActivity);
    // document.addEventListener('keyup', handleTypingStart);

    resetTimer();

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      document.removeEventListener('scroll', handleUserActivity);
      // document.removeEventListener('keyup', handleTypingEnd);

      if (activityTimer.current) {
        clearTimeout(activityTimer.current);
      }
    };
  }, [timeout, onInactivity]);

  return <></>; // or any other UI components you may want to render
};

export default InactivityDetector;