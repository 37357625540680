import React from 'react';

function MatchWrapperStats( {...props} ) {

    return (
        <div className='matchWrapperStats'>
            <div className='matchWrapperItem'>
                <h2 className='teamNameStats'>{props.homeName}</h2>
                <img className='teamLogo' src={`data:image/png;base64, ${props.homeLogoURL}`} alt='logo'/>
            </div>
            <div className='gameClockScoresWrapper'>
                <p className='gameclock'>{props.isLive ? props.currentTime : 'Game End'}</p>
                <div className='matchWrapperItem statsScoreContainer'>
                    <h2 className='statsScore'>{props.updatedScoreHome || props.homeScore}</h2>
                    <h2 className='versus'>VS</h2>
                    <h2 className='statsScore'>{props.updatedScoreAway || props.awayScore}</h2>
                </div>
            </div>
            <div className='matchWrapperItem'>
                <img className='teamLogo' src={`data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                <h2 className='teamNameStats'>{props.awayName}</h2>
            </div>
        </div>
    )

}

export default MatchWrapperStats;