import React, { useEffect } from 'react';
import CoasterTimeFeed from './CoasterTimeFeed';

function ParkAttractions( {...props} ) {

    // Scroll to top on render
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function resetState() {
        props.setShowingPark(false);
        props.setShowingRide(false);
    };

    const openAttractions = props.attractions.filter((attraction) => {
        return attraction.status === 'OPERATING';
    });

    const tempClosedAttractions = props.attractions.filter((attraction) => {
        return attraction.status === 'REFURBISHMENT';
    });

    const closedAttractions = props.attractions.filter((attraction) => {
        return attraction.status === 'CLOSED';
    });

    return (
        <div id='parkAttractionsContainer'>
            <h2 className='parkAttractionsParkName'>{props.parkData.name}</h2>
            <button id='parksBackButton' onClick={resetState}>Go Back</button>
            <div className='attractionsFeedWrapper'>
                <div id='attractionsColumn'>
                    <div className='parkTitleAttractionsContainer'>
                        <h2 className='parkCatTitle'>Attractions</h2>
                        <h2 className='attractionStatusTitle'>Open</h2>
                        <div className='parkAttractionsListContainer'>
                            {openAttractions.map((attraction) => {
                                return (
                                    <div className='attractionContainer' key={attraction.id}>
                                            <div className='attractionRow'>
                                                <h2 className='attractionName'>{attraction.name}</h2>
                                            </div>
                                            <div className='attractionRow attractionRowBottom'>
                                                <div className='attractionInfoContainer'>
                                                    <h2 className='infoText'>Wait Time</h2>
                                                    <h2 className='infoText'>{attraction.queue && attraction.queue.STANDBY && attraction.queue.STANDBY.waitTime !== null ? attraction.queue.STANDBY.waitTime + ` Mins` : `0 Mins`}</h2>
                                                </div>
                                                <div className='attractionInfoContainer'>
                                                    <h2 className='infoText'>Status</h2>
                                                    <h2 className='infoText'>{attraction.status}</h2>
                                                </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <h2 className='attractionStatusTitle'>Temporarily Closed</h2>
                        <div className='parkAttractionsListContainer'>
                            {tempClosedAttractions.map((attraction) => {
                                return (
                                    <div className='attractionContainer' key={attraction.id}>
                                            <div className='attractionRow'>
                                                <h2 className='attractionName'>{attraction.name}</h2>
                                            </div>
                                            <div className='attractionRow attractionRowBottom'>
                                                <div className='attractionInfoContainer'>
                                                    <h2 className='infoText'>Wait Time</h2>
                                                    <h2 className='infoText'>{attraction.queue && attraction.queue.STANDBY && attraction.queue.STANDBY.waitTime !== null ? attraction.queue.STANDBY.waitTime + ` Mins` : `0 Mins`}</h2>
                                                </div>
                                                <div className='attractionInfoContainer'>
                                                    <h2 className='infoText'>Status</h2>
                                                    <h2 className='infoText'>{attraction.status}</h2>
                                                </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <h2 className='attractionStatusTitle'>Closed</h2>
                        <div className='parkAttractionsListContainer'>
                            {closedAttractions.map((attraction) => {
                                return (
                                    <div className='attractionContainer' key={attraction.id}>
                                            <div className='attractionRow'>
                                                <h2 className='attractionName'>{attraction.name}</h2>
                                            </div>
                                            <div className='attractionRow attractionRowBottom'>
                                                <div className='attractionInfoContainer'>
                                                    <h2 className='infoText'>Wait Time</h2>
                                                    <h2 className='infoText'>{attraction.queue && attraction.queue.STANDBY && attraction.queue.STANDBY.waitTime !== null ? attraction.queue.STANDBY.waitTime + ` Mins` : `0 Mins`}</h2>
                                                </div>
                                                <div className='attractionInfoContainer'>
                                                    <h2 className='infoText'>Status</h2>
                                                    <h2 className='infoText'>{attraction.status}</h2>
                                                </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='parkTitleAttractionsContainer'>
                        <h2 className='parkCatTitle'>Shows</h2>
                        <div className='parkAttractionsListContainer'>
                            {props.shows.map((show) => {
                                return (
                                    <div className='attractionContainer' key={show.id}>
                                            <div className='attractionRow'>
                                                <h2 className='attractionName'>{show.name}</h2>
                                            </div>
                                            <div className='attractionRow attractionRowBottom'>
                                                <div className='attractionInfoContainer'>
                                                    <h2 className='infoText'>Status</h2>
                                                    <h2 className='infoText'>{show.status}</h2>
                                                </div>
                                            </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='parkTitleAttractionsContainer'>
                        <h2 className='parkCatTitle'>Restaurants</h2>
                        <div className='parkAttractionsListContainer'>
                            {props.restaurants.map((restaurant) => {
                                return (
                                    <div className='attractionContainer' key={restaurant.id}>
                                            <div className='attractionRow'>
                                                <h2 className='attractionName'>{restaurant.name}</h2>
                                            </div>
                                            <div className='attractionRow attractionRowBottom'>
                                                <div className='attractionInfoContainer'>
                                                    <h2 className='infoText'>Status</h2>
                                                    <h2 className='infoText'>{restaurant.status}</h2>
                                                </div>
                                            </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div id='attractionsFeedContainer'>
                    <CoasterTimeFeed {...props} />  
                </div> 
            </div>
        </div>
    )
};

export default ParkAttractions;