import React, { useEffect, useState, useRef } from 'react';
import { getIncidents, getLogoByTeamID, getPlayerPhotoByPlayerID, getMatchInfoByMatchID } from '../../pages/LiveSports/sofaScoreUtils';

// Component Imports
import MatchWrapperStats from './MatchWrapperStats';
import Lineups from './Lineups';
import Standings from './Standings';
import LivePlays from './LivePlays';
import TeamStats from './TeamStats';
import BestPlayers from './BestPlayers';

function MatchStats( {...matchProps} ) {
    const progressBar = document.getElementById("bar");
    progressBar.style.width = 0;

    // Team Details
    const [homeName, setHomeName] = useState();
    const [awayName, setAwayName] = useState();
    const [homeNameCode, setHomeNameCode] = useState();
    const [awayNameCode, setAwayNameCode] = useState();
    const [homeLogoURL, setHomeLogoURL] = useState('');
    const [awayLogoURL, setAwayLogoURL] = useState('');

    // Match Details 
    const [updatedScoreHome, setUpdatedScoreHome] = useState(matchProps.scoreHome);
    const [updatedScoreAway, setUpdatedScoreAway] = useState(matchProps.scoreAway);
    const [homePlayerURL, setHomePlayerURL] = useState('');
    const [awayPlayerURL, setAwayPlayerURL] = useState('');
    const [incidents, setIncidents] = useState([]);
    const [currentTime, setCurrentTime] = useState('0:00');
    const [isLive, setIsLive] = useState(true);

    // Refs for Updating Stats
    const ref = useRef(null);
    const timeRef = useRef(null);

    // Get All Match Details
    const getCurrentMatchDetails = async () => {
        const matchDetailsRes = await getMatchInfoByMatchID(matchProps.currentMatch);

        setHomeName(matchDetailsRes.homeTeam.name);
        setAwayName(matchDetailsRes.awayTeam.name);
        setHomeNameCode(matchDetailsRes.homeTeam.nameCode);
        setAwayNameCode(matchDetailsRes.awayTeam.nameCode);
    }

    // Fetch Play by Play Incidents
    const fetchIncidents = async () => {
        const incidentsRes = await getIncidents(matchProps.currentMatch);

        if (incidentsRes) {
            setIncidents(incidentsRes);
            setUpdatedScoreHome(incidentsRes[0].homeScore);
            setUpdatedScoreAway(incidentsRes[0].awayScore);
        }

        //Fetch Stats
        matchProps.fetchStats();
    };

    // Fetch Team Logos
    const fetchLogos = async () => {
        const homoLogoRes = await getLogoByTeamID(matchProps.homeTeamID);
        const awayLogoRes = await getLogoByTeamID(matchProps.awayTeamID);

        setHomeLogoURL(homoLogoRes);
        setAwayLogoURL(awayLogoRes);
    };

    // Fetch Best Player Photos 
    const fetchBestPlayerPhotos = async () => {
        if (matchProps.bestPlayerStats.home) {
            const homePlayerPhotoRes = await getPlayerPhotoByPlayerID(matchProps.bestPlayerStats.home.player.id);
            setHomePlayerURL(homePlayerPhotoRes);
        }

        if (matchProps.bestPlayerStats.away) {
            const awayPlayerPhotoRes = await getPlayerPhotoByPlayerID(matchProps.bestPlayerStats.away.player.id);
            setAwayPlayerURL(awayPlayerPhotoRes);
        }
    };

    // Fetch Current Game Time
    const getCurrentMatchTime = async () => {
        if (isLive) {
            const timeRes = await getMatchInfoByMatchID(matchProps.currentMatch);

            if (timeRes.status.type === 'finished') {
                setIsLive(false);
            }

            const periodLength = timeRes.time.periodLength;
            const gameTimePlayed = timeRes.time.played;

            let gameClockTime = periodLength - gameTimePlayed;

            if (gameTimePlayed > periodLength && gameTimePlayed < periodLength * 2) {
                gameClockTime = gameClockTime - periodLength;
            }

            if (gameTimePlayed > periodLength * 2 && gameTimePlayed < periodLength * 3) {
                gameClockTime = gameClockTime - (periodLength * 2);
            }

            if (gameTimePlayed > periodLength * 3) {
                gameClockTime = gameClockTime - (periodLength * 3);
            }

            let roundedTime = Math.round((gameClockTime / 60) * 100) / 100;

            if (roundedTime === 0) {
                roundedTime = '0:00'
            }

            setCurrentTime(roundedTime);
        }
    };

    // Call needed functions on load
    useEffect(() => {
        getCurrentMatchDetails();
        fetchIncidents();
        fetchLogos();
        fetchBestPlayerPhotos();
        getCurrentMatchTime();
    }, []);

     // Update Plays and Scores Every 30 Seconds
     useEffect(() => {
        ref.current = setInterval(fetchIncidents, 1 * 30 * 1000); // 1 Minute 1 * 60 * 1000

        return () => {
            if(ref.current){
              clearInterval(ref.current)
            };
          };
    }, []);

    // Refresh Gameclock every minute
    useEffect(() => {
        timeRef.current = setInterval(getCurrentMatchTime, 1 * 60 * 1000); // 1 Minute 1 * 60 * 1000

        return () => {
            if(ref.current){
              clearInterval(timeRef.current)
            };
          };
    }, []);

    // Component Props
    const lineUp = matchProps.lineUp;
    const standings = matchProps.standings;
    const allStats = matchProps.allStats;
    const selectedSport = matchProps.selectedSport;
    const bestPlayerStats = matchProps.bestPlayerStats;
    const homeScore = matchProps.scoreHome;
    const awayScore = matchProps.scoreAway;

    const props = {
        lineUp,
        standings,
        allStats,
        selectedSport,
        bestPlayerStats,
        homeName,
        awayName,
        homeLogoURL,
        awayLogoURL,
        currentTime,
        isLive,
        updatedScoreHome,
        updatedScoreAway,
        incidents,
        homePlayerURL,
        awayPlayerURL,
        homeNameCode,
        awayNameCode,
        homeScore,
        awayScore
    }

    return (
        <div>
            <MatchWrapperStats {...props} />
            <div id='mainStatsWrapper'>
                {matchProps.lineUp && matchProps.lineUp.home !== undefined ? 
                    <Lineups {...props} />
                : <></>}
                {matchProps.standings && matchProps.standings.length > 0 ? 
                    <Standings {...props} />
                : <></>}
                <div id='teamTopContainer'>
                    <LivePlays {...props} />
                    <TeamStats {...props} />
                    {matchProps.bestPlayerStats && matchProps.bestPlayerStats.length !== 0 ? 
                        <BestPlayers {...props} />
                    : <></>}
                </div>
            </div>
        </div>
    )

}

export default MatchStats;