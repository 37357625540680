import React, { useState, useCallback } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './SubChatModal.css';

function SubChatModal({ isOpen, onClose, onCreateSubChat, mode }) {
	const [name, setName] = useState('');

	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);

	const handleChange = (event) => {
		setName(event.target.value);
	};

	const handleCreate = useCallback(
		(event) => {
			if (name.length === 0) {
				toast.warn('Subchat name is empty!');
				return;
			}
			if (name.length > 20) {
				toast.warn('Subname name is too long!');
				return;
			}
			onCreateSubChat(name);
			setName('');
		},
		[onCreateSubChat, name]
	);

	return (
		<Modal
			show={isOpen}
			onHide={handleClose}
			centered
			dialogClassName='createModal'
			closeButton
		>
			<Modal.Header
				style={{
					borderColor:
						mode === 'light' ? 'rgb(235, 235, 235)' : 'rgb(59, 59, 59)',
					color: mode === 'light' ? 'black' : 'white',
					background:
						mode === 'light' ? 'rgb(250, 253, 246)' : 'rgb(18, 18, 18)'
				}}
			>
				<Modal.Title>In-Chat Thread</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{
					background:
						mode === 'light' ? 'rgb(250, 253, 246)' : 'rgb(18, 18, 18)'
				}}
			>
				<Form.Control
					type='text'
					placeholder='Enter Chat Name'
					value={name}
					onChange={handleChange}
					autoFocus
					style={{
						outline: '0',
						border: 'none',
						color: mode === 'light' ? 'black' : 'white',
						background:
							mode === 'light' ? 'rgb(241, 243, 240)' : 'rgb(36, 42, 52)'
					}}
				/>
			</Modal.Body>
			<Modal.Footer
				style={{
					borderColor:
						mode === 'light' ? 'rgb(235, 235, 235)' : 'rgb(59, 59, 59)',
					background:
						mode === 'light' ? 'rgb(250, 253, 246)' : 'rgb(18, 18, 18)'
				}}
			>
				<Button
					variant={mode === 'light' ? 'primary' : 'secondary'}
					onClick={handleClose}
				>
					Cancel
				</Button>
				<Button
					variant={mode === 'light' ? 'primary' : 'secondary'}
					onClick={handleCreate}
				>
					Create
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default SubChatModal;
