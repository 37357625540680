import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Api from "../../config/axios";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import "./GroupChatModal.css";
import { Avatar } from "./styles";
import { getAvatarPath } from "../../utils/helpers";

function SingleChatModal({ user, show, handleClose }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [recipient, setRecipient] = useState(null);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (recipient) {
      handleSubmit();
    }
  }, [recipient]);

  const debouncedSave = useCallback(
    debounce((nextValue) => setPhoneNumber(nextValue), 1000),
    []
  );

  const handleSearchChange = (val) => {
    setPhoneNumber(val);
    debouncedSave(val);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    // Check if the phone number is already in the current user's contacts
    const isContactExists = user.contacts.some(contact => contact.phone === phoneNumber);
    if (isContactExists) {
      return toast.info("You've already chatted with this user.");
    }
  
    // Check if the phone number exists in the database
    try {
      const res = await Api.get(`/user/search?q=${phoneNumber}&by=search-phone`);
      if (res.data.length === 0) {
        return toast.error("Phone number does not have a Gibber account.");
      }
      const foundUser = res.data[0];
      // Before setting the recipient, make sure it's not already a contact
      await setRecipient(foundUser);
    } catch (error) {
      console.error("Error searching for user:", error);
      toast.error("An error occurred while searching for the user.");
    }
  };
  
  
  const handleSubmit = async () => {
    try {
      const res = await Api.post(`/chat/conversation/${recipient._id}`);
      const chat = res.data;
      handleClose();
      // Only display success toast if chat creation was successful
      if (chat && chat._id) {
        toast.success("Chat created successfully!");
        setPhoneNumber(""); 
      } else {
        toast.error("An error occurred while creating the chat.");
      }
    } catch (error) {
      console.error("Error creating chat:", error);
      toast.error("An error occurred while creating the chat.");
    }
  };


  const handleModalClose = () => {
    setRecipient(null);
    handleClose();
    setPhoneNumber(""); 
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Chat</Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}> 
          <Form.Group controlId="formRecipient">
            <Form.Label>Phone Number:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter recipient's phone number (10 digits)"
              value={phoneNumber}
              onChange={(e) => handleSearchChange(e.target.value)}
            />

            {/* Not Displaying User  */}
            {/* {recipient && (
              <div>
                <Avatar
                  src={getAvatarPath(recipient.avatar)}
                  alt={recipient.name}
                  className="avatar"
                />
                <span>{recipient.name}</span>
              </div>
            )} */}
            
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={phoneNumber.length !== 10} 
            >
              Start Chat
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default SingleChatModal;