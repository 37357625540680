export const languages = [
    {
        language: 'ar',
        name: 'عربي',
    },
    {
        language: 'bn',
        name: 'Bবাংলা',
    },
    {
        language: 'zh-CN',
        name: '简体中文',
    },
    {
        language: 'zh-TW',
        name: '中國傳統的',
    },
    {
        language: 'nl',
        name: 'Nederlands',
    },
    {
        language: 'en',
        name: 'English',
    },
    {
        language: 'fr',
        name: 'Français',
    },
    {
        language: 'de',
        name: 'Deutsch',
    },
    {
        language: 'gu',
        name: 'ગુજરાતી',
    },
    {
        language: 'hi',
        name: 'हिन्दी',
    },
    {
        language: 'id',
        name: 'Bahasa Indonesia',
    },
    {
        language: 'ja',
        name: '日本語',
    },
    {
        language: 'jw',
        name: 'Jꦧꦱꦗꦮ',
    },
    {
        language: 'ko',
        name: '한국인',
    },
    {
        language: 'mr',
        name: 'मराठी',
    },
    {
        language: 'pt',
        name: 'Português',
    },
    {
        language: 'pa',
        name: 'ਪੰਜਾਬੀ',
    },
    {
        language: 'es',
        name: 'Español',
    },
    {
        language: 'te',
        name: 'తెలుగు',
    },
    {
        language: 'tr',
        name: 'Türkçe',
    },
    {
        language: 'ur',
        name: 'اردو',
    },
]