import React, { useEffect, useState } from 'react';
import './index.css';

import { getParks, getParkRidesById, getLiveDataByRideId, getAllLiveInfoByParkId, getCoasterTimesFeed } from './themeParkUtils';
import ParkAttractions from '../../components/ThemePark/ParkAttractions';
import ParksList from '../../components/ThemePark/ParksList';

import LiveSportsLogo from '../../components/LiveSports/SportsAssets/LiveSportsLogo.jpg';

function ThemeParkWaitTimes() {
    const [parks, setParks] = useState([]);
    const [currentParkId, setCurrentParkId] = useState(''); 
    const [showingPark, setShowingPark] = useState(false); 
    const [showingRide, setShowingRide] = useState(false); 

    // State for All Attractions and more for a given park
    const [parkData, setParkData] = useState([]); 
    const [attractions, setAttractions] = useState([]); 
    const [shows, setShows] = useState([]); 
    const [restaurants, setRestaurants] = useState([]); 

    // State for each ride
    const [currentRideId, setCurrentRideId] = useState(''); 
    const [currentRideData, setCurrentRideData] = useState([]); 
    const [currentRideLiveData, setCurrentRideLiveData] = useState([]); 

    // Coaster Times Feed
    const [blogPosts, setBlogPosts] = useState([]);  

    // Fetch a list of Parks and Fetch Coaster Times Feed On Load
    useEffect(() => {
        const getFeed = async () => {
            const feed = await getCoasterTimesFeed();
            setBlogPosts(feed.slice(0, 3)); // First 3/10 Posts
        };

        const getThemeParks = async () => {
            const themeParks = await getParks();
            setParks(themeParks.sort((a, b) => a.name.localeCompare(b.name)));
        };

        getFeed();
        getThemeParks();
    }, []);

    // Fetch specific park data on click 
    useEffect(() => {
        const getParkRides = async () => {
            const parkRides = await getAllLiveInfoByParkId(currentParkId);
            setParkData(parkRides.data);

            const parkAttractions = parkRides.liveData.filter((parkRide) => {
                return parkRide.entityType === 'ATTRACTION';
            });

            const parkShows = parkRides.liveData.filter((parkRide) => {
                return parkRide.entityType === 'SHOW';
            });

            const parkRestaurants = parkRides.liveData.filter((parkRide) => {
                return parkRide.entityType === 'RESTAURANT';
            });

            setAttractions(parkAttractions.sort((a, b) => a.name.localeCompare(b.name)));
            setShows(parkShows.sort((a, b) => a.name.localeCompare(b.name)));
            setRestaurants(parkRestaurants.sort((a, b) => a.name.localeCompare(b.name)));
        };

        if (currentParkId) {
            getParkRides();
        };
    }, [currentParkId]);

    // Fetch wait times, status, and other live data
    useEffect(() => {
        const fetchLiveData = async () => {
            const liveData = await getLiveDataByRideId(currentRideId);
            setCurrentRideData(liveData.data);
            setCurrentRideLiveData(liveData.liveData);
        };

        if (currentRideId) {
            fetchLiveData();
        };
    }, [currentRideId]);

    // Reset state on title click
    function resetState() {
        setShowingPark(false);
        setShowingRide(false);
    };

    // Go back to park rides list
    function handleParkClickWaitPage() {
        setShowingPark(true);
        setShowingRide(false);
    };

    // Props
    const props = {
        parks,
        parkData,
        attractions,
        setCurrentRideId,
        setShowingRide,
        setCurrentParkId,
        setShowingPark,
        shows,
        restaurants,
        blogPosts
    };

    //<h2 onClick={resetState} id='themeTitle'>Theme Park Wait Times</h2>

    return (
        <>
        <div id='themeParksContainer'>
            <div id='parksTitleLogoWrapper'>
                <img id='liveSportsLogoNavbar' src={LiveSportsLogo} alt='live-sports-logo'/>
                <h2 onClick={resetState} id='themeParksTitle'>Theme Park Wait Times</h2>
            </div>
            {!showingPark && !showingRide ?
                <ParksList {...props} />
            : <></>}
            {showingPark ? 
                <ParkAttractions {...props}/>
            : <></>}
        </div>
        </>
    )
}

export default ThemeParkWaitTimes;