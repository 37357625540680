import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Api from "../../config/axios";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import "./GroupChatModal.css";
import { Avatar } from "./styles";
import { getAvatarPath } from "../../utils/helpers";

function GroupChatModal({ user, show, handleClose }) {
  const [groupChatName, setGroupChatName] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchDb, setSearchDb] = useState("");
  const [selectedOption, setSelectedOption] = useState("search-name");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState([]);

  const ChangeGroupName = (event) => {
    setGroupChatName(event.target.value);
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => setSearchDb(nextValue), 1000),
    []
  );

  const handleSearchChange = (val) => {
    setSearch(val);
    debouncedSave(val);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleUserSelection = (userId) => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, userId]);
      const userDetails = allUsers.find((user) => user._id === userId);
      setSelectedUserDetails((prevSelectedUserDetails) => [
        ...prevSelectedUserDetails,
        userDetails,
      ]);
    }
  };

  const handleUserDeselection = (userId) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.filter((id) => id !== userId)
    );
    setSelectedUserDetails((prevSelectedUserDetails) =>
      prevSelectedUserDetails.filter((user) => user._id !== userId)
    );
  };

  const handleSubmit = async () => {
    if (!groupChatName) {
      return toast.warn("Input Group Chat Name");
    } else if (selectedUsers.length === 0) {
      return toast.warn("Invalid Number of Selected Users!");
    }
    try {
      const res = await Api.post("/chat/group-conversation/", {
        participants: selectedUsers,
        name: groupChatName,
      });
      const groupChat = res.data;
      handleClose();
      setSelectedUsers([]);
      setGroupChatName("");
      toast.success("Group chat created successfully!"); // Success toast
    } catch (error) {
      console.warn(error);
      toast.error("An error occurred while creating the group chat."); // Error toast
    }
  };

  const handleModalClose = () => {
    setGroupChatName("");
    setSelectedUsers([]);

    handleClose();
  };

  const fetchAllUsers = useCallback(async () => {
    if (searchDb.length > 2) {
      const res = await Api.get(
        "/user/search?q=" + searchDb + "&by=" + selectedOption
      );
      setAllUsers(res.data);
    }
  }, [searchDb, selectedOption]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formGroupName">
            <Form.Label>Group Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Group Name"
              value={groupChatName}
              onChange={ChangeGroupName}
              className="mb-3"
            />
          </Form.Group>

          <Form.Group controlId="formUsers">
            <Form.Label>Select Users to Add:</Form.Label>
            <Form.Control
              type="text"
              value={search}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            {allUsers.length > 0 && (
              <div className="search-results">
                {allUsers.map((user) => (
                  <div
                    key={user._id}
                    value={user._id}
                    className={`user-option ${
                      selectedUsers.includes(user._id) ? "selected-user" : ""
                    }`}
                    onClick={() => handleUserSelection(user._id)}
                  >
                    <Avatar
                      src={getAvatarPath(user.avatar)}
                      alt={user.name}
                      className="avatar"
                    />{" "}
                    {user.name}
                  </div>
                ))}
              </div>
            )}

            <div style={{ fontSize: "14px", paddingTop: "10px" }}>
              {" "}
              Selected Users:{" "}
              <div className="selectedUsers">
                {selectedUserDetails.map((user) => {
                  return (
                    <span key={user._id} className="selectedUserOption">
                      <Avatar
                        className="avatar"
                        src={getAvatarPath(user.avatar)}
                        alt={user.name}
                      />{" "}
                      {user.name}{" "}
                      <div className="buttonContainer">
                        <button
                          className="minusButton"
                          onClick={() => handleUserDeselection(user._id)}
                        >
                          x
                        </button>
                      </div>
                    </span>
                  );
                })}
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GroupChatModal;
