import React, { useState } from 'react';

function LivePlays( {...props} ) {
    const [incidentsShown, setIncidentsShown] = useState(5);

    function loadMorePlays() {
        setIncidentsShown(incidentsShown + 5);
        let playsContainer = document.getElementById("livePlaysEventsContainer");
        playsContainer.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }

    function PlaysTableRow() {
        return (
            <>
            <h2 className='statsContainerTitle liveTitle'>Play by Play</h2>
            <div className='playsTableRow'>
                <div className='playsTableGroup'>
                    <h2>Time</h2>
                    <h2>Play</h2>
                </div>
                <div className='playsTableGroup'>
                    <h2>{props.homeNameCode}</h2>
                    <h2>{props.awayNameCode}</h2>
                </div>
            </div>   
            </>
        )
    }

    // Basketball Live Plays
    if (props.selectedSport === 'Basketball') {
        return (
            <div id='livePlays'>
                <PlaysTableRow/>
                <div id='livePlaysEventsContainer'>
                {props.incidents.slice(0, incidentsShown).map((incident) => {
                    const timeSeconds = incident.reversedPeriodTimeSeconds;
                    const minutes = Math.floor(timeSeconds / 60);
                    let seconds = timeSeconds - minutes * 60;
    
                    if (seconds && seconds.toString().length === 1) {
                        seconds = seconds.toString() + '0';
                    }
    
                    return (
                        <>
                            {incident && incident.incidentType === 'period' && incident.text === 'FT' ? 
                            <div className='playMessageContainer'>
                                <h2 className='livePlaysMessage'>Game end: {props.homeName} {incident.homeScore}, {props.awayName} {incident.awayScore}.</h2>
                            </div>
                            : <></>}
                            {incident && incident.incidentType === 'period' && incident.text !== 'FT' ? 
                            <div className='playMessageContainer'>
                                <h2 className='livePlaysMessage'>{incident.text}: {props.homeName} {incident.homeScore}, {props.awayName} {incident.awayScore}.</h2>
                            </div>
                            : <></>}
                            {incident && incident.incidentType === 'goal' && incident.incidentClass === 'onePoint' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>{incident.player.name} makes a free throw.</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{incident.homeScore}</h2>
                                    <h2 className='livePlaysMessage'>{incident.awayScore}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident && incident.incidentType === 'goal' && incident.incidentClass === 'twoPoints' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>{incident.player.name} scores for 2.</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{incident.homeScore}</h2>
                                    <h2 className='livePlaysMessage'>{incident.awayScore}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident && incident.incidentType === 'goal' && incident.incidentClass === 'threePoints' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>{incident.player.name} sinks a 3.</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{incident.homeScore}</h2>
                                    <h2 className='livePlaysMessage'>{incident.awayScore}</h2>
                                </div>
                            </div>
                            : <></>}
                            <hr/>
                        </>
                    )
                })}
                </div>
                <button id='loadMorePlaysButton' onClick={loadMorePlays}>Load More</button>
            </div>
        )
    }

    // Soccer Live Plays
    if (props.selectedSport === 'Soccer') {
        return (
            <div id='livePlays'>
                <PlaysTableRow/>
                <div id='livePlaysEventsContainer'>
                {props.incidents.slice(0, incidentsShown).map((incident) => {
                    const timeSeconds = incident.time;
                    const minutes = Math.floor(timeSeconds / 60);
                    let seconds = timeSeconds - minutes * 60;

                    if (seconds && seconds.toString().length === 1) {
                        seconds = seconds.toString() + '0';
                    }

                    return (
                        <>
                            {incident.incidentType === 'goal' && incident.incidentClass === 'regular' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>Goal!! {incident.player.name} scores for {incident.isHome ? props.homeTeam : props.awayTeam}!</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{incident.homeScore}</h2>
                                    <h2 className='livePlaysMessage'>{incident.awayScore}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'goal' && incident.incidentClass === 'ownGoal' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>Goal!! Own goal as {incident.player.name} accidentally scores for {incident.isHome ? props.awayTeam : props.homeTeam}.</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{incident.homeScore}</h2>
                                    <h2 className='livePlaysMessage'>{incident.awayScore}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'substitution' && incident.incidentClass !== 'injury' ?
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>{incident.playerIn.name} enters in for {incident.playerOut.name}.</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreHome}</h2>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreAway}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'substitution' && incident.incidentClass === 'injury'  ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>{incident.playerIn.name} enters in for injured {incident.playerOut.name}.</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreHome}</h2>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreAway}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'injuryTime' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <h2 className='livePlaysMessage'>Time stopped due to injury.</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreHome}</h2>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreAway}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'varDecision' && incident.incidentClass === 'goalAwarded' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>VAR Decision: Goal awarded to {incident.isHome ? props.homeTeam : props.awayTeam}!! Goal from {incident.player.name}!!</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{incident.homeScore}</h2>
                                    <h2 className='livePlaysMessage'>{incident.awayScore}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'card' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>{incident.playerName} recieves a {incident.incidentClass} card. Reason: {incident.reason}.</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreHome}</h2>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreAway}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'period' && incident.text === 'FT' ? 
                            <>
                                <h2 className='livePlaysMessage'>Game end: {props.homeTeam} {incident.homeScore}, {props.awayTeam} {incident.awayScore}.</h2>
                            </>
                            : <></>}
                            {incident.incidentType === 'period' && incident.text === 'HT' ? 
                            <>
                                <h2 className='livePlaysMessage'>Halftime: {props.homeTeam} {incident.homeScore}, {props.awayTeam} {incident.awayScore}.</h2>
                            </>
                            : <></>}
                            <hr/>
                        </>
                    )
                })}
                </div>
                <button id='loadMorePlaysButton' onClick={() => setIncidentsShown(incidentsShown + 5)}>Load More</button>
            </div>
        )
    }

    // Baseball Live Plays
    if (props.selectedSport === 'Baseball') {
        return (
            <div id='livePlays'>
                <PlaysTableRow/>
            </div>
        )
    }

    // Football Live Plays
    if (props.selectedSport === 'Football') {
        return (
            <div id='livePlays'>
                <PlaysTableRow/>
                <div id='livePlaysEventsContainer'>
                {props.incidents.slice(0, incidentsShown).map((incident) => {
                    const reducedTeamNameHome = props.homeName.split(' ').reverse()[0];
                    const reducedTeamNameAway = props.awayName.split(' ').reverse()[0];

                    const timeSeconds = incident.time;
                    const minutes = Math.floor(timeSeconds / 60);
                    let seconds = timeSeconds - minutes * 60;

                    if (seconds && seconds.toString().length === 1) {
                        seconds = seconds.toString() + '0';
                    }

                    return (
                        <>
                            {incident.incidentType === 'goal' && incident.incidentClass === 'touchdown' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>Touchdown!! {incident.player.name} scores for the {incident.isHome ? reducedTeamNameHome : reducedTeamNameAway}!</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreHome}</h2>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreAway}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'goal' && incident.incidentClass === 'fieldGoal' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>{incident.player.name} scores a field goal for the {incident.isHome ? reducedTeamNameHome : reducedTeamNameAway}!</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreHome}</h2>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreAway}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'goal' && incident.incidentClass === 'extraPoint' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>{incident.player.name} scores an extra point for the {incident.isHome ? reducedTeamNameHome : reducedTeamNameAway}.</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreHome}</h2>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreAway}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'goal' && incident.incidentClass === 'twoPointConversion' ? 
                            <div className='playMessageContainer'>
                                <div className='playsMessageGroup leftGroup'>
                                    <h2 className='livePlaysMessage'>{minutes}:{seconds}</h2>
                                    <img className='teamLogoSmall' src={incident.isHome ? `data:image/png;base64, ${props.homeLogoURL}` : `data:image/png;base64, ${props.awayLogoURL}`} alt='logo'/>
                                    <h2 className='livePlaysMessage'>{incident.player.name} makes the conversion for 2 extra points for the {incident.isHome ? reducedTeamNameHome : reducedTeamNameAway}!</h2>
                                </div>
                                <div className='playsMessageGroup rightGroup'>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreHome}</h2>
                                    <h2 className='livePlaysMessage'>{props.updatedScoreAway}</h2>
                                </div>
                            </div>
                            : <></>}
                            {incident.incidentType === 'period' && incident.text !== 'Q4' ? 
                            <div className='playMessageContainer'>
                                <h2 className='livePlaysMessage'>End of {incident.text}: {props.homeTeam} {incident.homeScore}, {props.awayTeam} {incident.awayScore}.</h2>
                            </div>
                            : <></>}
                            {incident.incidentType === 'period' && incident.text === 'Q4' ? 
                            <>
                                <h2 className='livePlaysMessage'>{incident.text} end of game: {props.homeTeam} {incident.homeScore}, {props.awayTeam} {incident.awayScore}.</h2>
                            </>
                            : <></>}
                            <hr/>
                        </>
                    )
                })}
                </div>
                <button id='loadMorePlaysButton' onClick={() => setIncidentsShown(incidentsShown + 5)}>Load More</button>
            </div>
        )
    }

    // Cricket Live Plays
    if (props.selectedSport === 'Cricket') {
        return (
            <div id='livePlays'>
                <PlaysTableRow/>
                <div id='livePlaysEventsContainer'>
                {props.incidents.slice(0, incidentsShown).map((incident) => {
                    return (
                        <>
                            {incident.incidentType === 'ball'? 
                            <>
                                <h2 className='livePlaysMessageCricket'>{incident.commentary}</h2>
                            </>
                            : <></>}
                            <hr/>
                        </>
                    )
                })}
                </div>
                <button id='loadMorePlaysButton' onClick={() => setIncidentsShown(incidentsShown + 5)}>Load More</button>
            </div>
        )
    }

}

export default LivePlays;