import React, { useEffect } from "react";

function SearchedLeagues( {...searchedLeaguesProps} ) {
    return (
        <>
            {searchedLeaguesProps.searchedLeagues.map((league) => {
                function handleLeagueClick() {
                    searchedLeaguesProps.setLeague(league.name);
                    searchedLeaguesProps.setCategory(league.category.id);
                    searchedLeaguesProps.setSelectedSport(searchedLeaguesProps.sportsMap[league.category.sport.id])
                    searchedLeaguesProps.setBannerClick(0);
                    searchedLeaguesProps.setShowingSearchedGames(true);
                };

                return (
                <div className='matchWrapper'>
                    <div className='teamNamesWrapper'>
                        <div className='nameScoreWrapper'>
                            <h2 className='teamName'>{league.name}</h2>
                        </div>
                    </div>
                    <button className='matchButton' onClick={handleLeagueClick}><div className='triangle'></div></button>
                </div>
                )
            })}
            </>
    )
}

export default SearchedLeagues;