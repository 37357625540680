import React from 'react';

function TeamStats( {...props} ) {

    // Basketball Team Stats
    if (props.selectedSport === 'Basketball') {
        // Basketball Stats Values
        const homeFreeThrows = props.allStats[0].statisticsItems[0].homeValue;
        const awayFreeThrows = props.allStats[0].statisticsItems[0].awayValue;
        const totalFreeThrows = homeFreeThrows + awayFreeThrows;

        const homeTwoPointers = props.allStats[0].statisticsItems[1].homeValue;
        const awayTwoPointers = props.allStats[0].statisticsItems[1].awayValue;
        const totalTwoPointers = homeTwoPointers + awayTwoPointers;

        const homeThreePointers = props.allStats[0].statisticsItems[2].homeValue;
        const awayThreePointers = props.allStats[0].statisticsItems[2].awayValue;
        const totalThreePointers = homeThreePointers + awayThreePointers;

        const homeAssists = props.allStats[1].statisticsItems[3].homeValue;
        const awayAssists = props.allStats[1].statisticsItems[3].awayValue;
        const totalAssists = homeAssists + awayAssists;

        const homeTurnovers = props.allStats[1].statisticsItems[4].homeValue;
        const awayTurnovers = props.allStats[1].statisticsItems[4].awayValue;
        const totalTurnovers = homeTurnovers + awayTurnovers;

        const homeSteals = props.allStats[1].statisticsItems[5].homeValue;
        const awaySteals = props.allStats[1].statisticsItems[5].awayValue;
        const totalSteals = homeSteals + awaySteals;

        return (
            <div id='teamStats'>
                <h2 className='statsContainerTitle'>Team Stats</h2>
                <div id='teamsRowStats'>
                    <h2 className='teamTitleStats'>{props.homeName}</h2>
                    <h2 className='teamTitleStats'>{props.awayName}</h2>
                </div>
                <div className='statsRow'>
                    <h2 className='statsText'>{homeFreeThrows}</h2>
                    <div className='statsBar'>
                        <div className='statsBarFill' style={{width: `${(homeFreeThrows / totalFreeThrows) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>Free Throws</h2>
                    <div className='statsBarRight'>
                        <div className='statsBarFillRight' style={{width: `${(awayFreeThrows / totalFreeThrows) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>{awayFreeThrows}</h2>
                </div>
                <div className='statsRow'>
                    <h2 className='statsText'>{homeTwoPointers}</h2>
                    <div className='statsBar'>
                        <div className='statsBarFill' style={{width: `${(homeTwoPointers / totalTwoPointers) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>2 Pointers</h2>
                    <div className='statsBarRight'>
                        <div className='statsBarFillRight' style={{width: `${(awayTwoPointers / totalTwoPointers) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>{awayTwoPointers}</h2>
                </div>
                <div className='statsRow'>
                    <h2 className='statsText'>{homeThreePointers}</h2>
                    <div className='statsBar'>
                        <div className='statsBarFill' style={{width: `${(homeThreePointers / totalThreePointers) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>3 Pointers</h2>
                    <div className='statsBarRight'>
                        <div className='statsBarFillRight' style={{width: `${(awayThreePointers / totalThreePointers) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>{awayThreePointers}</h2>
                </div>
                <hr></hr>
                <div className='statsRow'>
                    <h2 className='statsText'>{homeAssists}</h2>
                    <div className='statsBar'>
                        <div className='statsBarFill' style={{width: `${(homeAssists / totalAssists) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>Assists</h2>
                    <div className='statsBarRight'>
                        <div className='statsBarFillRight' style={{width: `${(awayAssists / totalAssists) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>{awayAssists}</h2>
                </div>
                <div className='statsRow'>
                    <h2 className='statsText'>{homeTurnovers}</h2>
                    <div className='statsBar'>
                        <div className='statsBarFill' style={{width: `${(homeTurnovers / totalTurnovers) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>Turnovers</h2>
                    <div className='statsBarRight'>
                        <div className='statsBarFillRight' style={{width: `${(awayTurnovers / totalTurnovers) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>{awayTurnovers}</h2>
                </div>
                <div className='statsRow'>
                    <h2 className='statsText'>{homeSteals}</h2>
                    <div className='statsBar'>
                        <div className='statsBarFill' style={{width: `${(homeSteals / totalSteals) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>Steals</h2>
                    <div className='statsBarRight'>
                        <div className='statsBarFillRight' style={{width: `${(awaySteals / totalSteals) * 100}%`}}/>
                    </div>
                    <h2 className='statsText'>{awaySteals}</h2>
                </div>
            </div>
        )
    }

    // Soccer Team Stats
    if (props.selectedSport === 'Soccer') {
        // Soccer Stats Values
        const homePossession = props.allStats[1].statisticsItems[0].home;
        const awayPossession = props.allStats[1].statisticsItems[0].away;

        const homeShots = props.allStats[2].statisticsItems[0].home;
        const awayShots = props.allStats[2].statisticsItems[0].away;
        const totalShots = Number(homeShots) + Number(awayShots);

        const homeShotsTarget = props.allStats[2].statisticsItems[1].home;
        const awayShotsTarget = props.allStats[2].statisticsItems[1].away;
        const totalShotsTarget = Number(homeShotsTarget) + Number(awayShotsTarget);

        const homePasses = props.allStats[5].statisticsItems[0].home;
        const awayPasses = props.allStats[5].statisticsItems[0].away;
        const totalPasses = Number(homePasses) + Number(awayPasses);

        const homePassesAccurate = props.allStats[5].statisticsItems[1].homeValue;
        const awayPassesAccurate = props.allStats[5].statisticsItems[1].awayValue;
        const totalPassesAccurate = homePassesAccurate + awayPassesAccurate;

        const homeInterceptions = props.allStats[7].statisticsItems[1].homeValue;
        const awayInterceptions = props.allStats[7].statisticsItems[1].awayValue;
        const totalInterceptions = homeInterceptions + awayInterceptions;

        const homeYellowCards = props.allStats[3].statisticsItems[3].homeValue;
        const awayYellowCards = props.allStats[3].statisticsItems[3].awayValue;
        const totalYellowCards = homeYellowCards + awayYellowCards;

        return (
            <div id='teamStats'>
                <h2 className='statsContainerTitle'>Team Stats</h2>
                <div id='teamsRowStats'>
                    <h2 className='teamTitleStats'>{props.homeName}</h2>
                    <h2 className='teamTitleStats'>{props.awayName}</h2>
                </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homePossession}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${homePossession.slice(0, 2)}%`}}/>
                        </div>
                        <h2 className='statsText'>Ball Possession</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${awayPossession.slice(0, 2)}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayPossession}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeShots}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeShots / totalShots) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Total Shots</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayShots / totalShots) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayShots}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeShotsTarget}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeShotsTarget / totalShotsTarget) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Shots on Target</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayShotsTarget / totalShotsTarget) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayShotsTarget}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homePasses}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homePasses / totalPasses) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Passes</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayPasses / totalPasses) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayPasses}</h2>
                    </div>
                    <hr></hr>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homePassesAccurate}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homePassesAccurate / totalPassesAccurate) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Accurate Passes</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayPassesAccurate / totalPassesAccurate) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayPassesAccurate}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeInterceptions}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeInterceptions / totalInterceptions) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Interceptions</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayInterceptions / totalInterceptions) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayInterceptions}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeYellowCards}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeYellowCards / totalYellowCards) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Yellow Cards</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayYellowCards / totalYellowCards) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayYellowCards}</h2>
                    </div>
            </div>
        )
    }

    // Baseball Team Stats
    if (props.selectedSport === 'Baseball') {
        // Baeball Stats Values
        const homeRunsTotal = props.allStats[0].statisticsItems[0].homeValue;
        const awayRunsTotal = props.allStats[0].statisticsItems[0].awayValue;
        const totalRunsTotal = homeRunsTotal + awayRunsTotal;

        const homeDoubles = props.allStats[0].statisticsItems[1].homeValue;
        const awayDoubles = props.allStats[0].statisticsItems[1].awayValue;
        const totalDoubles = homeDoubles + awayDoubles;

        const homeTriples = props.allStats[0].statisticsItems[2].homeValue;
        const awayTriples = props.allStats[0].statisticsItems[2].awayValue;
        const totalTriples = homeTriples + awayTriples;

        const homeRunsHome = props.allStats[0].statisticsItems[3].homeValue;
        const homeRunsAway = props.allStats[0].statisticsItems[3].awayValue;
        const totalHomeRuns = homeRunsHome + homeRunsAway;

        const homeStrikeOuts = props.allStats[0].statisticsItems[4].homeValue;
        const awayStrikeOuts = props.allStats[0].statisticsItems[4].awayValue;
        const totalStrikeOuts = homeStrikeOuts + awayStrikeOuts;

        const homePutOuts = props.allStats[2].statisticsItems[1].homeValue;
        const awayPutOuts = props.allStats[2].statisticsItems[1].awayValue;
        const totalPutOuts = homePutOuts + awayPutOuts;

        const homeAssists = props.allStats[2].statisticsItems[0].homeValue;
        const awayAssists = props.allStats[2].statisticsItems[0].awayValue;
        const totalAssists = homeAssists + awayAssists;

        return (
            <div id='teamStats'>
                <h2 className='statsContainerTitle'>Team Stats</h2>
                <div id='teamsRowStats'>
                    <h2 className='teamTitleStats'>{props.homeName}</h2>
                    <h2 className='teamTitleStats'>{props.awayName}</h2>
                </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeRunsTotal}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeRunsTotal / totalRunsTotal) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Runs</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayRunsTotal / totalRunsTotal) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayRunsTotal}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeDoubles}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeDoubles / totalDoubles) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Doubles</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(homeDoubles / totalDoubles) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayDoubles}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeTriples}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeTriples / totalTriples) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Triples</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayTriples / totalTriples) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayTriples}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeRunsHome}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeRunsHome / totalHomeRuns) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Home Runs</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(homeRunsHome / totalHomeRuns) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{homeRunsAway}</h2>
                    </div>
                    <hr></hr>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeStrikeOuts}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeStrikeOuts / totalStrikeOuts) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Strike Outs</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayStrikeOuts / totalStrikeOuts) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayStrikeOuts}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homePutOuts}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homePutOuts / totalPutOuts) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Put Outs</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayPutOuts / totalPutOuts) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayPutOuts}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeAssists}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeAssists / totalAssists) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Assists</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayAssists / totalAssists) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayAssists}</h2>
                    </div>
            </div>
        )
    }

    // Football Team Stats
    if (props.selectedSport === 'Football') {
        // Football Stats Values        
        const homeTouchdowns = props.allStats[0].statisticsItems[0].home;
        const awayTouchdowns = props.allStats[0].statisticsItems[0].away;
        const totalTouchdowns = Number(homeTouchdowns) + Number(awayTouchdowns);

        const homeFieldGoals = props.allStats[0].statisticsItems[1].home;
        const awayFieldGoals = props.allStats[0].statisticsItems[1].away;
        const totalFieldGoalds = homeFieldGoals + awayFieldGoals;

        const homeYards = props.allStats[0].statisticsItems[2].home;
        const awayYards = props.allStats[0].statisticsItems[2].away;
        const totalYards = Number(homeYards) + Number(awayYards);

        const homeFirstdowns = props.allStats[1].statisticsItems[0].home;
        const awayFirstdowns = props.allStats[1].statisticsItems[0].away;
        const totalFirstdowns = Number(homeFirstdowns) + Number(awayFirstdowns);

        const homeTurnovers = props.allStats[0].statisticsItems[3].home;
        const awayTurnovers = props.allStats[0].statisticsItems[3].away;
        const totalTurnovers = Number(homeTurnovers) + Number(awayTurnovers);

        const homePenalties = props.allStats[4].statisticsItems[0].home;
        const awayPenalties = props.allStats[4].statisticsItems[0].away;
        const totalPenalties = Number(homePenalties) + Number(awayPenalties);

        return (
            <div id='teamStats'>
                <h2 className='statsContainerTitle'>Team Stats</h2>
                <div id='teamsRowStats'>
                    <h2 className='teamTitleStats'>{props.homeName}</h2>
                    <h2 className='teamTitleStats'>{props.awayName}</h2>
                </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeTouchdowns}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeTouchdowns / totalTouchdowns) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Touchdowns</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayTouchdowns / totalTouchdowns) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayTouchdowns}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeFieldGoals}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeFieldGoals / totalFieldGoalds) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Field Goals</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayFieldGoals / totalFieldGoalds) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayFieldGoals}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeYards}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeYards / totalYards) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Total Yards</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayYards / totalYards) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayYards}</h2>
                    </div>
                    <hr></hr>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeFirstdowns}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeFirstdowns / totalFirstdowns) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>First Downs</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayFirstdowns / totalFirstdowns) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayFirstdowns}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeTurnovers}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeTurnovers / totalTurnovers) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Turnovers</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayTurnovers / totalTurnovers) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayTurnovers}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homePenalties}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homePenalties / totalPenalties) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Penalties</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayPenalties / totalPenalties) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayPenalties}</h2>
                    </div>
            </div>
        )
    }

    // Cricket Team Stats
    if (props.selectedSport === 'Cricket') {
        // Cricket Stats Values
        const homeScoreFull = props.allStats[1].score;
        const homeScoreFull2 = props.allStats[1].wickets;
        const awayScoreFull = props.allStats[0].score;
        const awayScoreFull2 = props.allStats[0].wickets; 
        const scoreTotal = homeScoreFull + awayScoreFull;

        const homeOvers = props.allStats[1].overs;
        const awayOvers = props.allStats[0].overs;
        const totalOvers = homeOvers + awayOvers;

        const homeByes = props.allStats[1].bye;
        const awayByes = props.allStats[0].bye;
        const totalByes = homeByes + awayByes;

        const homeLegByes = props.allStats[1].legBye;
        const awayLegByes = props.allStats[0].legBye;
        const totalLegByes = homeLegByes + awayLegByes;

        const homePenalties = props.allStats[1].penalty;
        const awayPenalties = props.allStats[0].penalty;
        const totalPenalties = homePenalties + awayPenalties;

        const homeNoBalls = props.allStats[1].noBall;
        const awayNoBalls = props.allStats[0].noBall;
        const totalNoBalls = homeNoBalls + awayNoBalls;

        const homeExtras = props.allStats[1].extra;
        const awayExtras = props.allStats[0].extra;
        const totalExtras = homeExtras + awayExtras;

        return (
            <div id='teamStats'>
                <h2 className='statsContainerTitle'>Team Stats</h2>
                <div id='teamsRowStats'>
                    <h2 className='teamTitleStats'>{props.homeName}</h2>
                    <h2 className='teamTitleStats'>{props.awayName}</h2>
                </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeScoreFull}/{homeScoreFull2}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeScoreFull / scoreTotal) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Score</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayScoreFull / scoreTotal) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayScoreFull}/{awayScoreFull2}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeOvers}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeOvers / totalOvers) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Overs</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayOvers / totalOvers) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayOvers}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeByes}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeByes / totalByes) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Byes</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayByes / totalByes) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayByes}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeLegByes}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeLegByes / totalLegByes) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Leg Byes</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayLegByes / totalLegByes) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayLegByes}</h2>
                    </div>
                    <hr></hr>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homePenalties}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homePenalties / totalPenalties) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Penalties</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayPenalties / totalPenalties) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayPenalties}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeNoBalls}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeNoBalls / totalNoBalls) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>No Balls</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayNoBalls / totalNoBalls) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayNoBalls}</h2>
                    </div>
                    <div className='statsRow'>
                        <h2 className='statsText'>{homeExtras}</h2>
                        <div className='statsBar'>
                            <div className='statsBarFill' style={{width: `${(homeExtras / totalExtras) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>Extras</h2>
                        <div className='statsBarRight'>
                            <div className='statsBarFillRight' style={{width: `${(awayExtras / totalExtras) * 100}%`}}/>
                        </div>
                        <h2 className='statsText'>{awayExtras}</h2>
                    </div>
            </div>
        )
    }

}

export default TeamStats;