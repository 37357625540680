const europeLocations = ['Parc Asterix',
'Walibi Holland' ,
'Plopsaland De Panne',
'Europa-Park' ,
'Phantasialand',
'Holiday Park',
'Heide Park Resort',
'Liseberg',
'Efteling Themepark Resort',
'Attractiepark Toverland',
'Disneyland Paris',
'PortAventura World',
'Bellewaerde',
'Gardaland',
'Thorpe Park',
'LEGOLAND Windsor',
'Alton Towers Resort',
'Chessington World of Adventures Resort'];

const americasLocations = ['Busch Gardens Tampa',
'Busch Gardens Williamsburg',
'Six Flags Darien Lake',
'Six Flags Discovery Kingdom',
'Six Flags Fiesta Texas',
'Six Flags Magic Mountain',
'Six Flags Great Adventure',
'La Ronde, Montreal',
'Six Flags México',
'Six Flags New England',
'Six Flags St. Louis',
'The Great Escape',
'Six Flags Over Texas',
'Universal Studios',
'Universal Orlando Resort',
'Walt Disney World® Resort',
'Hersheypark',
'Silver Dollar City',
'Dollywood',
'Worlds of Fun',
`Michigan's Adventure`,
`Knott's Berry Farm`,
'Cedar Point',
'Six Flags Great America',
`California's Great America`,
'Six Flags America',
'Six Flags Frontier City',
'Six Flags Over Georgia',
`Canada's Wonderland`,
'Kings Dominion',
'SeaWorld San Diego',
'SeaWorld San Antonio',
'SeaWorld Parks and Resorts Orlando',
'Dorney Park',
'Carowinds',
'Kings Island',
'Valleyfair',
'LEGOLAND California',
'Disneyland Resort',
'LEGOLAND Florida'];

const asiaLocations = ['Tokyo Disney Resort',
'Shanghai Disney Resort',
'Hong Kong Disneyland Parks'];

export {
    europeLocations,
    americasLocations,
    asiaLocations
}