import {Buffer} from 'buffer';

const axios = require('axios');
const apiKey = process.env.REACT_APP_SPORTS_API_KEY; 

// Get matches by date and sport, then filter to desired league games
const getEventsByDate = async (league, date, sport) => {
    const eventsOptions = {
        method: 'GET',
        url: `https://sportapi7.p.rapidapi.com/api/v1/sport/${sport}/scheduled-events/${date}`,
        headers: {
          'X-RapidAPI-Key': apiKey,
          'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
        }
    };

    try {
        const res = await axios.request(eventsOptions);
        const events = res.data.events;

        // Filter Out Unrelated Teams
        const latestEvents = [];

        for (let i = 0; i < 80; i++) {
            if (events[i] && events[i].tournament.name === league) {
                latestEvents.push(events[i]);
            };
        };

        if (latestEvents.length < 1) {
          return null;
        };

        return latestEvents.reverse();
    } catch (error) {
        console.error(error);
    };
};

// Get stats by match
const getStatsByEvent = async (eventId) => {
    const statsOptions = {
        method: 'GET',
        url: `https://sportapi7.p.rapidapi.com/api/v1/event/${eventId}/statistics`,
        headers: {
          'X-RapidAPI-Key': apiKey,
          'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
        }
      };

      try {
        const res = await axios.request(statsOptions);

        return res.data.statistics;
    } catch (error) {
        console.error(error);
    };
};

// Get Cricket Stats (Cricket Uses a Diff Endpoint)
const getCricketStatsByEvent = async (eventId) => {
  const cricketStatsOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/event/${eventId}/innings`,
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
    }
  };

  try {
    const res = await axios.request(cricketStatsOptions);

    return res.data.innings;
  } catch (error) {
      console.error(error);
  };
}

// Get best players by match
const getBestPlayers = async (eventId) => {
  const bestPlayersOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/event/${eventId}/featured-players`,
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
    }
  };
  
  try {
    const res = await axios.request(bestPlayersOptions);

    return res.data;
  } catch (error) {
    console.error(error);
  };
};

// Get Lineup by match
const getLineup = async (eventID) => {
  const lineupOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/event/${eventID}/lineups`,
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
    }
  };

  try {
    const res = await axios.request(lineupOptions);

    return res.data;
  } catch (error) {
    console.error(error);
  };
}

// Get Standings by season
const getStandings = async (seasonId, tournamentID) => {
  const standingsOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/tournament/${tournamentID}/season/${seasonId}/standings/total`,
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
    }
  };

  try {
    const res = await axios.request(standingsOptions);

    const teams = res.data.standings[0].rows;
    let arr = [];

    teams.forEach((team) => {
      arr.push(team);
    });

    const top10 = arr;

    return top10;
  } catch (error) {
    console.error(error);
  };
}

// Get Leagues From Search Query
const searchLeagues = async (query) => {
  const searchOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/search/unique-tournaments/${query}/unique-tournaments`,
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
    }
  };

  try {
    const res = await axios.request(searchOptions);

    return res.data.uniqueTournaments;
  } catch (error) {
    console.error(error);
  };
}

const searchGamesByCategory = async (category, date) => {
  const categoryGamesOptions = {
    method: 'GET',
    url: 'https://sofascores.p.rapidapi.com/v1/events/schedule/category',
    params: {
      category_id: category,
      date: date
    },
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sofascores.p.rapidapi.com'
    }
  };

  try {
    const res = await axios.request(categoryGamesOptions);

    return res.data.data;
  } catch (error) {
    console.error(error);
  };
}

const getIncidents = async (eventID) => {
  const incidentsOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/event/${eventID}/incidents`,
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
    }
  };

  try {
    const res = await axios.request(incidentsOptions);

    let recentEvents = [];
    let incidents = res.data.incidents;

    for (let i = 0; i < 5; i++) {
      recentEvents.push(incidents[i]);
    };

    return incidents;
  } catch (error) {
    console.error(error);
  };
}

const getLogoByTeamID = async (teamID) => {
  const logoOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/team/${teamID}/image`,
    responseType: 'arraybuffer',
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com',
      'Content-Type': 'image/png'
    }
  };
  
  try {
    const res = await axios.request(logoOptions);
    let base64ImageString = Buffer.from(res.data, 'binary').toString('base64');

    return base64ImageString;
  } catch (error) {
    console.error(error);
  }
}

const getPlayerPhotoByPlayerID = async (playerID) => {
  const playerOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/player/${playerID}/image`,
    responseType: 'arraybuffer',
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
    }
  };
  
  try {
    const res = await axios.request(playerOptions);
    let base64ImageString = Buffer.from(res.data, 'binary').toString('base64');

    return base64ImageString;
  } catch (error) {
    console.error(error);
  }
}

const getMatchInfoByMatchID = async (matchID) => {
  const matchInfoOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/event/${matchID}`,
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
    }
  };
  
  try {
    const res = await axios.request(matchInfoOptions);

    return res.data.event;
  } catch (error) {
    console.error(error);
  }
}

const getLiveEventsBySport = async (sport) => {
  const liveEventsOptions = {
    method: 'GET',
    url: `https://sportapi7.p.rapidapi.com/api/v1/sport/${sport}/events/live`,
    params: {sport_id: sport},
    headers: {
      'X-RapidAPI-Key': apiKey,
      'X-RapidAPI-Host': 'sportapi7.p.rapidapi.com'
    }
  };

  try {
    const res = await axios.request(liveEventsOptions);
    let games = [];

    res.data.events.forEach((game) => {
      games.push(game);
    })

    return games;
  } catch (error) {
    console.error(error);
  }
}

export {
    getEventsByDate,
    getStatsByEvent,
    getBestPlayers,
    getLineup,
    getStandings,
    getCricketStatsByEvent,
    searchLeagues,
    searchGamesByCategory,
    getIncidents,
    getLogoByTeamID,
    getPlayerPhotoByPlayerID,
    getMatchInfoByMatchID,
    getLiveEventsBySport
};