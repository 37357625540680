import React from "react";
import {
  ICard,
  UList,
  SidebarContainer,
  ItemRight,
  Avatar,
  MessageText,
  UserName,
  Time,
  Item,
  UnseenCount,
  Msg,
  ChatList,
} from "./styles";
import { Row } from "../../utils/sharedStyles";
import Icon from "../Icon";
import { getAvatarPath, sortConversations } from "../../utils/helpers";
import moment from "../../utils/moment";
import { CreateChat } from "../index";
import Api from "../../config/axios";
// import { FaCheck } from "react-icons/fa";
// import { ImCross, ImBlocked } from "react-icons/im";
import "./styles.css";
import { Link, useNavigate } from 'react-router-dom';
import debounce from "lodash.debounce";

import chatIcon from '../LiveSports/SportsAssets/chatIcon.png';
import chatIconBlue from '../LiveSports/SportsAssets/chatIconBlue.png';
import sportsIcon from '../LiveSports/SportsAssets/sportsIcon.png';
import sportsIconBlue from '../LiveSports/SportsAssets/sportsIconBlue.png';

import settingsBlue from './sidebarAssets/settingsBlue.png';
import logoutBlue from './sidebarAssets/logoutBlue.png';

function Sidebar({ user, conversations, ...props }) {
  const [createVisible, setCreateVisible] = React.useState(false);
  const [convoSelected, setConvoSelected] = React.useState("");
  const [activeTab, setActiveTab] = React.useState("conversation");
  const [search, setSearch] = React.useState("");
  const [searchDb, setSearchDb] = React.useState("");

  // debouncing function for handling search input changes
  const debouncedSave = React.useCallback(
    debounce((nextValue) => setSearchDb(nextValue), 1000),
    []
  );

  //function to handle search input changes
  const handleChange = (val) => {
    setSearch(val);
    debouncedSave(val);
  };

  const navigate = useNavigate();

  const getListData = React.useCallback(() => {
    const blocked = user?.blocked?.map((b) => b._id);
    const blockedFrom = user?.blockedFrom?.map((b) => b._id);
    const filter = (c) => {
      if (c.isGroup) return true;
      else
        return (
          !c.users.find((u) => blocked?.includes(u._id)) &&
          !c.users.find((u) => blockedFrom?.includes(u._id))
        );
    };
    return conversations.filter(filter);
  }, [user, conversations]);

  // const handleBlock = async (fReq) => {
  //   const res = await Api.put(`/user/block/${fReq.sender}`);
  //   window.location.reload(true);
  // };

  const msgText = React.useCallback(
    (icon, text, unseenMessage) => (
      <Msg>
        <Icon name={icon} size={16} />
        <MessageText unseen={unseenMessage} noFont style={{ marginLeft: 4 }}>
          {text}
        </MessageText>
      </Msg>
    ),
    []
  );
  const renderItem = React.useCallback(
    (item) => {
      const recipient = item.users.find((x) => x._id !== user._id);
      let text;
      const msg = item.message;
      const sender = msg?.user?._id === user._id;
      const unseenMessage = msg && !sender && !msg?.seenBy?.includes(user._id);
      const unseenMessageLength = item.unseenMessageLength;
      let messageText = 'None';
      if (msg && msg.text && msg.text[0] !== undefined && msg.user && msg.user._id === user._id) {
        messageText = msg.text[0].text;
      } else if (msg && msg.text && msg.text[1] !== undefined && msg.user && msg.user._id !== user._id) {
        messageText = msg.text[1].text;
      };
      text = !msg ? (
        <MessageText>Draft...</MessageText>
      ) : msg.image ? (
        msgText("image", "Photo", unseenMessage)
      ) : msg.audio ? (
        msgText("headphones", "Sound", unseenMessage)
      ) : msg.location ? (
        msgText("pin", "Location", unseenMessage)
      ) : msg.video ? (
        msgText("video", "Video", unseenMessage)
      ) : (
        <MessageText unseen={unseenMessage}>
          {msg.text?.find((i) => i.language === user.language)?.text || messageText} 
        </MessageText>
      );
      return (
        <Item
          onClick={() => {
            props.setChatId(item._id);
            setConvoSelected(item._id);
            props.setSidebarStatus("close");
          }}
          className={convoSelected === item._id ? "selected" : ""}
          unseen={unseenMessage}
          key={item._id}
        >
          <Row>
            <Avatar
              src={getAvatarPath(
                !item.isGroup ? recipient?.avatar : item.image,
                item.isGroup
              )}
            />
            <div>
              <UserName unseen={unseenMessage}>
                {!item.isGroup ? recipient?.name : item.name}
              </UserName>
              <div>{text}</div>
            </div>
          </Row>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Time>{moment(msg?.createdAt || item.createdAt).fromNow()}</Time>
            {props.width > 375 ? (
              <ItemRight>
                {unseenMessage ? (
                  <UnseenCount>
                    {unseenMessageLength > 9 ? "9+" : unseenMessageLength}
                  </UnseenCount>
                ) : (
                  <div style={{ height: 0 }} />
                )}
                <div style={{ opacity: sender && !unseenMessage ? 1 : 0 }}>
                  <Icon name={"done-all-outline"} color="gray" size={15} />
                </div>
              </ItemRight>
            ) : (
              <></>
            )}
          </div>
        </Item>
      );
    },
    [user._id, msgText, props]
  );

  const [avatar, setAvatar] = React.useState();

  React.useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const imag = await getAvatarPath(user.avatar);
        setAvatar(imag);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAvatar();
  }, [user.avatar]);

  const handleProfileClick = async (event) => {
		event.preventDefault();
		await Api.put('/user/updateUserStatus/' + user._id, { status: 'yellow' });
		navigate(`/app/myprofile`, { state: user });
	};

  const handleLogout = () => {
		localStorage.removeItem('token');
		navigate('/');
	};

  return (
    <>
    <div id="sideBarProfileContainer">
      <div className="sideBarSubContainer">
        <img onClick={handleProfileClick} id="sideBarImage" src={avatar} className="image" alt="avatar" style={{ borderRadius: "50%", objectFit: "cover", width: "50px", height: "50px"}} />
        <Link to='/app/chat' id='chatIconsSidebar'>
            <img id='chatIconBlueSideBar' className='sideBarIcon' src={chatIconBlue} alt='chat'/>
        </Link>
        <Link to='/sports' id='sportsIconsSidebar'>
          <img id='sportsIconBlueSideBar' className='sideBarIcon' src={sportsIconBlue} alt='sports'/>
        </Link>
      </div>
      <div className="sideBarSubContainer">
        <img onClick={handleProfileClick} id='settingsBlueSidebar' className='sideBarIcon' src={settingsBlue} alt='settings'/>
        <img onClick={handleLogout} id='logoutBlueSidebar' className='sideBarIcon' src={logoutBlue} alt='logout'/>
      </div>
    </div>
    <SidebarContainer>
      <Row align="center" justify="space-between" className="head">
        <h3>Messages</h3>
        <div onClick={() => setCreateVisible(true)}>
          <Icon name="plus-circle-outline" />
        </div>
      </Row>
      <ChatList>
        <UList className="nav nav-tabs" id="myTab" role="tablist">
          {/* <li className="nav-item" role="presentation">
            <button
              className={
                "nav-link" + (activeTab === "invitation" ? " active" : "")
              }
              onClick={() => setActiveTab("invitation")}
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Requests
            </button>
          </li> */}
          {/* <li className="nav-item" role="presentation"> */}
          <button
            className={
              "nav-link" + (activeTab === "conversation" ? " active" : "")
            }
            onClick={() => setActiveTab("conversation")}
            data-bs-toggle="tab"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            Conversations
          </button>
          {/* </li> */}
        </UList>
        {activeTab === "conversation" && (
          <div id="conversationsContainer">
            {getListData().slice().sort(sortConversations).map(renderItem)}
          </div>
        )}
      </ChatList>
      {createVisible && (
        <CreateChat
          close={() => setCreateVisible(false)}
          user={user}
          setChatId={props.setChatId}
          createChat={props.createChat}
        />
      )}
    </SidebarContainer>
    </>
  );
}

export default Sidebar;
