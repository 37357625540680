import styled from "styled-components";

export const ProfileContainer = styled.div`
  width: 300px;
  flex-basis: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  transition: all 0.3s ease-in;
  background-color: ${({ theme }) => theme.bg};
  border-left: 1px solid ${({ theme }) => theme.border};
  text-align: center;
  padding: 20px;
  padding-top: 100px;
  color: #a8a8a8;
  .title {
    font-size: 20px;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.txt};
  }
  .AddUserButton {
    background: transparent;
    border: none;
    color: rgb(53, 139, 208);
    cursor: pointer;
    font-size: 30px;
    transition: 0.3s ease-out;
  }
  .AddUserButton:hover {
    color: rgb(30, 80, 120);
    background: transparent;
  }
  .AddUserContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    color: ${({ theme }) => theme.txt};
  }
  .GroupUsers {
    color: ${({ theme }) => theme.txt};
  }
  .GroupUsersLine {
    color: ${({ theme }) => theme.txt};
    margin-top: -10px;
  }
`;

export const Avatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100px;
  margin-bottom: 25px;
`;

export const Close = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.txt};
  }
`;

export const ArchSubChatContainer = styled.div`
  margin-top: 30px;
  border: 1px solid lightgray;
  transition: all 0.3s ease-in;
  border-radius: 0.5rem;
  max-height: 245px;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
  ${(props) =>
    props.isVisible ? "height: auto; " : "height: 35px; overflow: hidden; "};
`;

export const ArchSubChatContent = styled.div`
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  transition: all 0.3s ease-in;
  border-radius: 0.5rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 2rem;
  padding: 0 10px;
  margin: 0.5rem;
  cursor: pointer;
  ${(props) =>
    props.isVisible ? "opacity: 1;" : "opacity: 0; pointer-events: none;"};
`;
