import React, { useState, useEffect } from "react";
import './styles.css';
import { AiOutlineMail, AiOutlineEdit, AiOutlineCloudUpload } from "react-icons/ai";
import { BsPerson } from "react-icons/bs"
import DropDownInput from "../../../components/DropdownInput"
import { Button, Input, Space } from 'antd';
import { getAvatarPath } from "../../../utils/helpers";
import { toast } from "react-toastify";
import Api from '../../../config/axios';
import { useNavigate } from "react-router-dom";
import FileUpload from "../../../components/FileUpload";

import { NavLink, useLocation } from 'react-router-dom';
import { languages } from '../../../utils/languages';

const { TextArea } = Input;

export default function EditProfiletab({user}) {
  const [username, setUsername] = useState(user.name);
  const [nameEdit, setNameEdit] = useState(true);
  const [avatar, setAvatar] = useState();
  const navigate = useNavigate();

  const location = useLocation();
	const userData = location.state;
  const [userLanguage, setUserLanguage] = React.useState(userData.language);

  const bioWords = "A little about the company and the team that you'll be working with.";

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const imag = await getAvatarPath(user.avatar);
        setAvatar(imag);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAvatar();
  }, [user.avatar]);

  const handleSave = async () => {
    if (!username || username.trim() === '') {
      toast.warn('Invalid name');
      return;
    };
    if (user.name !== username) {
      await Api.put(`/user`, { name: username } );
    }
    toast.success('Profile Updated');
    navigate('/app/chat');
  }

  const handleCancel = () => {
    navigate('/app/chat');
  }
  const handleNameChange = (event) => {
    setUsername(event.target.value);
  }
  const handleEdit = () => {
    setNameEdit(false);
  }
  const handleBlur = () => {
    setNameEdit(true);
  }
  const handleAvatarChange = (source) => {
    const formData = new FormData();
    formData.append('file', source);
    Api.put(`/user/avatar`, formData)
        .then((res) => {
          toast.success("Avatar Updated");
          setAvatar(getAvatarPath(res.data.path));
          return res.data.path;
        })
        .catch((error) => {
          toast.error("Error");
        });
  }

  const handleLanguageChange = React.useCallback(async (e) => {
		const selectedLanguage = e.target.value;
		setUserLanguage(selectedLanguage);

		// Map the language code to its corresponding name
		const selectedLanguageName = languages.find(
			(language) => language.language === selectedLanguage
		)?.name;

		// Map the user language code to its corresponding name
		const userLanguageName = languages.find(
			(language) => language.language === userLanguage
		)?.name;

		if (selectedLanguage === userLanguage) {
			toast.success('Your language is already set to ' + userLanguageName);
		} else {
			try {
				await Api.put(`/user/language/${userData._id}`, {
					language: selectedLanguage
				});
				toast.success(
					'Your language has been changed to ' + selectedLanguageName
				);
				setUserLanguage(selectedLanguage);
			} catch (error) {
				toast.error('Failed to update language');
			}
		}
	});

  return (
    <div className="page-container">
      <div className="avatar-container" style={{ width: "100px", height: "100px", position: "relative", overflow: "hidden", margin: "0"}}>
        {avatar ? (
          <img src={avatar} className="image" alt="" style={{ borderRadius: "50%", objectFit: "cover", width: "80%", height: "80%", marginLeft: "1rem"}} />
          ) : (
            <div>Loading avatar...</div>
            )}
          <FileUpload accept="image/*" onChange={handleAvatarChange}>
            <AiOutlineCloudUpload size={20} color={'#888888'} style={{ position: "absolute",  bottom: "0.7rem", left: "0.6rem", cursor: "pointer"}}/>
          </FileUpload>
      </div>
      <form className="info-container">
        <h6 className='user-info'>Name</h6>
        <div className="name-box">
          <AiOutlineEdit className="edit-icon" onClick={handleEdit}/>
          <input className='input-field' value={username} onChange={handleNameChange} disabled={nameEdit} onBlur={handleBlur}/>
        </div>
        <h6 className='user-info'>Email</h6>
        <div className="mail-box">
          <AiOutlineMail className="input-icon" />
          <input type="email" placeholder="Enter your email" className="input-field" value={user.email} disabled/>
        </div>
        <h6 className='user-info' type="tel">Phone Number</h6>
        <input className='input-box' value={user.phone} disabled/>
        <h6 className='language-info'>Language</h6>
        <div className="language-box">
          <BsPerson className="input-icon" />
          <DropDownInput onChange={handleLanguageChange} className='select-box' label="Language" />
        </div>
        <h6 className="language-info">Bio</h6>
        <TextArea showCount maxLength={250} rows={7} placeholder={bioWords} style={{width: '40%', resize: 'none'}}/>
        {/* <h6 className="user-info">Location</h6>
        <input className='input-box' value="Los Angeles, CA"/> */}
        {/* <h6 className="user-info">Sports</h6>
        <input className='input-box' /> */}
        <br/>
        <Space wrap className="button-box">
          <Button type="default" className="cancel-btn" onClick={handleCancel}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>Save</Button>
        </Space>
      </form>
    </div>
  )
}
