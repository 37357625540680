import React from 'react';

function BestPlayers( {...props} ) {

    return (
        <div id='topPlayers'>
            <h2 className='statsContainerTitle topPlayersTitle'>Top Performers</h2>
            {props.bestPlayerStats.home ? 
                <>
                <div className='topPlayersRow'>
                    <div className='topPlayersSubContainer'>
                        <img className='playerPhoto' src={`data:image/png;base64, ${props.homePlayerURL}`} alt='Player'/>
                    </div>
                    <div className='topPlayersSubContainer'>
                        <h2 className='statsText'>{props.bestPlayerStats.home.player.name}</h2>
                        <div className='statsRow'>
                            <div className='smallStatsWrapper'>
                                <h2 className='statsText'>{props.bestPlayerStats.home.statistics.points}</h2>
                                <h2 className='statsText'>PTS</h2>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            : <></>}
            {props.bestPlayerStats.away ? 
                <div className='topPlayersRow'>
                    <div className='topPlayersSubContainer'>
                        <img className='playerPhoto' src={`data:image/png;base64, ${props.awayPlayerURL}`} alt='Player'/>
                    </div>
                    <div className='topPlayersSubContainer'>
                        <h2 className='statsText'>{props.bestPlayerStats.away.player.name}</h2>
                        <div className='statsRow'>
                            <div className='smallStatsWrapper'>
                                <h2 className='statsText'>{props.bestPlayerStats.away.statistics.points}</h2>
                                <h2 className='statsText'>PTS</h2>
                            </div>
                        </div>
                    </div>
                </div>
            : <></>}
        </div>
    )

}

export default BestPlayers;