const axios = require('axios');

// Fetches a list of all theme parks
const getParks = async () => {
    try {
        const res = await axios.get('https://api.themeparks.wiki/v1/destinations');
        const parks = res.data.destinations;

        return parks;
    } catch (error) {
        console.error(error);
    };
};

// Fetches basic park data by park id
const getParkDataById = async (parkId) => {
    try {
        const res = await axios.get(`https://api.themeparks.wiki/v1/entity/${parkId}`);

        return res.data;
    } catch (error) {
        console.error(error);
    };
};

// Fetches basic park data and a list of all rides by park id
const getParkRidesById = async (parkId) => {
    try {
        const res = await axios.get(`https://api.themeparks.wiki/v1/entity/${parkId}/children`);

        const data = {
            id: res.data.id,
            name: res.data.name,
            timezone: res.data.timezone
        };

        const rides = res.data.children;

        const result = {
            data,
            rides
        };

        return result;
    } catch (error) {
        console.error(error);
    };
};

// Fetches live wait times and status by ride id
const getLiveDataByRideId = async (rideId) => {
    try {
        const res = await axios.get(`https://api.themeparks.wiki/v1/entity/${rideId}/live`);

        const data = {
            id: res.data.id,
            name: res.data.name,
            timezone: res.data.timezone
        };

        const liveData = res.data.liveData[0];

        const result = {
            data,
            liveData
        };

        return result;
    } catch (error) {
        console.error(error);
    };
};

// Get all info for rides and wait times by park
const getAllLiveInfoByParkId = async (parkId) => {
    try {
        const res = await axios.get(`https://api.themeparks.wiki/v1/entity/${parkId}/live`);

        const data = {
            id: res.data.id,
            name: res.data.name,
            timezone: res.data.timezone
        };

        const liveData = res.data.liveData;

        const result = {
            data,
            liveData
        };

        return result;
    } catch (error) {
        console.error(error);
    };
};


// Fetch Coaster Times XML Feed
const getCoasterTimesFeed = async () => {
    const feedUrl = "https://coastertimes.com/feed/";

    try {
        const res = await fetch(
            "https://api.rss2json.com/v1/api.json?rss_url=" + feedUrl
        );

        const json = await res.json();
        const blogPosts = json.items;

        return blogPosts;
    } catch (error) {
        console.error(error);
    };
};

export {
    getParks,
    getParkDataById,
    getParkRidesById,
    getLiveDataByRideId,
    getAllLiveInfoByParkId,
    getCoasterTimesFeed
};