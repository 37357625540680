import React from 'react';

function Standings( {...props} ) {

    return (
        <div id='standingsContainer'>
            <div id='standings'>
                <h2 className='statsContainerTitle standingsTitle'>Standings</h2>
                <div className='standingsRow'>
                    <h2 className='standingsText'>Team</h2>
                    <div className='standingsItemContainer'><h2 className='statsText'>W</h2></div>
                    <div className='standingsItemContainer'><h2 className='statsText'>L</h2></div>
                    <div className='standingsItemContainer'><h2 className='statsText'>PCT</h2></div>
                    <div className='standingsItemContainer'><h2 className='statsText'>STRK</h2></div>
                </div>
                {props.standings.map((team) => {
                    return (
                        <div className='standingsRow'>
                            <h2 className='standingsText'>{team.team.name}</h2>
                            <div className='standingsItemContainer'><h2 className='statsText'>{team.wins}</h2></div>
                            <div className='standingsItemContainer'><h2 className='statsText'>{team.losses}</h2></div>
                            <div className='standingsItemContainer'><h2 className='statsText'>{team.percentage}</h2></div>
                            <div className='standingsItemContainerLast'><h2 className='statsText'>{team.streak}</h2></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default Standings;