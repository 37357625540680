import React, { useCallback } from "react";
import {
  ChatContainer,
  ChatContent,
  SubChatContainer,
  SubChatContent,
  Header,
  HeaderAvatar,
  LoadBtn,
  MessageText,
  StatusTxt,
  IconBtns,
} from "./styles";
import { getAvatarPath,getImagePath, mapMessageData } from "../../utils/helpers";
import { Bubble, Avatar, GiftedChat } from "react-native-gifted-chat";
import LocationMessage from "./components/LocationMessage";
import { getBubbleProps } from "./components/bubbleProps";
import { theme } from "../../config/theme";
import ChatInput from "./components/ChatInput";
import Api from "../../config/axios";
import { CenteredContent, Row } from "../../utils/sharedStyles";
import {
  disconnectSocket,
  initiateSocket,
  sendMessage,
  subscribeToChat,
  subscribeToUserTypingStatus,
  userTyping,
} from "./socket";
import VideoMessage from "./components/VideoMessage";
import AudioMessage from "./components/AudioMessage";
import ImageMessage from "./components/ImageMessage";
import { Spinner, Switch } from "../index";
import Icon from "../Icon";
import useDimensions from "../../utils/useDimensions";
import {
  checkRecipientOnline,
  removeListeners,
  subscribeToOffline,
  subscribeToOnline,
  subscribeToRecipientOnlineStatus,
} from "../../pages/ChatRoom/socket";
import styled from "styled-components/native";
import Hyperlink from "react-native-hyperlink";
import "./index.css";
import SubChatModal from "./components/SubChatModal";
import SubChatConversationModal from "./components/SubChatConversationModal";
import { toast } from "react-toastify";
import { emitLastSeen } from "../../components/Chat/socket";

let timeout;

function Chat({
  data,
  user,
  mode,
  sideBarToggle,
  sidebarStatus,
  subChats,
  setSubChats,
  // updateSubChat,
  openSubChats,
  closeSubChats,
  rotate,
  setRotate,
  subChatOpen,
  setSubChatOpen,
  ...props
}) {
  const { width } = useDimensions();
  const [messages, setMessages] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [isGroup, setIsGroup] = React.useState(false);
  const [groupName, setGroupName] = React.useState("");
  const [groupImage, setGroupImage] = React.useState("");
  const [recipient, setRecipient] = React.useState();
  const [recipients, setRecipients] = React.useState([]);
  const [isReady, setIsReady] = React.useState(false);
  const [loadingMoreMsg, setLoadingMoreMsg] = React.useState(false);
  const [noMoreMsg, setNoMoreMsg] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [statusTxt, setStatusTxt] = React.useState("red");
  const [isOnline, setIsOnline] = React.useState(false);
  const [recipientTyping, setRecipientTyping] = React.useState(false);
  const [isTyping, setIsTyping] = React.useState(false);
  const [selectedBubble, setSelectedBubble] = React.useState(null);
  //Testing something out for load more button here
  const [totalPages, setTotalPages] = React.useState(1);
  const [background, setBackground] = React.useState(user.background);
  const [subChatModal, setSubChatModal] = React.useState(false);
  const [subChatConversationModal, setSubChatConversationModal] =
    React.useState(false);
  const [selectedSubChatConversation, setSelectedSubChatConversation] =
    React.useState(null);

  React.useEffect(() => {
    setIsReady(false);
    setMessages([]);
    setMessage("");
    setIsGroup(false);
    setGroupName("");
    setGroupImage("");
    setRecipient();
    setRecipients([]);
    setLoadingMoreMsg(false);
    setNoMoreMsg(false);
    setPage(0);
    setData();
    setStatus();
    const fetchBackground = async() => {
      try{
        setBackground(user.background);
      }
      catch(error){
        console.log(error);
      }
    }
    fetchBackground();
  }, [data,user.background]);

  React.useEffect(() => {
    if (subChats.length) {
      setSubChatOpen(true);
      setRotate("rotate(180deg)");
    } else {
      setSubChatOpen(false);
      setRotate("rotate(0deg)");
    }
  }, [setRotate, setSubChatOpen, subChats.length]);

  const setStatus = async () => {
    await Api.get("user/getUserStatus/" + data.users[0]._id).then((res) => {
      setStatusTxt(res.data);
      // socket.emit('lastSeen', { userId: data.users[0]._id, lastSeen: res.data.lastSeen });
      emitLastSeen({ userId: data.users[0]._id, lastSeen: res.data.lastSeen });
    });
  };

  React.useEffect(() => {
    if (isOnline === true) {
      setStatusTxt("green");
    } else {
      setStatusTxt("red");
    }
  }, [statusTxt, isOnline, isTyping, emitLastSeen]);

  const setData = React.useCallback(async () => {
    if (data._id) {
      removeListeners(["userOnline", "userOffline"]);
      disconnectSocket();
      initiateSocket(data._id);
      subscribeToChat((data) => {
        if (data.message) {
          setMessages((oldChats) => [data.message, ...oldChats]);
          setSeenMessages([data.message._id]);
        }
      });
      if (!data.isGroup) {
        const recipientUser = data.users.find((x) => x._id !== user._id);
        setRecipient(recipientUser);
        subscribeToOnline(recipientUser._id, () => setIsOnline(true));
        subscribeToOffline(recipientUser._id, () => setIsOnline(false));
        subscribeToUserTypingStatus((status) => setRecipientTyping(status));
        subscribeToRecipientOnlineStatus((status) => setIsOnline(status));
        checkRecipientOnline(recipientUser._id);
      } else {
        setRecipients(data.users.filter((x) => x._id !== user._id));
        setGroupName(data.name);
        setGroupImage(data.image);
        setIsGroup(true);
      }
      setMessages(data.messages || []);
      const unseenMessages = data.messages
        .filter((m) => m.user !== user._id && !m.seenBy?.includes(user._id))
        .map((m) => m._id);
      setSeenMessages(unseenMessages);
      setIsReady(true);
    }
  }, [data]);

  const openSubChatModal = React.useCallback(() => {
    setSubChatModal(true);
  }, []);

  const closeSubChatModal = React.useCallback(() => {
    setSubChatModal(false);
  }, []);

  const openSubChatConversationModal = React.useCallback((subChat) => {
    setSubChatConversationModal(true);
    setSelectedSubChatConversation(subChat);
  }, []);

  const closeSubChatConversationModal = React.useCallback(() => {
    setSubChatConversationModal(false);
    setSelectedSubChatConversation(null);
  }, []);

  const createSubChat = React.useCallback(
    async (name) => {
      try {
        closeSubChatModal();
        const res = await Api.post(`/subchat/subconversation/${data._id}`, {
          name,
          participants: [...data.users],
        });

        const newSubChat = res.data;
        //if there are already 3pinned subchats, we need to push one of the earliest subchat created to archive
        if (subChats.length > 2) {
          const unArchivedSubChats = subChats.filter(
            (subChat) => !subChat.isArch
          );
          await Api.put(
            `/subchat/subconversation/${unArchivedSubChats[0]._id}`
          );
          setSubChats([
            ...subChats.map((subChat) => {
              if (subChat._id === unArchivedSubChats[0]._id) {
                return { ...subChat, isArch: true };
              } else {
                return subChat;
              }
            }),
            newSubChat,
          ]);
        } else {
          setSubChats([...subChats, newSubChat]);
        }
        setSubChatOpen(true);
        setRotate("rotate(180deg)");
      } catch (error) {
        toast.warn("An error has occcurred. Please try again");
        console.error("An error has occcurred", error);
      }
    },
    [
      data._id,
      data.users,
      subChats,
      setSubChats,
      closeSubChatModal,
      setRotate,
      setSubChatOpen,
    ]
  );

  const setSeenMessages = React.useCallback(
    async (messageIds) => {
      const reqData = {
        messageIds,
        conversationId: data._id,
        userId: user._id,
      };
      await Api.put("/chat/conversation/set-seen-messages", { messageIds });
      props.setSeenMessages(reqData);
    },
    [data, user]
  );

  const messagesData = React.useMemo(
    () => mapMessageData(messages),
    [messages]
  );

  const onSend = React.useCallback(
    async (id, message, isGroup) => {
      const res = await Api.post("/chat/conversation/reply/" + id, {
        messageData: { ...message },
        originalLang: user.language,
        isGroup,
      });
      props.updateLastMessage(id, res.data.message);
      setMessages((messages) =>
        messages.map((msg, i) => (i === 0 ? res.data.message : msg))
      );
      sendMessage({
        ...res.data.message,
        recipientIds: isGroup ? recipients.map((r) => r._id) : [recipient._id],
      });
    },
    [isGroup, recipients, recipient, data]
  );

  const onChangeTimeoutFunc = React.useCallback(() => {
    setIsTyping(false);
    userTyping(false);
  }, []);

  React.useEffect(() => {
    if (message && isOnline) {
      if (!isTyping) {
        setIsTyping(true);
        userTyping(true);
        timeout = setTimeout(onChangeTimeoutFunc, 1500);
      } else {
        clearTimeout(timeout);
        timeout = setTimeout(onChangeTimeoutFunc, 1500);
      }
    }
  }, [message]);

  const appendMessage = React.useCallback(
    (message) => {
      setMessages((previousMessages) => [
        {
          _id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(2, 10),
          ...message,
          createdAt: new Date(),
          user: { _id: user._id, name: user.name, avatar: user.avatar },
        },
        ...previousMessages,
      ]);
    },
    [user, recipients, isGroup, recipient]
  );

  const deleteMessage = React.useCallback(
    async (message) => {
      console.log(message);
      if (message.user._id === user._id) {
        await Api.delete("/chat/conversation/message/" + message._id);
        setMessages((messages) =>
          messages.filter((msg) => msg._id !== message._id)
        );
        const isLastMsg =
          messages.findIndex((m) => m._id === message._id) === 0;
        if (isLastMsg) props.updateLastMessage(data._id, messages[1]);
      }
    },
    [user._id, messages, data._id, props]
  );

  const onBubbleLongPress = React.useCallback(
    (context, message) => {
      const options =
        message.user._id === user._id
          ? [
              "Show Original Text",
              "Reply in Thread",
              "Delete Message",
              "Cancel",
            ]
          : ["Show Original Text", "Reply in Thread", "Cancel"];

      if (!message.originalText) options.shift();

      if (selectedBubble === message._id) {
        options[0] = "Hide Original Text";
      }
      const cancelButtonIndex = options.length - 1;
      if (options.length === 4) {
        context
          .actionSheet()
          .showActionSheetWithOptions(
            { options, cancelButtonIndex },
            async (buttonIndex) => {
              if (buttonIndex === 0 && options[0] === "Show Original Text")
                setSelectedBubble(message._id);
              if (buttonIndex === 0 && options[0] === "Hide Original Text")
                setSelectedBubble(null);
              if (buttonIndex === 2) deleteMessage(message);
            }
          );
      } else if (options.length === 3)  {
        context
          .actionSheet()
          .showActionSheetWithOptions(
            { options, cancelButtonIndex },
            async (buttonIndex) => {
              console.log(buttonIndex);
              if (buttonIndex === 0 && options[0] === "Show Original Text")
                setSelectedBubble(message._id);
              if (buttonIndex === 0 && options[0] === "Hide Original Text")
                setSelectedBubble(null);
              if (buttonIndex === 1) 
                deleteMessage(message);
  
            }
          );
      }
    },
    [messages, data._id, selectedBubble]
  );

  const loadMore = React.useCallback(async () => {
    const newPage = page + 1;
    setLoadingMoreMsg(true);
    const resPageCount = await Api.get(
      `/chat/conversation/${data._id}/messages/totalPages`
    );
    const pageCount = resPageCount.data.pageCount - 1;

    const res = await Api.get(
      `/chat/conversation/${data._id}/messages?page=${newPage}`
    );
    setMessages((state) => [...state, ...res.data.messages]);
    setLoadingMoreMsg(false);
    if (newPage === pageCount) {
      setNoMoreMsg(true);
    } else {
      setPage(newPage);
    }
  }, [data._id, messages.length, page]);

  const renderLoadMoreBtn = React.useMemo(() => {
    if (messages.length > 19 && !noMoreMsg) {
      return (
        <LoadBtn onClick={loadMore} disabled={loadingMoreMsg}>
          {loadingMoreMsg ? <Spinner size={25} color="#fff" /> : "Load More"}
        </LoadBtn>
      );
    } else {
      return null;
    }
  }, [loadingMoreMsg, messages, loadMore, noMoreMsg]);

  const formatLink = (text) => {
    return (
      <Hyperlink
        linkDefault={true}
        linkStyle={{ color: "blue", textDecorationLine: "underline" }}
      >
        <div style={{ fontSize: `${user.fontSize}rem` }}>{text}</div>
      </Hyperlink>
    );
  };

  const profileInfo = React.useCallback(
    (recipient) => {
      if (!isGroup) {
        props.setProfile(recipient._id);
      } else {
        props.setProfile(data);
      }
    },
    [data, isGroup]
  );

  function closeModal() {
    const modal = document.getElementById('imageModal');
    modal.style.display = 'none';
  };

  if (!isReady)
    return (
      <CenteredContent className="loading">
        <Spinner />
      </CenteredContent>
    );

  return (
    <ChatContainer
      background = {getImagePath(background)}
      useBackground = {user.useBackground}
      onFocus={() => {
        if (sidebarStatus === "open") sideBarToggle("close");
      }
      
    }
    >
      <Header>
        {width < 700 ? (
          <div onClick={sideBarToggle}>
            <Icon name="menu-outline" color="#848484" />
          </div>
        ) : (
          <></>
        )}
        <Row align="center" onClick={() => profileInfo(recipient)}>
          <div style={{ position: "relative" }}>
            <HeaderAvatar
              src={getAvatarPath(
                isGroup ? groupImage : recipient.avatar,
                isGroup
              )}
            />
            <div
              style={{
                position: "absolute",
                display: "flex",
                bottom: "35%",
                right: "35%",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: statusTxt,
                  position: "absolute",
                }}
              ></div>
            </div>
          </div>
          <div>
            {isGroup ? groupName : recipient.name}
            {!isGroup && recipientTyping ? (
              <StatusTxt>Typing...</StatusTxt>
            ) : isOnline ? (
              <StatusTxt>Online</StatusTxt>
            ) : null}
          </div>
        </Row>
        <div
          className="switch-button"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Switch
            onChange={() => props.setMode(mode === "dark" ? "light" : "dark")}
            checked={mode === "dark"}
            checkedIcon={false}
            height={25}
            uncheckedIcon={false}
          />
          <IconBtns>
            <i
              id="create"
              className="bx bx-list-plus"
              onClick={openSubChatModal}
            ></i>
            {!subChatOpen ? (
              <i
                className="bx bx-chevron-down"
                style={{ transform: rotate }}
                onClick={() => openSubChats()}
              ></i>
            ) : (
              <i
                className="bx bx-chevron-down"
                style={{ transform: rotate }}
                onClick={() => closeSubChats()}
              ></i>
            )}
          </IconBtns>
          <SubChatModal
            isOpen={subChatModal}
            onClose={closeSubChatModal}
            onCreateSubChat={createSubChat}
            mode={mode}
          />
        </div>
      </Header>
      <SubChatContainer isVisible={subChatOpen}>
        {/* {console.log(subChats)}
        {([...subChats]).reverse().map((subChat) =>
          !subChat.isArch ? (
            <SubChatContent
              onClick={() => openSubChatConversationModal(subChat)}
            >
              # {subChat.name} */}
          {subChats.filter(subChat => subChat.isPinned).reverse().map((subChat) =>
            <SubChatContent
              key={subChat._id}
              onClick={() => openSubChatConversationModal(subChat)}
            >
              # {subChat.name}
              {/* <div style={{ zIndex: 1, fontSize: '20px' }}>
								<i
									className='bx bx-archive-in'
									onClick={(e) => {
										updateSubChat(subChat._id, 'archiveIn');
										e.stopPropagation();
									}}
								></i>
							</div> */}
            </SubChatContent>
            
          // ) : null
        )}
      </SubChatContainer>

      {/* subchat conversation modal */}

      <SubChatConversationModal
        isOpen={subChatConversationModal}
        onClose={closeSubChatConversationModal}
        selectedSubChatConversation={selectedSubChatConversation}
        user={user}
        data={data}
        props={props}
        isGroup={isGroup}
        recipients={recipients}
        mode={mode}
        appendMessage={appendMessage}
      />

      <ChatContent>
        <GiftedChat
          messages={messagesData}
          user={{ _id: user._id }}
          minInputToolbarHeight={60}
          renderBubble={(props) => {
            if (props.currentMessage.location)
              return (
                <LocationMessage
                  location={props.currentMessage.location}
                  messagePosition={props.position}
                />
              );
            if (props.currentMessage.audio)
              return <AudioMessage src={props.currentMessage.audio} />;
            else {
              const allProps = {
                ...props,
                ...getBubbleProps(theme[mode]),
                onLongPress: onBubbleLongPress,
              };
              return (
                <>
                  <Bubble {...allProps} />
                </>
              );
            }
          }}
          renderMessageText={(props) => {
            const { currentMessage } = props;
            const text =
              typeof currentMessage?.text === "string"
                ? currentMessage?.text
                : currentMessage?.text.find((i) => i.language === user.language)
                    ?.text;

            // logic behind changing user's language

            // if the message is mine, then simply show the original text. This is because I want the text to show up as the language that I had originally set my language at at the time of sending it.
            if (currentMessage.user._id === user._id) {
              return (
                <MessageText right={props.position === "right"}>
                  {formatLink(currentMessage.originalText)}
                </MessageText>
              );
              // if it's the OTHER user's message, then pick the original text in the language that me (myself) had my language set up as during the time when HE/SHE sent it.
            } else if (currentMessage.user._id !== user._id) {
              if (currentMessage.otherUsersTranslatedText) {
                const myText = currentMessage.text?.find((i) => i.language === user.language)?.text;
                let translatedText = null;

                // Checks for original message text if users language isn't found
                if (currentMessage.text[0]) {
                  translatedText = currentMessage.text[0].text;
                }

                return (
                  <MessageText right={props.position === "right"}> 
                    {formatLink(myText || translatedText)} 
                  </MessageText>
                );
              } else {
                return (
                  <MessageText right={props.position === "right"}>
                    {formatLink(text)}
                  </MessageText>
                );
              }
            } else if (selectedBubble === currentMessage._id) {
              return (
                <MessageText right={props.position === "right"}>
                  {formatLink(text)}
                  <StyledText mode={theme[mode]}>
                    {currentMessage.originalText}
                  </StyledText>
                </MessageText>
              );
            }
          }}
          renderAvatar={(props) => (
            <Avatar
              {...props}
              containerStyle={{ left: { top: -10, marginRight: 0 } }}
            />
          )}
          renderInputToolbar={() => (
            <ChatInput
              sidebarStatus={sidebarStatus}
              value={message}
              onChange={setMessage}
              onSend={onSend}
              isGroup={isGroup}
              appendMessage={appendMessage}
              chatId={data._id}
              mode={mode}
              user={user}
            />
          )}
          renderMessageVideo={(props) => (
            <VideoMessage src={props.currentMessage.video} />
          )}
          renderMessageImage={(props) => (
            <ImageMessage src={props.currentMessage.image} />
          )}
          listViewProps={{ ListFooterComponent: renderLoadMoreBtn }}
          extraData={[mode]}
          shouldUpdateMessage={(props, nextProps) =>
            props.extraData !== nextProps.extraData
          }
        />
        <div id='imageModal' onClick={closeModal}>
          <img id='imageModalImg' src={null} alt='largeImage'/>
        </div>
      </ChatContent>
    </ChatContainer>
  );
}

const StyledText = styled.Text`
  font-style: italic;
  color: ${(props) => (props.mode.mode === "light" ? "#5A5A5A" : "#D3D3D3")};
`;

export default Chat;