import React, { useState } from "react";
import { Input, Space, Button } from 'antd'
import { toast } from "react-toastify";
import Api from '../../../config/axios';
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
export default function PasswordTab({user}) {
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  })
  const [valid,setValid] = useState(false);
  React.useEffect(() => {
    const hasDigit = /\d/.test(password.newPassword);
    const hasUppercase = /[A-Z]/.test(password.newPassword);
    const hasSpecialChars = password.newPassword.match(/^[^a-zA-Z0-9]+$/) ? true : false;
    setValid(hasDigit && hasUppercase && hasSpecialChars && password.newPassword.length >= 8);
  }, [password])
  const navigate = useNavigate();
  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value })
  }

  const isValidPassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/;
    return regex.test(password);
  }

  const handleUpdatePassword = async() => {
    if(valid === false){
      return toast.warn('New Password is not valid!');
    }
    const { currentPassword, newPassword, confirmNewPassword } = password;

    if (!isValidPassword(newPassword)) {
      toast.warn("Password must contain at least 1 letter, 1 number, and 1 symbol. Minimum length is 12 characters.");
      return;
    }

    if (newPassword === currentPassword) {
      toast.warn("New password must be different from current password");
      return;
    }

    if (newPassword === confirmNewPassword) {
      try {
        await Api.put(`/user/password/${user._id}`, { userNewPassword: newPassword, userOldPassword: currentPassword });
      } catch (error) {
        toast.warn("Invalid password or mismatched");
        return;
      }
    } else {
      toast.warn("New passwords do not match");
      return;
    }
    toast.success('Password updated succesfully');
    localStorage.removeItem("token");
    navigate('/app/login');
  };

  return (
    <div className="page-container">
      <form className="password-container">
        <h2 style={{marginTop: '1em', fontWeight: "bold"}}>Change Password</h2>
        <p>Password must contain at least 1 letter, 1 number, and 1 symbol.</p>
        <p style={{marginBottom: '1em'}}>Minimum length is 8 characters.</p>
        <div className="change-password">
          <p>Current Password</p>
          <Input.Password value={password.currentPassword} name='currentPassword' style={{width: '30%'}} onChange={handlePasswordChange}/>
          <p>New Password</p>
          <Input.Password value={password.newPassword} name='newPassword' style={{width: '30%'}} onChange={handlePasswordChange}/>
          <PasswordChecklist
                style={{padding:'20px'}}
                rules={["minLength", "number","capital",'specialChar']}
                minLength={8}
                value={password.newPassword}
            />
          <p>Confirm New Password</p>
          <Input.Password value={password.confirmNewPassword} name='confirmNewPassword' style={{width: '30%'}} onChange={handlePasswordChange}/>
        </div>
        <Space wrap className="button-box">
          <Button type="default" className="cancel-btn" onClick={() => navigate('/app/forgot-password')}>Forgot Password</Button>
          <Button type="primary" onClick={handleUpdatePassword}>Update Password</Button>
        </Space>
      </form>
    </div>
  )
}
