import React from 'react';
import styled from 'styled-components'
import {getImagePath} from "../../utils/helpers.js"
function Background(props){
    console.log(getImagePath(props.background));
    console.log(props.used);
    let a = getImagePath(props.background);
    const BackgroundDiv = styled.div`
        backgroundImage: ${props.used ? 'url('+ a +')': 'url("../images/bg.png")'};
    `
    return(
        <BackgroundDiv></BackgroundDiv>
    );
    
}
//hello there
export default Background;