import React, { useCallback, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { translateText } from '../../../utils/helpers';
import {
	disconnectSocket,
	initiateSocket,
	sendMessage,
	subscribeToChat,
	subscribeToUserTypingStatus,
	userTyping
} from '../socket';
import Hyperlink from 'react-native-hyperlink';
import { getAvatarPath } from '../../../utils/helpers';
import { theme } from '../../../config/theme';
import Api from '../../../config/axios';
import styled from 'styled-components/native';
import {
	Chat,
	ChatHeader,
	ChatBody,
	ChatMessage,
	ChatMessageReceiver,
	ChatPhoto,
	Timestamp,
	Footer,
	Form,
	SubInput
} from '../styles';
import '../index.css';
import './SubChatModal.css';

import Icon from '../../Icon';
import { IconContainer } from '../styles';
import { InputContainer } from '../styles';
import { InputWrapper } from '../styles';
import { Input } from '../styles';
import { IconBtn } from '../styles';
import { RowItem } from '../styles';
import { ActionsContainer } from '../styles';
import { Row } from '../styles';
import FileUpload from '../../FileUpload';
import { useOutsideAlerter } from '../../../utils/useOutsideAlerter';
import { Btn } from '../styles';
import LocationMessage from './LocationMessage';
import ImageMessage from './ImageMessage';
import AudioMessage from './AudioMessage';

import SportsMessages from './SportsMessages';       
import { getIncidents } from '../../../pages/LiveSports/sofaScoreUtils';
import sportsIcon from '../../LiveSports/SportsAssets/sportsIcon.png';
import { getLiveEventsBySport } from '../../../pages/LiveSports/sofaScoreUtils';

function SubChatConversationModal({
	isOpen,
	onClose,
	selectedSubChatConversation,
	data,
	user,
	isGroup,
	recipients,
	mode,
	appendMessage
}) {
	const [subMessages, setSubMessages] = React.useState();
	const [message, setMessage] = React.useState('');
	const messagesEndRef = useRef(null);

	const [actionsVisible, setActionsVisible] = React.useState(false);
	const actionsRef = React.useRef(null);
	useOutsideAlerter(actionsRef, () => setActionsVisible(false));
	const [groupedMessages, setGroupedMessages] =  React.useState({});

	const [sportsGameId, setSportsGameId] = React.useState({});
	const [recentIncident, setRecentIncident] =  React.useState({});
	const [liveGames, setLiveGames] =  React.useState([]);
	const [currentLiveGame, setCurrentLiveGame] =  React.useState(false);

	const [currentHomeTeam, setCurrentHomeTeam] =  React.useState();
	const [currentAwayTeam, setCurrentAwayTeam] =  React.useState();

	const groupMessagesByDate = (messages) => {
		const groupedMessages = {};
		messages.forEach((message) => {
		  const date = new Date(message.createdAt).toDateString();
		  if (!groupedMessages[date]) {
			groupedMessages[date] = [];
		  }
		  groupedMessages[date].push(message);
		});
		return groupedMessages;
	  };
	  
	  React.useEffect(() => {
		if (subMessages) {
		  const grouped = groupMessagesByDate(subMessages);
		  setGroupedMessages(grouped);
		}
	  }, [subMessages]);
	
	  
	const handleClose = useCallback(() => {
		onClose();
		setMessage('');
	}, []);

	const fetchSubChatCoversationMessages = React.useCallback(async () => {
		if (selectedSubChatConversation) {
			//   fetch the subchat's messages, using the subchat ID
			const res = await Api.get(
				`/subchat/getAllMessagesFromSubChat/${selectedSubChatConversation._id}`
			);
			setSubMessages(res.data);
		}
	}, [selectedSubChatConversation]);

	React.useEffect(() => {
		fetchSubChatCoversationMessages();
		if (selectedSubChatConversation) {
			initiateSocket(selectedSubChatConversation._id);
		}
		// return () => {
		// 	// This function will be executed when the component is unmounted
		// 	// You can perform cleanup tasks here
		// 	setSubMessages(null);
		// 	setMessage('');
		// };
	}, [selectedSubChatConversation]);

	React.useEffect(() => {
		messagesEndRef.current?.scrollIntoView();
	}, [subMessages]);

	const onSend = async (messageData, isGroup) => {
			if (selectedSubChatConversation) {
				const res = await Api.post(
					`/subchat/subConversation/reply/${selectedSubChatConversation._id}`,        
					{
						messageData: messageData,
						originalLang: user.language
					}
				);
				setMessage('');
				fetchSubChatCoversationMessages();
				const recipientUser = data.users == null ?  data.find((x) => x !== user._id) : data.users.find((x) => x._id !== user._id);
				sendMessage({
					...res.data.message,
					recipientIds: isGroup
						? recipients.map((r) => r._id)
						: [recipientUser._id != null ? recipientUser._id : recipientUser]
				});
			}
	}

	const formatLink = (text) => {
		//This is no longer necessary, but can be used in the future for emails?
		//const linkRegex = /(https?:\/\/[^\s]+)/g;

		// return text.split(linkRegex).map((token, index) => {
		//   if (token.match(linkRegex)) {
		//     return (
		//       <Text key={index} style={{ color: 'blue', textDecorationLine: 'underline'}} onPress={() => Linking.openURL(token)}>
		//         {token}
		//       </Text>
		//     );
		//   }
		//   return token;
		// });
		return (
			<Hyperlink
				linkDefault={true}
				linkStyle={{ color: 'blue', textDecorationLine: 'underline' }}
			>
				<div>{text}</div>
			</Hyperlink>
		);
	};

	const appendSubChatMessage = React.useCallback(
		(message) => {
			setSubMessages((previousMessages) => [
			...previousMessages,
			{
			  _id: Math.random()
				.toString(36)
				.replace(/[^a-z]+/g, "")
				.substr(2, 10),
			  ...message,
			  createdAt: new Date(),
			  user: { _id: user._id, name: user.name, avatar: user.avatar },
			}
		  ]);
		},
		[user, recipients, isGroup]
	  );

	const uploadFile = React.useCallback(async (file) => {
		const form = new FormData();
		form.append('file', file);
		const res = await Api.post('/chat/file', form);
		const prefix = 'https://gibber-chat-main.s3.amazonaws.com/';
		return prefix + res.data.path;
	}, []);

	const sendPicOrVideo = async(source) => {
			const uri = URL.createObjectURL(source);
			setActionsVisible(false);
			const type = source.type.includes('image') ? 'image' : 'video';
			let data = { [type]: uri };
			appendSubChatMessage(data);
			data[type] = await uploadFile(source);
			onSend(data, isGroup);
	}

	const sendLocation = async () => {
		const recipientUser = data.users.find((x) => x._id !== user._id);
		await navigator.geolocation.getCurrentPosition((position) =>
			sendMessage({
				location: {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				},
				recipientIds: isGroup
					? recipients.map((r) => r._id)
					: [recipientUser._id]
			}),
		);
		await navigator.geolocation.getCurrentPosition((position) =>
			onSend({location: {
				latitude: position.coords.latitude,
				longitude: position.coords.longitude
			}, isGroup})
		)
		setActionsVisible(false)
	}

	const submit = React.useCallback(async () => {
		if (message) {
			onSend({ text: message }, isGroup);
		}
	}, [message]);

	const sendAudio = async (source) => {
			setActionsVisible(false);
			const uri = URL.createObjectURL(source);
			appendMessage({ audio: uri });
			setActionsVisible(false);
			const data = await uploadFile(source);
			onSend({ audio: data }, isGroup);
	}

	const formatDateTime = (date) => {
		var dt = new Date(date);
		var hours = dt.getHours(); // gives the value in 24 hours format
		var AmOrPm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12 || 12;
		var minutes = dt.getMinutes();
		if (minutes.toString().length === 1) {
			minutes = '0' + minutes;
		}
		var finalTime = hours + ':' + minutes + ' ' + AmOrPm;
		return finalTime;
	};

	// // Fetches play and creates message, than emits it
	// const getSportsIncidentsByGameId = async () => {
	// 	if (currentLiveGame) {
	// 		const res = await getIncidents(currentLiveGame);
	
	// 		const recentEvent = res[1];
	// 		setRecentIncident(recentEvent);
	
	// 		let sport = 'basketball';
	// 		let teams = {
	// 			homeTeam: currentHomeTeam,
	// 			awayTeam: currentAwayTeam
	// 		};
	
	// 		let incidentMessage = null;
	// 		const incident = recentEvent;
	
	// 		if (recentEvent) {
	// 			incidentMessage = SportsMessages( {sport, incident, teams} );
	// 		}
	
	// 		if (recentIncident && incidentMessage) {
	// 			onSend({ text: incidentMessage }, isGroup);
	// 		}
	// 	}
	// };

	// // Send play event every 30 secs
	// const ref = React.useRef(null);
    // const timeRef = React.useRef(null);

	// React.useEffect(() => {
    //     timeRef.current = setInterval(getSportsIncidentsByGameId, 1 * 30 * 1000); // 30 sec - 1 Minute 1 * 60 * 1000

    //     return () => {
    //         if(ref.current){
    //           clearInterval(timeRef.current)
    //         };
    //       };
    // }, []);

	// // Gets list of games - set to 2 (Basketball) for now
	// React.useEffect(() => {
	// 	let sport = 'basketball';

	// 	const getLiveGames = async () => {
	// 		const gamesRes = await getLiveEventsBySport(sport);
	// 		setLiveGames(gamesRes);
	// 	}

	// 	getLiveGames();
	// }, [])

	// const sportsModal = document.getElementById('subChatSportsModal');

	// function handleSportsModalDisplay() {
	// 	if (sportsModal && sportsModal.style.display !== 'flex') {
	// 		sportsModal.style.display = 'flex';
	// 	} else {
	// 		sportsModal.style.display = 'none';
	// 	}
	// }

	// Below JSX place above first IconContainer

	// <IconContainer onClick={handleSportsModalDisplay} id='threadsSportsContainer'>
	// 	<img className='sportsIconChat' src={sportsIcon} alt='sports'/>
	// </IconContainer>

	// Below JSX place above Chat and below Footer

	// <div id='subChatSportsModal'>
	// 	{liveGames && liveGames.map((game) => {
	// 		function setLiveGame() {
	// 			setCurrentLiveGame(game.id);
	// 			setCurrentHomeTeam(game.homeTeam.name);
	// 			setCurrentAwayTeam(game.awayTeam.name);
	// 			getSportsIncidentsByGameId();
	// 			handleSportsModalDisplay();
	// 		}

	// 		return (
	// 			<div className='sportsModalGameContainer' onClick={setLiveGame}>
	// 				<div className='sportsModalRow'>
	// 					<h2 className='sportsModalText'>{game.homeTeam.nameCode}</h2>
	// 					<h2 className='sportsModalText'>{game.homeScore.current}</h2>
	// 				</div>
	// 				<div className='sportsModalRow'>
	// 					<h2 className='sportsModalText'>{game.awayTeam.nameCode}</h2>
	// 					<h2 className='sportsModalText'>{game.awayScore.current}</h2>
	// 				</div>
	// 			</div>
	// 		)
	// 	})}
	// </div>

	return (
		<Modal
			show={isOpen}
			onHide={handleClose}
			size='lg'
			dialogClassName='subChatModal'
		>
			<Chat mode={theme[mode]}>
				<ChatHeader>
					{selectedSubChatConversation
						? '# ' + selectedSubChatConversation.name
						: ''}
				</ChatHeader>
				{/* display each message here */}
			<ChatBody>
			{
				Object.entries(groupedMessages).sort((a, b) => new Date(a[0]) - new Date(b[0])).map(([date, messages]) => (
				<React.Fragment key={date}>
					<div style={{ textAlign: "center", color: "#B2B2B2", fontSize: "12px", fontWeight: 600 }}>
					{new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
					</div>
					{messages.map((message, i) => {
						if (message.location) {
							return (
								<div className='locationThreadContainer'>
									<LocationMessage location={message.location} />
								</div>
							)
						}

						if (message.audio) {
							return (
								<ChatMessage>
									<AudioMessage src={message.audio} />
								</ChatMessage>
							)
						}

						if (message.image) {
							return (
								<ChatMessage>
									<ImageMessage src={message.image} />
								</ChatMessage>
							)
						}

					const valueOfText = typeof message.text === 'string'
						? message.text
						: message.text.find((i) => i.language === user.language)?.text;

					var finalTime = formatDateTime(message.createdAt);
					return message.user._id === user._id ? (
						<ChatMessage key={message._id}>
						<div>{formatLink(valueOfText)}</div>
						<Timestamp>{finalTime}</Timestamp>
						</ChatMessage>
					) : (
						<ChatMessageReceiver key={message._id}>
						<div>{formatLink(valueOfText)}</div>
						<Timestamp style={{ textAlign: "left" }}>{finalTime}</Timestamp>
						{/* Optionally render the avatar for the first message in the group or if the previous message was by another user */}
						{i === 0 || messages[i - 1].user._id !== message.user._id ? (
							<ChatPhoto
							src={getAvatarPath(message.user.avatar)}
							roundedCircle={true}
							/>
						) : null}
						</ChatMessageReceiver>
					);
					})}
				</React.Fragment>
				))
			}
			<div ref={messagesEndRef} />
			</ChatBody>


				{/* message input */}
				{actionsVisible &&
				<div id='subChatsActionsContainer'>
				<ActionsContainer ref={actionsRef}>
					<Row>
					<FileUpload accept="image/*,video/*" onChange={sendPicOrVideo}>
						<RowItem>
						<Btn style={{backgroundColor: '#0984e3'}}><Icon name="image" size={20} color={'#fff'} /></Btn>
						Gallery
						</RowItem>
					</FileUpload>
					<FileUpload accept="audio/*" onChange={sendAudio}>
						<RowItem>
						<Btn style={{backgroundColor: '#00b894'}}><Icon name="headphones" size={20} color={'#fff'} /></Btn>
						Sound
						</RowItem>
					</FileUpload>
					<RowItem onClick={sendLocation}>
						<Btn style={{backgroundColor: '#e17055'}}><Icon name="pin" size={20} color={'#fff'} /></Btn>
						Location
					</RowItem>
					</Row>
				</ActionsContainer>
				</div>
				}
				<Footer>
					<div id='subchatForm'>
					    <InputWrapper>
						<InputContainer>
						<Input
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							placeholder='Message'
							autoFocus
							onKeyDown={(e) => {if (e.key === "Enter") {submit()}}}
						/>
						<IconContainer style={{marginRight: "20px"}} onClick={() => setActionsVisible(true)}>
							<Icon size={21} name="attach-outline" color={theme.gray} />
						</IconContainer>
						<IconContainer onClick={submit}>
							<Icon size={21} name={"paper-plane-outline"} color={theme.primary} />
						</IconContainer>
						</InputContainer>
						</InputWrapper>
					</div>
				</Footer>
			</Chat>
		</Modal>
	);
}

export default SubChatConversationModal;
