import styled from "styled-components";

export const ICard = styled.div`
display: flex;
flex-direction: column;
height: 80px;
border-bottom: 1px solid ${({theme}) => theme.border};
transition: all 0.3s ease-in;
align-items: center;
justify-content: center;
cursor: pointer;
position: relative;
padding: 0 25px;
background-color: ${(props) => props.className === 'selected' ? props.theme.selectedConvo : {}};
`

export const UList = styled.ul`
  justify-content: center;
  border-bottom: ${({theme}) => "1px solid " + theme.border};
  transition: all 0.3s ease-in;


  li button{
    color: ${({theme}) => theme.txt};
    background-color: ${({theme}) => theme.bg};
    border-color: ${({theme}) => theme.border};
    transition: all 0.3s ease-in;

  }

  .nav-link.active{
    color: ${({theme}) => theme.primary};
    background-color: ${({theme}) => theme.bg};
    border-color: ${({theme}) => theme.border};
    transition: all 0.3s ease-in;
  }

  .nav-link:focus {
    color: ${({theme}) => theme.primary};
  }

  .nav-link:hover {
    color: ${({theme}) => theme.primary};
  }
  
`;

export const SidebarContainer = styled.div`
  background-color: ${({theme}) => theme.bg};
  transition: all 0.3s ease-in;
  width: 340px;
  flex-basis: 340px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  padding: 15px 0;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  color: ${({theme}) => theme.txt};
  border-right: 1px solid ${({theme}) => theme.border};
  position: relative;
  overflow-y: hidden;
  .head {
    // padding: 0 25px;
    overflow-y: hidden;
    color: ${({theme}) => theme.txt};
    svg {
      fill: ${({theme}) => theme.txt}
    }
    div {
    cursor: pointer;
    }
  }
  @media screen and (max-width: 650px) {
    flex-basis: 100% !important;
    width: 100% !important;
  } 
`;

export const ChatList = styled.div`
  padding: inherit;
  height: 100%;
  overflow-y: auto;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  border-bottom: 1px solid ${({theme}) => theme.border};
  transition: all 0.3s ease-in;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding: 0 25px;
  background-color: ${(props) => props.className === 'selected' ? props.theme.selectedConvo : {}};
  
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 30px;
  margin-right: 15px;
`;

export const UserName = styled.span`
  font-size: 16px;
  margin-bottom: 7px;
  display: block;
  color: ${({theme, unseen}) => !unseen ? theme.title : theme.primary};
  font-weight: ${({unseen}) => !unseen ? 400 : 600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 122px;
`;

export const MessageText = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.gray};
  font-weight: ${({unseen}) => !unseen ? 400 : 600};
  height: 16px;
  overflow: hidden;
  width: 175px;
`;

export const Time = styled.span`
  color: ${({theme}) => theme.gray};
  font-size: 12px;
  position: absolute;
  right: 25px;
  top: 15px;
`;

export const Msg = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({theme}) => theme.mode === 'light' ? 0.6 : 1};
  transition: all 0.3s ease-in;
`;

export const UnseenCount = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: ${({theme}) => theme.primary};
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  margin-top: 20px;
  color: #fff;
`;

export const ItemRight = styled.div`
  margin-top: 20px;
  align-self: flex-end;
`;

export const Container = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.bg};
  padding: 15px 0;
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column; /* Align items vertically */
  justify-content: flex-start; /* Align items to the start of the container */
`;

export const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 100%; /* Ensure the container takes full width */
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
`;

export const Search = styled.input.attrs({ type: "text" })`
  background-color: ${({ theme }) =>
    theme.mode === "dark"
      ? "rgba(255, 255, 255, 0.15)"
      : "rgba(0, 0, 0, 0.05)"};
  border: none;
  outline: none;
  width: calc(100% - 30px); /* Adjusted width to accommodate for the icon */
  height: 45px;
  padding: 0 10px;
  padding-left: 50px; /* Adjusted padding to accommodate for the icon */
  border-radius: 5px;
  font-size: 15px;
  color: ${({ theme }) => theme.txt};
  &::placeholder {
    color: ${({ theme }) => theme.txt};
  }
`;
