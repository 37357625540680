// Import necessary libraries and icons
import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import FileUpload from "../../../components/FileUpload";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useCollapse } from 'react-collapsed';
import { VscChevronRight } from 'react-icons/vsc';
import { BsChatLeftText, BsInfoCircle, BsCheck } from 'react-icons/bs';
//import { IoMdNotificationsOutline } from 'react-icons/io';
//import { FiShare } from 'react-icons/fi';
//import { ImStack } from "react-icons/im";
import {getImagePath} from "../../../utils/helpers.js"
import { toast } from "react-toastify";
import Api from '../../../config/axios';
import './styles.css';

// Define the Collapsible component
const Collapsible = ({ name, icons, children }) => {
  const { getCollapseProps, getToggleProps } = useCollapse();
  return (
    <div className="collapsible">
      <div className="header" {...getToggleProps()}>
        <div>
          {React.createElement(icons, { style: { fontSize: "1.10rem" } })}
          <span style={{ marginLeft: "1rem" }}>{name}</span>
          <VscChevronRight style={{ float: "right" }} />
        </div>
      </div>
      <div {...getCollapseProps()} className="content-box">
        {children}
      </div>
    </div>
  );
};
/*const getBackgroundSettings = () => {
  Api.get(`/user/useBackground`)
}*/
// Define the ButtonWithIcon component
const ButtonWithIcon = ({ label, isSelected, onClick, fontSizeValue }) => {
  const buttonStyle = {
    fontSize: `${fontSizeValue}rem`, // Use the fontSizeValue directly to set the size
  };

  return (
    <button
      className={`collapsible-options ${isSelected ? "selected" : ""}`}
      onClick={onClick}
      style={buttonStyle}
    >
      <span className="button-text">{label}</span>
      {isSelected && (
        <span className="selected-icon-container">
          <BsCheck style={{ fontSize: "1.50rem", color: "green" }} />
        </span>
      )}
    </button>
  );
};

// Define the SettingsTab component
export default function SettingsTab({ user }) {
  const [fontSize, setFontSize] = useState(user.fontSize);
  const [selectedText, setSelectedText] = useState(null);
  const [background, setBackground] = useState(user.background);
  const [used, setUsed] = useState(user.useBackground);
  const imageUploader = React.useRef(null);
  const handleBackgroundChange = async(source) => {
    //setBackground(source);
    console.log(background);
    console.log(user);
    const formData = new FormData();
    formData.append('file',source);
    console.log(formData);
    Api.put(`/user/background`, formData).then((res) => {
      toast.success("Background Updated");
      //console.log(res.data.path);
      console.log(getImagePath(res.data.path));
      setBackground(res.data.path);
      return res.data.path;
    })
    .catch((error) => {
      console.log(error);
      toast.error(error);
    });
    try{
      await Api.put(`/user/useBackground/${user._id}`,{useBackground: used});
    }
    catch(error){
      console.log(error);
      toast.error(error);
    }
    
    
  }
  useEffect(() => {
    let res;
    switch (fontSize) {
      case 0.80:
        res = 'Small';
        break;
      case 0.90:
        res = 'Medium';
        break;
      case 1.15:
        res = 'Large';
        break;
      default:
        res = '';
    }
    setSelectedText(res);
    const fetchBackground = async() => {
      try{
        setBackground(user.background);
      }
      catch(error){
        console.log(error);
      }
    }
    fetchBackground();
    
  }, [fontSize, user.background,used,setSelectedText]);

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate('/');
  }

  const handleTextChange = (label) => {
    setFontSize(label);
    setSelectedText(label); // Fixed: Update selectedText with the new label
  }

  const handleUseChange = async(change) => {
    setUsed(change);
    if(change === false){
      setBackground("../images/bg.png");
      try{
        await Api.put(`/user/useBackground/${user._id}`,{useBackground: change});
      }
      catch(error){
        console.log(error);
        toast.error(error);
      }
     
      return 0;
    }

  }

  const handleDelete = async () => {
    const confirmed = window.confirm('Are you sure you want to delete your account? This action cannot be undone.');
    if (!confirmed) return;
    try {
      await Api.delete(`chat/conversations/${user._id}`);
      await Api.delete(`/user/${user._id}`);
      localStorage.removeItem("token");
      navigate('/app/login');
      toast.success('Account Deleted Successfully!')
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete account');
    }
  }
  const changeBackground = (changeImage) => {
    if(changeImage === true){
      return(
        <div>
          <img src={getImagePath(background)} alt="" style={{objectFit: "cover", width: "80%", height: "80%"}}/>
      <FileUpload accept="image/*" onChange={handleBackgroundChange}>
        <AiOutlineCloudUpload ref={imageUploader} size={20} color={'#888888'} style={{ cursor: "pointer"}}/>
    </FileUpload>
        </div>
      )
    }
    
  }
  
  return (
    <div className="settings-container">
      <h6 className="options">Features</h6>
      <div className="features-options">
        <Collapsible name="Font Size" icons={BsChatLeftText}>
          <div className="content">
            <ButtonWithIcon
              label="Small"
              isSelected={selectedText === "Small"}
              onClick={() => handleTextChange(0.8)}
              fontSizeValue={0.8}
            />
            <div className="divider-options"></div>
            <ButtonWithIcon
              label="Medium"
              isSelected={selectedText === "Medium"}
              onClick={() => handleTextChange(0.9)}
              fontSizeValue={0.9}
            />
            <div className="divider-options"></div>
            <ButtonWithIcon
              label="Large"
              isSelected={selectedText === "Large"}
              onClick={() => handleTextChange(1.15)}
              fontSizeValue={1.15}
            />
          </div>
        </Collapsible>
      </div>
      <div className="backgroundChange">
        <h6 className="options">Change Background</h6>
        <Switch onChange={handleUseChange} checked = {used}/>
        <div className="actualBackgroundChange">
          {changeBackground(used)}
          
        </div>
      </div>
      <h6 className="options">About</h6>
      <div className="features-options">
        <Collapsible name="About" icons={BsInfoCircle}>
          <div className="content">
            <button className="collapsible-options" onClick={handleDelete}>
              <span className="button-text">Delete Account</span>
            </button>
          </div>
        </Collapsible>
      </div>
      <button className="logout-btn" onClick={handleLogout}>
        <span>LOG OUT</span>
      </button>
    </div>
  );
}
