import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Api from "../../config/axios";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import "./AddUserModal.css";
import { Avatar } from "./styles";
import { getAvatarPath } from "../../utils/helpers";

function AddUserModal({ user, show, handleClose, chatId }) {
  const [allUsers, setAllUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchDb, setSearchDb] = useState("");
  const [selectedOption, setSelectedOption] = useState("search-name");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState([]);

  const debouncedSave = useCallback(
    debounce((nextValue) => setSearchDb(nextValue), 1000),
    []
  );

  const handleSearchChange = (val) => {
    setSearch(val);
    debouncedSave(val);
  };

  // Handle change in search option (name, email, phone)
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleUserSelection = (userId) => {
    console.log("Selected user ID:", userId);

    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, userId]);
      const userDetails = allUsers.find((user) => user._id === userId);
      setSelectedUserDetails((prevSelectedUserDetails) => [
        ...prevSelectedUserDetails,
        userDetails,
      ]);
    }
  };

  const handleUserDeselection = (userId) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.filter((id) => id !== userId)
    );
    setSelectedUserDetails((prevSelectedUserDetails) =>
      prevSelectedUserDetails.filter((user) => user._id !== userId)
    );
  };

  const handleSubmit = async () => {
    if (selectedUsers.length === 0) {
      return toast.warn("Select at least one User!");
    }
    try {
      // Fetch current participants
      const participantsRes = await Api.get(`/chat/conversation/${chatId}`);
      const currentParticipants = participantsRes.data.users; // Access the 'users' property

      // Create an array of user IDs
      const participantIds = currentParticipants.map((user) => user._id);

      // Check if any of the selected users are already participants
      const alreadyInChat = [];
      for (let userId of selectedUsers) {
        if (participantIds.includes(userId)) {
          const user = allUsers.find((user) => user._id === userId);
          console.log("A user may already be in the group chat");
          return toast.warn(`User(s) may already be in the group chat!`);
        }
      }

      // Add selected users to chat
      const resAdd = await Api.put(
        `/chat/conversation/group/${chatId}/participant`,
        {
          participants: selectedUsers,
        }
      );
      if (resAdd.status === 200) {
        console.log("User(s) added successfully");
        toast.success("User(s) added successfully");
      } else {
        console.log("Failed to add user(s)");
        toast.error("Failed to add user(s)");
      }
      handleClose();
      setSelectedUsers([]);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while adding user(s) to the chat.");
    }
  };

  // Fetch all users based on search input and selected option
  const fetchAllUsers = useCallback(async () => {
    if (searchDb.length > 2) {
      const res = await Api.get(
        "/user/search?q=" + searchDb + "&by=" + selectedOption
      );
      setAllUsers(res.data);
    }
  }, [searchDb, selectedOption]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Users to Group Chat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formUserId">
            <Form.Label>Users to Add</Form.Label>
            <Form.Control
              type="text"
              value={search}
              onChange={(e) => handleSearchChange(e.target.value)}
              className="noBoxShadow"
            />
            {allUsers.length > 0 && (
              <div className="search-results">
                {allUsers.map((user) => (
                  <div
                    key={user._id}
                    onClick={() => handleUserSelection(user._id)}
                    className={
                      selectedUsers.includes(user._id) ? "selected-user" : ""
                    }
                  >
                    <Avatar
                      src={getAvatarPath(user.avatar)}
                      alt={user.name}
                      className="avatar"
                    />
                    {user.name}
                  </div>
                ))}
              </div>
            )}

            <div style={{ fontSize: "14px", paddingTop: "10px" }}>
              Selected Users:{" "}
              <div className="selectedUsers">
                {selectedUserDetails.map((user) => {
                  return (
                    <span key={user._id} className="selectedUserOption">
                      <Avatar
                        className="avatar"
                        src={getAvatarPath(user.avatar)}
                        alt={user.name}
                      />{" "}
                      {user.name}{" "}
                      <div className="buttonContainer">
                        <button
                          className="minusButton"
                          onClick={() => handleUserDeselection(user._id)}
                        >
                          x
                        </button>
                      </div>
                    </span>
                  );
                })}
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Add Users
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddUserModal;
