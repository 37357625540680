import React, {useEffect, useState} from 'react';
import Api from "../../../config/axios";
import {ImgMessage} from '../styles';

function ImageMessage({src}) {

  function handleImageModal() {
    const modal = document.getElementById('imageModal');
    const img = document.getElementById('imageModalImg');

    if (modal.style.display === 'none') {
      img.src = src;
      modal.style.display = 'block';
    } else {
      modal.style.display = 'none';
      img.src = null;
    };
  };

  return (
    <div onClick={handleImageModal}>
      <ImgMessage
        src={src}
        controls
      />
    </div>
  )
}

export default ImageMessage;
